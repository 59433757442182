import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type WarehouseType } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<WarehouseType>;
const initialState = getInitialState<WarehouseType>();

const WarehousesTypesSlice = createSlice({
  name: "warehousesTypes",
  initialState,
  reducers: {
    rehydrateWarehousesTypes: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateWarehousesTypes } = WarehousesTypesSlice.actions;
export default WarehousesTypesSlice.reducer;
