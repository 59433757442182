export const toyFilterLabels = {
  uuid: "UUID",
  accessories: "付属品・装飾品",
  comment_Comment: "コメント",
  comment_CommentOfCleaning: "清掃・梱包方法",
  comment_CommentForCustomer: "顧客へのコメント",
  comment_CommentOfPlan: "プラン留意事項",
  comment_CommentOfShipping: "発送時の確認事項",
  comment_CommentOfReturning: "返却時の確認事項",
  comment_CommentOfPurchase: "買取り留意事項",
  comment_CommentOfPlanSheet: "プランシートコメント",
  comment_CommentOfStock: "仕入れ留意事項",
  comment_PreparationOfShipping: "商品化作業内容",
  packingMaterial: "梱包資材",
  packingMaterial_Uuid: "梱包資材のUUID",
  noteOfPurchaseForCustomer: "お客様に対する購入メモ",
  depth: "おもちゃの奥行き",
  height: "おもちゃの高さ",
  width: "おもちゃの横幅",
  description: "おもちゃの概要",
  gender: "対象性別",
  instruction: "プランナー向け留意事項",
  maxAge: "トイサブ対象最大月齢",
  minAge: "トイサブ対象最小月齢",
  toyProduceStatus: "おもちゃの生産状況",
  toyMakerRecommendedMaxAge: "メーカー推奨最大月齢",
  toyMakerRecommendedMinAge: "メーカー推奨最小月齢",
  makerToyUrl: "商品のURL",
  name: "おもちゃの名前",
  preparationOfShipping: "商品化作業内容",
  price: "おもちゃの価格",
  retailPrice: "希望小売価格(税抜)",
  requiredBatteries: "必要電池",
  requiredBatteryNumber: "電池の数",
  requiredBatteryType_Name: "電池の種類",
  requiredBatteryType_Uuid: "電池の種類のUUID",
  safetyBorders_Name: "安全基準名",
  safetyBorders_Uuid: "安全基準のUUID",
  safetyBorderNote: "安全基準について",
  toyAttachments_MimeType: "おもちゃのアタッチメントのMIMEタイプ",
  toyAttachments_Name: "おもちゃのアタッチメント名",
  toyAttachments_OriginalName: "おもちゃのアタッチメントの元の名前",
  toyAttachments_Path: "おもちゃのアタッチメントのパス",
  toyAttachments_Uuid: "おもちゃのアタッチメントのUUID",
  toyCautions_Name: "おもちゃの注意事項",
  toyCautions_Uuid: "おもちゃの注意事項のUUID",
  toyCategories_Name: "おもちゃカテゴリー名",
  toyCategories_Uuid: "おもちゃカテゴリーのUUID",
  toyCleaningImages_MimeType: "清掃・梱包方法画像のMIMEタイプ",
  toyCleaningImages_Name: "清掃・梱包方法画像の名前",
  toyCleaningImages_OriginalName: "清掃・梱包方法画像の元の名前",
  toyCleaningImages_Path: "清掃・梱包方法画像のパス",
  toyCleaningImages_Uuid: "清掃・梱包方法画像のUUID",
  toyCleaningDifficultyRates_Name: "清掃難易度名",
  toyCleaningDifficultyRates_Uuid: "清掃難易度のUUID",
  toyImages_MimeType: "おもちゃの画像MIMEタイプ",
  toyImages_Name: "おもちゃの画像名",
  toyImages_OriginalName: "おもちゃの画像の元の名前",
  toyImages_Path: "おもちゃの画像のパス",
  toyImages_Uuid: "おもちゃの画像のUUID",
  toyMaker_Comment: "おもちゃメーカーのコメント",
  toyMaker_Name: "おもちゃメーカー名",
  toyMaker_Url: "おもちゃメーカーURL",
  toyMaker_Uuid: "おもちゃメーカーのUUID",
  toyMaterials_Name: "おもちゃの材質名",
  toyMaterials_Uuid: "おもちゃの材質のUUID",
  toyProduceStatus_Name: "おもちゃの生産状況",
  toyProduceStatus_Uuid: "おもちゃの生産状況のUUID",
  toyStatus_Name: "取り扱いステータス名",
  toyStatus_Uuid: "取り扱いステータスのUUID",
  toySuppliers_Comment: "仕入れ先企業のコメント",
  toySuppliers_ConditionOfFee: "仕入れ先企業の料金条件",
  toySuppliers_ConditionOfPurchase: "仕入れ先企業の購入条件",
  toySuppliers_Name: "仕入れ先企業名",
  toySuppliers_NoteOfHowToReturn: "仕入れ先企業への返却方法",
  toySuppliers_OrderDirectTel: "仕入れ先企業の発注直通電話番号",
  toySuppliers_OrderFirstName: "仕入れ先企業の発注担当者名（名前)",
  toySuppliers_OrderFirstNameOfPronunciation:
    "仕入れ先企業の発注担当者名（名前・カナ)",
  toySuppliers_OrderLastName: "仕入れ先企業の発注担当者名（苗字)",
  toySuppliers_OrderLastNameOfPronunciation:
    "仕入れ先企業の発注担当者名（苗字・カナ)",
  toySuppliers_OrderMail: "仕入れ先企業の発注メールアドレス",
  toySuppliers_OrderTel: "仕入れ先企業の発注代表電話番号",
  toySuppliers_PurchaseNote: "仕入れ先企業の購入時メモ",
  toySuppliers_ReturnAddress: "仕入れ先企業の返品先住所",
  toySuppliers_ReturnAddressZipCode: "仕入れ先企業の返品先郵便番号",
  toySuppliers_Url: "仕入れ先企業のURL",
  toySuppliers_Uuid: "仕入れ先企業のUUID",
  sizeOfPlasticBag: "ポリ袋の大きさ",
  secondHandsPrice: "中古販売価格",
  toyMaker: "メーカー",
  toyStatus: "取り扱いステータス",
  weight: "おもちゃの重量",
  individualToyStatus_Uuid: "個体おもちゃの取り扱いステータスのUUID",
  shelf_Name: "棚名",
  shelf_Uuid: "棚のUUID",
  shipment_CommentOfReceived: "その他・ご意見・ご感想",
  shipment_PlannerMessage: "プランナーメッセージ",
  shipment_ChildInterestingOfReceived: "子供の発育・興味",
  shipment_PlanTitle: "プランタイトル",
  shipment_Uuid: "発送のUUID",
  shipment_PlanComment: "プランコメント",
  shipment_ShippingNumber: "伝票番号",
  stockToy_Uuid: "仕入れのUUID",
  toy_Name: "おもちゃの名前",
  toy_Description: "おもちゃの概要",
  toy_Accessories: "おもちゃの付属品",
  toy_Instruction: "おもちゃの使用方法",
  toy_MakerToyUrl: "おもちゃの商品URL",
  toy_Uuid: "おもちゃのUUID",
  toy_NoteOfPurchaseForCustomer: "おもちゃのお客様に対する購入メモ",
  depreciationMoney: "減価償却金額",
  depreciationMonths: "減価償却期間",
  salePrice: "売却金額",
  warehouse_Uuid: "倉庫のUUID",
  planSheet_NameOfPlanSheet: "プランシート名",
  planSheet_CommentOfPlanSheet: "プランシートコメント",
  planSheet_NoteOfPlanSheet: "プランシート留意事項",
  planSheet_ToyMakerNameOfPlanSheet: "おもちゃメーカーのプランシート名",
  requiredBatteryType: "電池の種類",
  inventoryItems_Uuid: "棚卸しのUUID",

  isCommercialization: "商品化作業",
  isPrivateBrand: "プライベートブランド",
  isSellingFromMaker: "メーカーからの販売",
  isSellingFromOur: "トラーナからの販売",
  planTemplate_Comment: "プランテンプレートコメント",
  planTemplate_Id: "プランテンプレートのID",
  planTemplate_Title: "プランテンプレート名",
  planTemplate_Uuid: "プランテンプレートのUUID",

  toyInstructionAttachments_MimeType:
    "おもちゃの説明書アタッチメントのMimeタイプ",
  toyInstructionAttachments_Name: "おもちゃの説明書アタッチメント名",
  toyInstructionAttachments_OriginalName:
    "おもちゃの説明書アタッチメント元の名前",
  toyInstructionAttachments_Path: "おもちゃの説明書アタッチメントのパス",
  toyInstructionAttachments_Uuid: "おもちゃの説明書アタッチメントのUUID",
  toyRequiredBatteries_Uuid: "おもちゃに必要な電池のUUID",

  saleCompletionDate: {
    start: "売却完了日 (開始)",
    end: "売却完了日 (終了)",
  },

  stockedAt: {
    start: "入庫日時 (開始)",
    end: "入庫日時 (終了)",
  },

  saleContactDate: {
    start: "売却連絡日 (開始)",
    end: "売却連絡日 (終了)",
  },
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },

  // copied individualToy
  individualToyStatus: "個体のステータス",
  individualToyStatusReason: "個体のステータスの理由",
  warehouse: "事業所・倉庫",
  toyId: "おもちゃ ID",

  toyPartsDangerRate: "パーツ危険サイズ",
  toyPartsDangerRate_Uuid: "パーツ危険サイズのUUID",
  toyPartsQuantityRate: "パーツ量",
  toyPartsQuantityRate_Uuid: "パーツ量のUUID",
  toySoundVolume: "音量",
  toySoundVolume_Uuid: "音量のUUID",
};
