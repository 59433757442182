import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ShelvesListApiResponseFromToyUuid } from "./useRehydratedToyShelves";
import { type RehydratePager, getInitialState } from "@/types/Pager";

type PayloadType = RehydratePager<ShelvesListApiResponseFromToyUuid>;
const initialState = getInitialState<ShelvesListApiResponseFromToyUuid>();

const ShelvesSlice = createSlice({
  name: "shelves",
  initialState,
  reducers: {
    rehydrateShelvesFromToyUuid: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateShelvesFromToyUuid } = ShelvesSlice.actions;
export default ShelvesSlice.reducer;
