import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type WarehouseTransfer } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<WarehouseTransfer>;
const initialState = getInitialState<WarehouseTransfer>();

const WarehousesTransfersSlice = createSlice({
  name: "warehousesTransfers",
  initialState,
  reducers: {
    rehydrateWarehousesTransfers: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateWarehousesTransfers } =
  WarehousesTransfersSlice.actions;
export default WarehousesTransfersSlice.reducer;
