import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const initialState = {} as any;

type SearchFilterStateParameter = { page: string; data: any };

const SearchFilterSlice = createSlice({
  name: "searchFilter",
  initialState,
  reducers: {
    rehydrateSearchFilter: (
      state: any,
      action: PayloadAction<SearchFilterStateParameter>,
    ) => {
      const { page, data } = action.payload;
      state[page] = data;
      return state;
    },
  },
});

export const { rehydrateSearchFilter } = SearchFilterSlice.actions;
export default SearchFilterSlice.reducer;
