import {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";
import { localStorageKeys } from "@/configs/localStorage";
import { localStorageAvailable } from "@/helper/local-storage";

const initialValue = false;
const _useFullScreenDisclosure = () => {
  const [fullScreen, setFullScreen] = useState(() => {
    if (!localStorageAvailable()) {
      return initialValue;
    }
    const boolString =
      localStorage.getItem(localStorageKeys.fullScreen) ?? String(initialValue);
    return boolString === "true";
  });
  const toggleFullScreen = () => {
    const setItem = !fullScreen;
    if (localStorageAvailable()) {
      localStorage.setItem(localStorageKeys.fullScreen, String(setItem));
    }
    setFullScreen(setItem);
  };
  return { fullScreen, toggleFullScreen } as const;
};

const FullScreenContext = createContext<
  ReturnType<typeof _useFullScreenDisclosure>
  // biome-ignore lint/suspicious/noExplicitAny :[AUTOMATICALLY-INSERTED] Unexpected any. Specify a different type.
>({} as any);

export const useFullScreenDisclosure = () => {
  return useContext(FullScreenContext);
};

export const FullScreenToggleProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const fullScreen = _useFullScreenDisclosure();
  const memorizedFullScreen = useMemo(() => fullScreen, [fullScreen]);
  return (
    <FullScreenContext.Provider value={memorizedFullScreen}>
      {children}
    </FullScreenContext.Provider>
  );
};
