import { type HTMLInputTypeAttribute } from "react";

export const isPickableInput = (
  type: string | undefined,
): type is Extract<
  HTMLInputTypeAttribute,
  "date" | "datetime-local" | "month" | "week" | "time"
> =>
  type === "date" ||
  type === "datetime-local" ||
  type === "month" ||
  type === "week" ||
  type === "time";
