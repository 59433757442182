import {
  Controller,
  type FieldPathValue,
  type FieldValues,
  type Path,
} from "react-hook-form";
import {
  type AutocompleteChangeReason,
  type AutocompleteChangeDetails,
  type AutocompleteValue,
} from "@mui/material";
import { type SyntheticEvent } from "react";
import { Autocomplete, type AutocompleteProps } from "./Autocomplete";
import { type OptionValue } from "./types";
import { comboboxDefaultProps } from "./constants";
import { type TextFieldProps } from "../textfield";
import { type RhfControllerForm } from "@/types/react-hook-form";
import { type DatadogAttributes } from "@/types/datadog";

type GetOptionType<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TOption extends OptionValue,
> = TOption extends never ? FieldPathValue<TFieldValues, TName> : TOption;

export type RhfComboboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
  DisableClearable extends boolean = false,
  TOption extends OptionValue = never,
> = {
  textFieldProps?: TextFieldProps;
  required?: TextFieldProps["required"];
  defaultValue?: FieldPathValue<TFieldValues, TName>;
  onChange?: (
    event: SyntheticEvent,
    value: AutocompleteValue<
      GetOptionType<TFieldValues, TName, TOption>,
      false,
      DisableClearable,
      false
    >,
    reason: AutocompleteChangeReason,
    rfhOnChange: (
      v: AutocompleteValue<
        GetOptionType<TFieldValues, TName, TOption>,
        false,
        DisableClearable,
        false
      >,
    ) => void,
    details?: AutocompleteChangeDetails<
      GetOptionType<TFieldValues, TName, TOption>
    >,
  ) => void;
} & Omit<
  AutocompleteProps<
    GetOptionType<TFieldValues, TName, TOption>,
    false,
    DisableClearable,
    false
  >,
  "defaultValue" | "onChange" | "multiple"
> &
  Omit<RhfControllerForm<TFieldValues, TName>, "defaultValue"> &
  DatadogAttributes;

export const RhfCombobox = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TOption extends OptionValue,
  DisableClearable extends boolean = false,
>({
  control,
  name,
  rules,
  defaultValue,
  textFieldProps,
  onChange,
  required,
  ddAllowPrivacy,
  ...props
}: RhfComboboxProps<TFieldValues, TName, DisableClearable, TOption>) => {
  const mergeRules =
    rules?.required === undefined && required !== undefined
      ? { ...rules, required }
      : rules;
  return (
    <Controller<TFieldValues, TName>
      name={name}
      control={control}
      rules={mergeRules}
      defaultValue={defaultValue}
      render={({
        field: { ref, onChange: rhfOnChange, ...params },
        fieldState,
      }) => {
        return (
          <Autocomplete<
            GetOptionType<TFieldValues, TName, TOption>,
            false,
            DisableClearable,
            false
          >
            {...params}
            {...comboboxDefaultProps}
            multiple={false}
            freeSolo={false}
            loadingText="読込中..."
            onChange={(e, v, r, d) => {
              if (onChange !== undefined) {
                onChange(e, v, r, rhfOnChange, d);
              } else {
                rhfOnChange(v);
              }
            }}
            textFieldProps={{
              helperText: fieldState?.error?.message,
              error: fieldState?.error !== undefined,
              required: required ?? rules?.required === true,
              inputRef: ref,
              ddAllowPrivacy,
              ...textFieldProps,
            }}
            ddAllowPrivacy={ddAllowPrivacy}
            {...props}
          />
        );
      }}
    />
  );
};
