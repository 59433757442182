import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type LoadingStateParameter = { id: string; enable: boolean };
const initialState = {} as any;

const LoadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    rehydrateLoading: (
      state: any,
      action: PayloadAction<LoadingStateParameter>,
    ) => {
      const { id, enable } = action.payload;
      if (!enable) {
        delete state[id];
      } else {
        state[id] = enable;
      }
      return state;
    },
  },
});

export const { rehydrateLoading } = LoadingSlice.actions;
export default LoadingSlice.reducer;
