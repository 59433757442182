export const COLUMNS_LABELS_COMMENTS = {
  id: "ID",
  comment: "コメント",
  comment_of_cleaning: "清掃・梱包方法",
  comment_for_customer: "顧客へのコメント",
  comment_of_plan: "プラン留意事項",
  comment_of_shipping: "発送時の確認事項",
  comment_of_returning: "返却時の確認事項",
  comment_of_purchase: "買取り留意事項",
  comment_of_plan_sheet: "プランシートコメント",
  comment_of_stock: "仕入れ留意事項",
  preparation_of_shipping: "商品化作業内容",
};
