import {
  hasCurlyBrackets,
  replaceCurlyBracketsWithGuard,
} from "./replaceCurlyBrackets";
import { type PartialPathParams } from "./type";
import { removeTrailingSlash } from "../removeTrailingSlash";

/**
 * path parameter の key が curly brackets 内の placeholder 名と一致する場合、
 * path parameter の value で placeholder を置換する。
 * ただし、path parameter が存在しなかったり、その key, value が不正である場合、 null を返却する。
 * @internal
 * path として OpenAPI の型を用いない緩い型の実装
 */
export function composePath<Path extends string>(
  path: Path,
  paramsInPath?: PartialPathParams<Path>,
): string | null {
  if (paramsInPath === undefined) {
    if (hasCurlyBrackets(path)) {
      // `path` に curly bracket が含まれる場合、`paramsInPath` は必須です。"
      return null;
    }
    return removeTrailingSlash(path);
  }

  const ret = Object.entries(
    paramsInPath as Record<string, string | undefined>,
  ).reduce(replaceCurlyBracketsWithGuard, path);

  // `path` と`paramsInPath` の組み合わせが不正です。
  if (hasCurlyBrackets(ret)) {
    return null;
  }
  return ret;
}
