import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ShipmentToyStatusReason } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<ShipmentToyStatusReason>;
const initialState = getInitialState<ShipmentToyStatusReason>();

const ShipmentToyStatusesReasonsSlice = createSlice({
  name: "shipmentToyStatusesReasons",
  initialState,
  reducers: {
    rehydrateShipmentToyStatusesReasons: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateShipmentToyStatusesReasons } =
  ShipmentToyStatusesReasonsSlice.actions;

export default ShipmentToyStatusesReasonsSlice.reducer;
