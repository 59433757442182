import Link, { type LinkProps } from "next/link";
import { Button, type ButtonProps } from "./Button";
import { type Overwrite } from "@/types/helpers";

interface NarrowedLinkProps extends LinkProps {
  href: string;
}

type LinkButtonProps = Overwrite<ButtonProps<"a">, NarrowedLinkProps>;

/**
 * next.js の Link と mui Button ラッパーを組み合わせる。
 * 各種コンポーネンの interface の変更等を吸収する。
 * @note
 * next.js は UrlObject を resolve して string に変換する関数を公開していない。
 * かつ、Button コンポーネントは string でしか href を受け取らない。（実装上は問題なさそうであるが ( cf. https://github.com/mui-org/material-ui/blob/95e41fd90e6eab800842ceb96e4f77c059b64f76/packages/mui-material/src/Button/Button.js#L362 ) 外部 interface （型） を無視して内部実装を見て実装すると flaky なコードになるので共通コンポーネントでは避けたい。）
 * そのため、 href に UrlObject を渡す場合には対応していない。
 *
 * href に UrlObject を渡したい場合は LinkWithButton を利用して下さい。
 */
export const LinkButton = ({ children, ...rest }: LinkButtonProps) => {
  return (
    <Button component={Link} {...rest}>
      {children}
    </Button>
  );
};
