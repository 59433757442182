import { type Overwrite } from "./helpers";
import { type Pager as PagerInterface } from "@/api/generated/openapi-typescript/apiv1/schemas/camel";

export type RehydratePager<T = any> = Overwrite<
  PagerInterface,
  { items: T[] }
> & {
  parameters?: URLSearchParams | undefined;
  endpoint?: string | undefined;
};

export interface RetainedState<T> {
  [key: string]: RehydratePager<T>;
}

export const getInitialPager = <T>(): RehydratePager<T> => {
  return {
    items: [] as T[],
    totalItems: 0,
    totalPages: 0,
    page: 1,
    parameters: undefined,
    endpoint: undefined,
  };
};

export const getInitialState = <T>(): RetainedState<T> => {
  return {
    "": getInitialPager<T>(),
  };
};
