// ja.ts になかったです
export const COLUMNS_LABELS_SENT_MAILS = {
  id: "ID",
  uuid: "UUID",
  mail_template_id: "メールテンプレートID",
  table_name: "テーブル名",
  target_id: "ターゲットID",
  subject: "件名",
  body: "本文",
};
