export const COLUMNS_LABELS_SHIPMENTS = {
  id: "ID",
  uuid: "UUID",
  child_interesting_of_received: "子供の発育・興味",
  comment_of_received: "その他、ご意見・ご感想",
  plan_comment: "プランコメント",
  planner_message: "プランナーメッセージ",
  shipping_number: "伝票番号",
  plan_title: "プランタイトル",
};
