export const subscriptionFilterLabels = {
  uuid: "UUID",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
  comment: "コメント",
  name: "コース名",
  price: "決済金額",
  description: "コースの概要",
  endAt: {
    start: "終了日時 (開始)",
    end: "終了日時 (終了)",
  },
  startAt: {
    start: "開始日時 (開始)",
    end: "開始日時 (終了)",
  },

  lpCode: "lpコード",
  lpLink: "lpリンク",
  maxUsing: "最大使用回数",
  paymentCycle: "支払いサイクル",
  paymentCycle_Name: "支払いサイクル名",
  paymentCycle_Uuid: "支払いサイクルID検索",
  requiredMaxAgeOfMonthInPlanning: "計画に必要な最大月齢",
  requiredMaxAgeOfYearInPlanning: "計画に必要な最大年齢",
  requiredMaxItemAmount: "必要な最大アイテム量",
  requiredPriceBase: "必要な価格ベース",
  requiredSafetyBase: "必要な安全基準",
  subscriptionStatus: "料金コースのステータス",
  changeCycle: "変更サイクル",
  changeCycle_Name: "変更サイクル名",
  changeCycle_Uuid: "変更サイクルID検索",
  paymentTypes_Name: "支払いタイプ名",
  paymentTypes_Uuid: "支払いタイプID検索",
  subscriptionStatus_Name: "サブスクリプションステータス名",
  subscriptionStatus_Uuid: "サブスクリプションステータスID検索",
};
