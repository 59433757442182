import { useCallback, useState } from "react";

export const useDisclosure = (initState = false) => {
  const [isOpen, setIsOpen] = useState(initState);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return { isOpen, open, close, toggle };
};
