import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getInitialState, type RehydratePager } from "@/types/Pager";
import { type RequiredBatterySize } from "@/openapi/api";

type PayloadSize = RehydratePager<RequiredBatterySize>;
const initialState = getInitialState<RequiredBatterySize>();

const RequiredBatterySizesSlice = createSlice({
  name: "requiredBatterySizes",
  initialState,
  reducers: {
    rehydratedRequiredBatterySizes: (
      state,
      { payload }: PayloadAction<PayloadSize>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydratedRequiredBatterySizes } =
  RequiredBatterySizesSlice.actions;
export default RequiredBatterySizesSlice.reducer;
