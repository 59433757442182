import { type PartialPathParams } from "./type";
import { isFalsyString } from "@/helper/string";

export function hasCurlyBrackets(str: string) {
  return /{/.test(str);
}

export function replaceCurlyBrackets(
  strIncludingBrackets: string,
  [key, value]: [string, string],
) {
  return strIncludingBrackets.replace(new RegExp(`{${key}}`), value);
}

export function replaceCurlyBracketsWithGuard(
  strIncludingBrackets: string,
  [key, value]: [string, string | undefined | null],
) {
  if (isFalsyString(value)) {
    return strIncludingBrackets;
  }
  return replaceCurlyBrackets(strIncludingBrackets, [key, value]);
}

/**
 * path parameter の妥当性をチェックせず、に path parameter を slug に当てはめる。
 */
export function composePathUnsafe<Path extends string>(
  path: Path,
  paramsInPath?: PartialPathParams<Path>,
): string {
  return Object.entries(paramsInPath as Record<string, string>).reduce(
    replaceCurlyBrackets,
    path,
  );
}
