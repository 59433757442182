import { type FC, useMemo } from "react";
import { getDisplayError } from "./helper";
import { DisplayError } from "../display-error";
import { ddLogs } from "@/lib/datadog";
import { MadrasAPIResponseError } from "@/api/errors";

export const FallbackError: FC<{
  error: unknown;
}> = ({ error }) => {
  const sessionId = useMemo(() => {
    let _sessionId: string | undefined;

    ddLogs((logs) => {
      // https://docs.datadoghq.com/ja/logs/log_collection/javascript/#%E5%86%85%E9%83%A8%E3%82%B3%E3%83%B3%E3%83%86%E3%82%AD%E3%82%B9%E3%83%88%E3%81%AB%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B9%E3%81%99%E3%82%8B
      const internalContext = logs?.getInternalContext?.();
      _sessionId = internalContext?.session_id;
    });
    return _sessionId || "不明";
  }, []);

  const { title, message, severity } = useMemo(
    () => getDisplayError(error),
    [error],
  );

  const clipboardText = useMemo(() => {
    const baseText = `session_id: ${sessionId}\nエラー名: ${title}\nエラーメッセージ: ${message}`;
    if (error instanceof MadrasAPIResponseError) {
      return `${baseText}\nresponse: ${error.response.body ?? "unknown"}`;
    }
    return baseText;
  }, [error, message, sessionId, title]);
  return (
    <DisplayError
      title={title}
      message={message}
      severity={severity}
      clipboardText={clipboardText}
      sessionId={sessionId}
    />
  );
};
