import { type SWRConfiguration, type SWRResponse } from "swr";
import { type SetInTest, hasMutated as hasMutatedSet } from "./hasMutated";
import { isNotFetched } from "./isNotFetched";
import { type ValueKey } from "@/api/types/AspidaSWROption";

export type MutateOnce = (args: {
  key: ValueKey;
  response: SWRResponse;
  revalidateOnMount: SWRConfiguration["revalidateOnMount"];
}) => void;

export const mutateOnceWith = (
  hasMutated: SetInTest | Set<string>,
): MutateOnce =>
  function mutateOnce({ key, response, revalidateOnMount }) {
    if (revalidateOnMount !== false || key === null) {
      return;
    }

    const dataIsNotFetched = isNotFetched(response);
    if (dataIsNotFetched && !hasMutated.has(key)) {
      response.mutate();
      hasMutated.add(key);
    }
  };

/**
 * revalidateOnMount = false の時、リクエストされない挙動を修正するための主要ロジック。
 * cf. https://github.com/vercel/swr/issues/943
 * cf. https://github.com/vercel/swr/issues/455#issuecomment-643504686

 * 同一 key で複数回 mutate 関数が実行されるとその分リクエストが送られてしまうので
 * そうならないように key 毎に mutate の呼び出し回数を管理する。
 */
export const mutateOnce = mutateOnceWith(hasMutatedSet);
