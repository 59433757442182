// ja.ts になかったです
export const COLUMNS_LABELS_FAMILY_CUSTOMER_DETAIL_SURVEY_LABELS = {
  id: "ID",
  uuid: "UUID",
  name: "ラベル",
  regex_name: "正規表現ラベル",
  type: "種類",
  yes_label: "はいラベル",
  no_label: "いいえラベル",
};
