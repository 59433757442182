import { type AppProps } from "next/app";
import SignIn from "@/components/sign-in/SignIn";
import TestMode from "@/components/essentials/styled/TestMode";
import { useSignIn } from "@/hooks/useSignIn";
import { isNotProduction } from "@/helper/env";
import { type DatadogAttributes } from "@/types/datadog";

interface Props extends AppProps, DatadogAttributes {}

const Download = ({ Component, pageProps, ddAllowPrivacy }: Props) => {
  const { isSignIn } = useSignIn();

  return (
    <>
      {isNotProduction() && (
        <TestMode position={isSignIn ? "static" : undefined} ddAllowPrivacy />
      )}
      {isSignIn ? (
        <Component {...pageProps} />
      ) : (
        <SignIn ddAllowPrivacy={ddAllowPrivacy} />
      )}
    </>
  );
};

export default Download;
