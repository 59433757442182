export const site = "datadoghq.com";
export const region = "us1";
export const datadogVersion = "v4";

export const clientToken = process.env.DATADOG_CLIENT_TOKEN;
// for datadog rum
export const applicationId = process.env.DATADOG_APPLICATION_ID;

export const mustBeLogsAndRumSameConfigs = {
  trackSessionAcrossSubdomains: false,
  useSecureSessionCookie: true,
  useCrossSiteSessionCookie: false,
};
