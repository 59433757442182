/**
 * デフォルトのブレイクポイントに準拠している
 * https://mui.com/material-ui/customization/breakpoints/#default-breakpoints
 */
export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
} as const;

export type BreakPointKey = keyof typeof breakpoints;
