export const COLUMNS_LABELS_TOY_SUPPLIER_STAFFS = {
  id: "ID",
  uuid: "UUID",
  department: "部署",
  direct_tel: "直通電話番号",
  first_name: "名前",
  first_name_of_pronunciation: "名前（カナ）",
  last_name: "苗字",
  last_name_of_pronunciation: "苗字（カナ）",
  mail: "メールアドレス",
  tel: "電話番号",
};
