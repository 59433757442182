export const numberFormat = (
  number: number | undefined,
): string | undefined => {
  if (number === undefined) return undefined;
  return new Intl.NumberFormat("en-US").format(number);
};

export const formatNumber = (number: number | undefined): string => {
  return numberFormat(number) ?? "";
};
