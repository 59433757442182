import { type FieldValues, get, useFormContext } from "react-hook-form";
import { TextField, type TextFieldProps } from "./TextField";
import { type RhfRegisterForm } from "@/types/react-hook-form";
import { isNotProduction } from "@/helper/env";
import { type DatadogAttributes } from "@/types/datadog";

export type RhfTextFieldProps<TFieldValues extends FieldValues = FieldValues> =
  RhfRegisterForm<TFieldValues> &
    Partial<Omit<TextFieldProps, "name">> &
    DatadogAttributes;

export const RhfTextField = <T extends FieldValues = FieldValues>({
  register,
  errors: errorsProp,
  inputProps,
  ddAllowPrivacy,
  ...textFieldProps
}: RhfTextFieldProps<T>) => {
  const { ref, name, ...restRegister } = register;
  const { formState } = useFormContext();
  const errors = errorsProp ?? formState.errors;
  if (isNotProduction() && errors === undefined) {
    throw new Error(`「${name}」フィールドに "errors" を渡してください。 `);
  }
  const error = get(errors, name);
  return (
    <TextField
      inputRef={ref}
      error={error !== undefined}
      helperText={error?.message?.toString()}
      name={name}
      required={restRegister.required}
      inputProps={{ ...restRegister, ...inputProps }}
      ddAllowPrivacy={ddAllowPrivacy}
      {...textFieldProps}
    />
  );
};
