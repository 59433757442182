import {
  Controller,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type PathValue,
} from "react-hook-form";
import { type ReactElement } from "react";
import { Checkbox, type CheckboxProps } from "./Checkbox";
import { type RhfControllerForm } from "@/types/react-hook-form";
import { type DatadogAttributes } from "@/types/datadog";
import { type Overwrite } from "@/types/helpers";

type RhfCheckboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  defaultValue?: FieldPathValue<TFieldValues, TName> | boolean;

  /**
   * `name` prop で参照する react-hook-form 内の値を boolean に変換する関数
   */
  checked?: (value: FieldPathValue<TFieldValues, TName>) => boolean;

  /**
   * true/false それぞれの場合において、 `name` prop が指し示す Field に設定する値
   */
  rhfValue?: {
    true: FieldPathValue<TFieldValues, TName>;
    false: FieldPathValue<TFieldValues, TName>;
  };
} & Omit<CheckboxProps, "name" | "checked" | "defaultValue"> &
  RhfControllerForm<TFieldValues, TName> &
  DatadogAttributes;

/**
 * @note
 * `control` (TFieldValues) と `name` (TName) が与えられている時の型
 */
type RhfCheckboxPropsWithTypedControl<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = Overwrite<
  RhfCheckboxProps<TFieldValues, TName>,
  { defaultValue?: FieldPathValue<TFieldValues, TName> }
>;

/**
 * @note
 * `control` prop が渡されていない場合、 `name` prop で参照する value の値は boolean 型とする。
 * 型が確定しないときに defaultValue 等が any となることを防ぐ。
 */
type RhfCheckboxPropsWithoutControl = Overwrite<
  RhfCheckboxProps,
  { defaultValue?: boolean }
>;

export const RhfCheckbox: {
  // Overload
  (props: RhfCheckboxPropsWithoutControl): ReactElement;

  // Overload
  <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  >(
    props: RhfCheckboxPropsWithTypedControl<TFieldValues, TName>,
  ): ReactElement;
} = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: RhfCheckboxProps<TFieldValues, TName>,
) => {
  const {
    control,
    name,
    rules,
    defaultValue,
    onChange,
    required,
    checked,
    rhfValue,
    ddAllowPrivacy,
    ...rest
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={{ ...rules, required }}
      defaultValue={defaultValue as PathValue<TFieldValues, TName>}
      render={({ field: { ref, value, onChange: rhfOnChange, ...fields } }) => (
        <Checkbox
          inputRef={ref}
          onChange={(e, muiChecked) => {
            rhfOnChange(
              rhfValue?.[String(muiChecked) as "true" | "false"] ?? muiChecked,
            );
            onChange?.(e, muiChecked);
          }}
          checked={checked?.(value) ?? value === true}
          ddAllowPrivacy={ddAllowPrivacy}
          {...fields}
          {...rest}
        />
      )}
    />
  );
};

export const createRhfCheckboxProps = <
  TFieldValues extends FieldValues = FieldValues,
>(
  /**
   * @params checkbox の値が変化した際の bool値
   * @return RHF の当該 filed に保存したい値
   */
  rhfValueCreator: (
    checked: boolean,
  ) => FieldPathValue<TFieldValues, FieldPath<TFieldValues>>,
) => {
  return {
    rhfValue: {
      true: rhfValueCreator(true),
      false: rhfValueCreator(false),
    },
    defaultValue: rhfValueCreator(false),
  };
};
