export const COLUMNS_LABELS_FAMILY_CUSTOMERS = {
  id: "ID",
  uuid: "UUID",
  family_id: "家族ID",
  first_name: "名前",
  last_name: "苗字",
  tel: "電話番号",
  mail: "メールアドレス",
  relationship: "続柄",
  last_name_of_pronunciation: "苗字 (カナ)",
  first_name_of_pronunciation: "名前 (カナ)",
  caution_score: "要注意スコア",
  created_at: "登録日時",
  updated_at: "更新日時",
  application_channel: "申し込みチャネル",
};
