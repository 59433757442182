import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getInitialState, type RehydratePager } from "@/types/Pager";
import { type ToyPartsQuantityRate } from "@/openapi/api";

type PayloadType = RehydratePager<ToyPartsQuantityRate>;
const initialState = getInitialState<ToyPartsQuantityRate>();

const ToysPartsQuantitiesRatesSlice = createSlice({
  name: "toyPartsQuantityRates",
  initialState,
  reducers: {
    rehydrateToysPartsQuantitiesRates: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateToysPartsQuantitiesRates } =
  ToysPartsQuantitiesRatesSlice.actions;
export default ToysPartsQuantitiesRatesSlice.reducer;
