import { type AxiosRequestTransformer } from "axios";
import { isObject } from "remeda";
import { camelcaseKeys } from "@/helper";

export const intoFrontendFormat: AxiosRequestTransformer = (data) => {
  if (isObject(data)) {
    return camelcaseKeys(data);
  }
  return data;
};
