/**
 *
 * @deprecated
 * 新規機能作成にこの関数は利用しないでください
 * 代わりに、`getUrl`関数を利用してください
 */
export const makeUrl = (path?: string): string => {
  const replacedPath = (path ?? "").replace(/\/+/, "");

  return `${process.env.APP_ENDPOINT}/${replacedPath}`;
};

export const madrasUrl = (path?: string): string => {
  const { host, protocol } = window.location;
  return `${protocol}//${host}/${path ?? ""}`;
};

export const convertDotToBracket = (key: string | undefined): string => {
  if (key === undefined) return "";
  return key
    ?.split(".")
    .map((name, i) => {
      if (i > 0) {
        return `[${name}]`;
      }
      return name;
    })
    .join("");
};

export const removeQueries = (query: string | undefined): string => {
  if (query === undefined) return "";
  return query?.replace(/\?.+$/, "");
};
