import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ToyMaker } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<ToyMaker>;
const initialState = getInitialState<ToyMaker>();

const ToysMakersSlice = createSlice({
  name: "toysMakers",
  initialState,
  reducers: {
    rehydrateToysMakers: (state, { payload }: PayloadAction<PayloadType>) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateToysMakers } = ToysMakersSlice.actions;
export default ToysMakersSlice.reducer;
