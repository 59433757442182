import { isFalsyString } from "@/helper/string";

export interface ValueFilter {
  name: string;
  value: string;
}

export const createValueFilterQuery = (valueFilter?: ValueFilter) => {
  if (valueFilter === undefined || isFalsyString(valueFilter.value)) {
    return {};
  }

  const { name, value } = valueFilter;
  return {
    // フィルター検索方法の一つ。
    // cf. https://github.com/torana-us/madras/blob/62cf5371d9c5a1335d79b7fe3243cdf1fec896b1/src/components/FilterGroupBox.tsx#L156
    [`${name}[value]`]: value,
  };
};

export interface UuidFilter {
  name: string;
  uuid: string;
}

export const createUuidFilterQuery = (uuidFilter?: UuidFilter) => {
  if (uuidFilter === undefined || isFalsyString(uuidFilter.uuid)) {
    return {};
  }

  const { name, uuid } = uuidFilter;
  return {
    // フィルター検索方法の一つ。
    // cf. https://github.com/torana-us/madras/blob/62cf5371d9c5a1335d79b7fe3243cdf1fec896b1/src/components/FilterGroupBox.tsx#L117
    [`${name}[uuid]`]: uuid,
  };
};

export interface UuidsFilter {
  name: string;
  uuids: string[];
}

export const createUuidsFilterQuery = (uuidsFilter?: UuidsFilter) => {
  if (uuidsFilter === undefined || uuidsFilter.uuids.length === 0) {
    return {};
  }

  const { name, uuids } = uuidsFilter;
  return {
    [`${name}[uuid][]`]: uuids,
  };
};

export interface DatePeriodFilter {
  name: string;
  startedAt?: string;
  endAt?: string;
}

export const createDatePeriodFilterQuery = (
  datePeriodFilter?: DatePeriodFilter,
) => {
  if (!datePeriodFilter) {
    return {};
  }

  const { name, startedAt, endAt } = datePeriodFilter;
  return {
    [`${name}[start]`]: startedAt,
    [`${name}[end]`]: endAt,
  };
};
