import { type SWRResponse } from "swr";

/**
 * isValidating でも isLoading でもない状態、つまりデータが取得されているはずの状態において、
 * data === undefined である場合、 true を返却する。
 */
export const isNotFetched = ({
  isLoading,
  isValidating,
  data,
}: Pick<SWRResponse, "data" | "isLoading" | "isValidating">) => {
  // isValidating でも isLoading でもない状態
  const isSettled = !isValidating && !isLoading;
  return isSettled && data === undefined;
};
