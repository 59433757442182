export const inventoryFilterLabels = {
  uuid: "UUID",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
  implementationDate: {
    start: "棚卸し実施日 (開始)",
    end: "棚卸し実施日 (終了)",
  },
  name: "棚卸し実施名",
  title: "タイトル",
  inventoryItems_Uuid: "棚卸し商品のUUID",
  staff: "スタッフ",
  staff_Avatar: "スタッフのアバター",
  staff_Department: "スタッフの部署",
  staff_Mail: "スタッフのメールアドレス",
  staff_Name: "スタッフの名前",
  staff_Uuid: "スタッフのUUID",
  warehouse: "事業所・倉庫",
  warehouse_Address1: "倉庫住所 1",
  warehouse_Address2: "倉庫住所 2",
  warehouse_Name: "倉庫名",
  warehouse_Pref: "倉庫の都道府県",
  warehouse_Tel: "倉庫の電話番号",
  warehouse_Uuid: "倉庫のUUID",
  warehouse_ZipCode: "倉庫の郵便番号",
};
