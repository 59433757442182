export const COLUMNS_LABELS_FAMILY_CUSTOMER_COUPONS = {
  id: "顧客のクーポンのID",
  uuid: "顧客のクーポンのUUID",
  family_customer_id: "顧客ID",
  coupon_id: "クーポンのID",
  coupon_status_id: "クーポンステータスのID",
  price: "クーポンの利用金額",
  note: "クーポンの備考",
  created_at: "クーポンの登録日時",
};
