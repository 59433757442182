import { getValueByAnyKey } from "@/helper/object";

/**
 * @example
 * ```
 *  const evolver = pickEvolve({ a: Number, b: { c: String }, e: true });
 *  const source =         { a: "1", b: { c:  2  }, d: 3,     e: 4 };
 *  const received = evolver(source);
 *  const expected =       { a:  1,  b: { c: "2" },           e: 4 };
 *  expect(received).toEqual(expected);
 * ```
 */

export const pickEvolve =
  <Keys extends string>(
    transformations: Partial<
      Record<Keys, true | Record<string, any> | ((prop: any) => any)>
    >,
  ) =>
  (object: Record<string, any>) => {
    const result = {} as Record<string, any>;
    Object.keys(object).forEach((key) => {
      const transformation = getValueByAnyKey(transformations, key);

      const value = (() => {
        if (transformation === true) {
          return object[key];
        }
        if (typeof transformation === "function") {
          return transformation(object[key]);
        }
        if (typeof transformation === "object") {
          return pickEvolve(transformation)(object[key]);
        }
        return false;
      })();
      if (value !== false && transformation !== undefined) {
        result[key] = value;
      }
    });
    return result;
  };
