import { is } from "@/helper/predicate";

/**
 * object x の key と value に対してそれぞれ、再帰的に keyFn, valueFn を適用する。
 * ※ 型変換は複雑になるため any を利用。
 */

export const createNestedObjectWith =
  (keyFn: (key: string) => string, valueFn: (value: any) => any) =>
  <Return = any>(x: any): Return => {
    const creator = createNestedObjectWith(keyFn, valueFn);

    if (is(Map, x) || is(Set, x)) {
      console.warn("Map and Set are not supported.");
      return x as any;
    }

    if (is(Array, x)) {
      return x.map((xx) => creator(xx)) as any;
    }

    if (is(Object, x)) {
      return Object.keys(x).reduce((acc, key) => {
        const newKey = keyFn?.(key) ?? key;
        const newVal = creator(x[key]);
        return { ...acc, [newKey]: newVal };
      }, {}) as any;
    }
    return valueFn(x);
  };
