// ja.ts になかったです
export const COLUMNS_LABELS_TOY_PARTS_IMAGES = {
  id: "ID",
  uuid: "UUID",
  toy_id: "おもちゃのID",
  mime_type: "MIMEタイプ",
  original_name: "元の名前",
  name: "名前",
  size: "サイズ",
  path: "パス",
};
