import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getInitialState, type RehydratePager } from "@/types/Pager";
import { type StockToy } from "@/openapi/api";

type PayloadType = RehydratePager<StockToy>;
const initialState = getInitialState<StockToy>();

const StocksToysSlice = createSlice({
  name: "stocksToys",
  initialState,
  reducers: {
    rehydrateStocksToys: (state, { payload }: PayloadAction<PayloadType>) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateStocksToys } = StocksToysSlice.actions;
export default StocksToysSlice.reducer;
