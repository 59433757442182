import { JPY } from "@/configs/exchange-currency";
import { type Stock } from "@/api/generated/openapi-typescript/apiv1/schemas/camel";

export const calculateStockPaidPrice = (stock: Stock | undefined): number => {
  const extra =
    (stock?.exchangeCurrency ?? JPY) === JPY ? 1 : (stock?.exchangeRate ?? 1);
  return (
    stock?.stockToys?.reduce((p, c) => p + (c.paidPrice ?? 0) * extra, 0) ?? 0
  );
};

export default { calculateStockPaidPrice };
