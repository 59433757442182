// ja.ts になかったです
export const COLUMNS_LABELS_FAMILY_CUSTOMER_TOY_IMAGES = {
  id: "ID",
  uuid: "UUID",
  family_customer_id: "顧客ID",
  name: "名前",
  original_name: "元の名前",
  size: "サイズ",
  path: "パス",
};
