import Router from "next/router";
import { type AxiosResponse } from "axios";
import { type FieldValues } from "react-hook-form";
import { type OptionalArgsForSaveHandle, type PostRequest } from "./types";
import { hasUuid, poppedPathUrl } from "@/helper";

export const createOnPost =
  (
    routerPushArgsCreator: (res: {
      uuid: string;
    }) => Parameters<(typeof Router)["push"]>[0],
  ) =>
  (res: unknown) => {
    if (hasUuid(res)) {
      Router.push(routerPushArgsCreator(res));
    }
  };

/**
 * 多くの画面では歴史的事情によりpathnameのslugが`[id]`固定値になっているため、
 * これを利用しPOST後のデフォルトの挙動を共通化している。
 * しかし、slugが別の値、例えばcustomer/[id]/contract/[contractId]などだった場合は
 * console.errorが発火されるので、その場合はonPostの引数に別途createOnPost関数で作成した関数を渡すようにすること。
 */
const createDefaultOnPost = (basePagePath?: string) =>
  createOnPost((res) => ({
    pathname: `${basePagePath}/[id]`,
    query: {
      id: res.uuid,
    },
  }));

export interface CreatePostHandlerArgs
  extends PostRequest,
    OptionalArgsForSaveHandle {
  basePagePath?: string;
}
export const createPostHandler = ({
  post,
  basePagePath: basePagePathProp,
  onResponse,
  onError,
  convertRequestBody,
}: CreatePostHandlerArgs) => {
  if (post === undefined) {
    return undefined;
  }
  const basePagePath = basePagePathProp ?? poppedPathUrl();
  return async (data: FieldValues) => {
    const finalOnPost = onResponse ?? createDefaultOnPost(basePagePath);

    const body = convertRequestBody?.(data) ?? data;
    return post({ body })
      .then((res: AxiosResponse) => finalOnPost(res.data))
      .catch((err: unknown) => {
        onError?.(err);
        throw err;
      });
  };
};
