import { type SWRConfiguration } from "swr";
import { mapValues } from "remeda";
import { immutableConfig } from "@/configs/swr";

const configToRevalidate = mapValues(immutableConfig, () => true as const);

/**
 * @param immutable
 * * true の場合, useSWRImmutable と同様の挙動になる。cf. https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
 * * false の場合、useSWRImmutable と正反対の挙動になる。
 */
export const createRevalidateConfig = (
  immutable?: boolean,
): Partial<SWRConfiguration> => {
  const variableConfig = (() => {
    if (immutable === true) return immutableConfig;
    if (immutable === false) return configToRevalidate;
    return {}; // use default config
  })();
  return variableConfig;
};
