export const couponCustomerFilterLabels = {
  couponStatus_Name: "クーポンステータス",
  couponStatus_Uuid: "クーポンステータスのUUID",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  description: "利用詳細",
  expiresAt: {
    start: "有効期限 (開始)",
    end: "有効期限 (終了)",
  },
  familyCustomer_ApplicationChannel: "顧客の申し込みチャネル",
  familyCustomer_FirstName: "顧客の名前",
  familyCustomer_FirstNameOfPronunciation: "顧客の名前 (カナ)",
  familyCustomer_Id: "顧客のID",
  familyCustomer_LastName: "顧客の苗字",
  familyCustomer_LastNameOfPronunciation: "顧客の苗字 (カナ)",
  familyCustomer_Mail: "顧客のメールアドレス",
  familyCustomer_Relationship: "顧客の続柄",
  familyCustomer_Tel: "顧客の電話番号",
  familyCustomer_Uuid: "顧客のUUID",
  issuedAt: {
    start: "発行日 (開始)",
    end: "発行日 (終了)",
  },
  note: "備考",
  price: "利用金額",
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
  uuid: "UUID",
};
