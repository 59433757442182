export const staffFilterLabels = {
  uuid: "UUID",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
  department: "部署名",
  mail: "メールアドレス",
  name: "スタッフ名",
  permission: "権限",
  staffAccessKeys_AccessKey: "スタッフアクセスキーのアクセスキー",
  staffAccessKeys_SecretKey: "スタッフアクセスキーのシークレットキー",
  staffAccessKeys_Uuid: "スタッフアクセスキーのUUID",
  warehouse: "事業所・倉庫",
  avatar: "スタッフのアバター",
  warehouse_Address1: "倉庫住所 1",
  warehouse_Address2: "倉庫住所 2",
  warehouse_Name: "倉庫名",
  warehouse_Pref: "倉庫の都道府県",
  warehouse_Tel: "倉庫の電話番号",
  warehouse_Uuid: "倉庫のUUID",
  warehouse_ZipCode: "倉庫の郵便番号",
};
