import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getInitialState, type RehydratePager } from "@/types/Pager";
import { type ToyCategory } from "@/openapi/api";

type PayloadType = RehydratePager<ToyCategory>;
const initialState = getInitialState<ToyCategory>();

const ToysCategoriesSlice = createSlice({
  name: "toysCategories",
  initialState,
  reducers: {
    rehydrateToysCategories: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateToysCategories } = ToysCategoriesSlice.actions;
export default ToysCategoriesSlice.reducer;
