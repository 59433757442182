import { createTheme } from "@mui/material/styles";
import { orange, pink } from "@mui/material/colors";
import { breakpoints } from "./breakpoint";

declare module "@mui/material/styles/createTheme" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface DeprecatedThemeOptions {
    status?: {
      danger?: string;
    };
  }
}
export const primary = pink;
export const secondary = orange;
export const theme = createTheme({
  breakpoints: {
    values: breakpoints,
  },
  palette: {
    primary,
    secondary,
  },
  typography: {
    fontFamily: ['"M PLUS 1p"', "sans-serif"].join(","),
    allVariants: {
      transform: "rotate(0.05deg)", // ジャギーの緩和
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: "12px",
        },
        body: {
          fontSize: "12px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        filledError: {
          backgroundColor: "#f44336",
        },
        filledSuccess: {
          backgroundColor: "#43a047",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: "inherit",
      },
      styleOverrides: {
        outlinedInherit: {
          borderColor: "#0000003b",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "14px",
          color: "#000",
          backgroundColor: "#fff",
          border: "1px solid #ddd",
          padding: "15px",
          boxShadow: "0 0 5px #eee",
          fontFamily: "M PLUS 1p, sans-serif",
        },
        arrow: {
          "&:before": {
            border: "1px solid #ddd",
          },
          color: "#fff",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          transform: "initial",
        },
      },
    },
  },
});
