import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

const initialState = false;

const LoadingFullScreenSlice = createSlice({
  name: "loadingFullScreen",
  initialState,
  reducers: {
    rehydrateLoadingFullScreen: (state: any, action: PayloadAction<boolean>) =>
      action.payload,
  },
});

export const { rehydrateLoadingFullScreen } = LoadingFullScreenSlice.actions;
export default LoadingFullScreenSlice.reducer;
