import styled from "@emotion/styled";
import GoogleIcon from "@mui/icons-material/Google";
import { makeUrl } from "@/helper/url-helper";
import { type DatadogAttributes } from "@/types/datadog";
import { addDDAllowPrivacy } from "@/helper/datadog";

const SignInBox = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);
  background-size: cover;
  background-position: 50%;
`;

const LayeredSignInBox = styled.div`
  background-image: url("/images/sign-in/bg.jpg");
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
`;

const LoginButton = styled.div`
  border: 1px solid #e91e63;
  border-radius: 4px;
  padding: 12px 24px;
  box-shadow: 0 0 2px #cccccc;
  cursor: pointer;
  text-align: center;
  color: #e91e63;
`;

const PageTitle = styled.div`
  color: #e91e63;
  font-size: 42px;
  font-weight: bold;
  margin-bottom: 32px;
  text-align: center;
`;

const SignInButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const SignInLabel = styled.div`
  margin-left: 4px;
`;

const SignInField = styled.div`
  background: rgba(255, 255, 255, 0.8);
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

export default function SignIn(props: DatadogAttributes): JSX.Element {
  const { ddAllowPrivacy } = props;
  const signIn = () => {
    // biome-ignore lint/style/noRestrictedGlobals :[AUTOMATICALLY-INSERTED] Do not use the global variable location.
    location.href = makeUrl(
      `/oauth/v2.0/auth/google?redirect_url=${encodeURIComponent(
        // biome-ignore lint/style/noRestrictedGlobals :[AUTOMATICALLY-INSERTED] Do not use the global variable location.
        location.href,
      )}&type=1`,
    );
  };

  return (
    <LayeredSignInBox>
      <SignInBox>
        <SignInField>
          <PageTitle {...addDDAllowPrivacy(ddAllowPrivacy)}>MADRAS</PageTitle>
          <LoginButton onClick={() => signIn()}>
            <SignInButton>
              <GoogleIcon />
              <SignInLabel {...addDDAllowPrivacy(ddAllowPrivacy)}>
                サインインする
              </SignInLabel>
            </SignInButton>
          </LoginButton>
        </SignInField>
      </SignInBox>
    </LayeredSignInBox>
  );
}
