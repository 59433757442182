import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type PaymentCycle } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<PaymentCycle>;
const initialState = getInitialState<PaymentCycle>();

const PaymentsCyclesSlice = createSlice({
  name: "paymentsCycles",
  initialState,
  reducers: {
    rehydratePaymentsCycles: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydratePaymentsCycles } = PaymentsCyclesSlice.actions;
export default PaymentsCyclesSlice.reducer;
