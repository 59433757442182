import { type APIErrorType } from "./types";
import { getErrorType } from "./_helpers/get-error-type";
import { type MadrasAPIResponseErrorConstructor } from "../classes/madras-api/types";
import {
  isAxiosNetWorkError,
  isAxiosTimeout,
  isAxiosAborted,
} from "@/api/helpers/predicates";
import { isFetchAborted, isFetchTimeout } from "@/api/helpers/predicates/fetch";

export const errorTypeFromAxios = (
  error: MadrasAPIResponseErrorConstructor<"axios">["error"],
): APIErrorType => {
  if (isAxiosAborted(error)) return "abort";
  if (isAxiosTimeout(error)) return "timeout";
  if (isAxiosNetWorkError(error)) return "network";

  return getErrorType(error?.response?.status, error.response?.data);
};
/**
 * responseボディを取り出そうとすると、response.json()がPromiseになり、
 * 処理全体が非同期処理になってしまうので、事前にclass内部に保存されているbodyを使用する
 */

export const errorTypeFromFetch = (
  error: Pick<
    MadrasAPIResponseErrorConstructor<"fetch">["error"],
    "userError" | "response"
  >,
): APIErrorType => {
  // network系のエラーの場合はtypeErrorが存在する
  // https://developer.mozilla.org/ja/docs/Web/API/Fetch_API/Using_Fetch#%E3%83%95%E3%82%A7%E3%83%83%E3%83%81%E3%81%8C%E6%88%90%E5%8A%9F%E3%81%97%E3%81%9F%E3%81%8B%E3%81%AE%E7%A2%BA%E8%AA%8D
  const errorInstance = error?.userError;
  if (errorInstance instanceof Error) {
    if (isFetchAborted(errorInstance)) return "abort";
    if (isFetchTimeout(errorInstance)) return "timeout";
    // responseが来ていれば空オブジェクトであるはずがないので、チェックする
    if (error?.response === undefined) return "network";
  }

  return getErrorType(error?.response?.status, error.response?.body);
};
