import { useRouter } from "next/router";
import { getStringQuery } from "@/helper/next-js";
import { copyQueryParamKey } from "@/components/fragments/detail-edit-manipulator/templates/copy-menu-button/constants";

/**
 * @note
 * * router.isReady === false の時、url slugs の値は undefined である可能性がある。
 * * create モード（url の path が /foo/bar/add）であっても、uuid を利用する場合がある。
 */
export const useUuid = (
  uuidName = "id",
): {
  /**
   * コピー対象のエンティティの uuid、または、引数 `uuidName` で取得される url slug の uuid
   */
  uuid: string | undefined;
  /**
   * 引数 `uuidName` で取得される url slug の uuid
   */
  uuidSlugValue: string | undefined;
  /**
   * @deprecated
   * router.isReady の考慮が必要であり扱いにくいため, deprecated とした。
   */
  hasUuid: boolean;
  router: ReturnType<typeof useRouter>;
} => {
  const router = useRouter();
  const uuidSlugValue = getStringQuery(uuidName, router.query);
  const copyTargetUuid = getStringQuery(copyQueryParamKey, router.query);
  const uuid = copyTargetUuid ?? uuidSlugValue;
  const hasUuid = uuid !== undefined;

  return { uuid, hasUuid, uuidSlugValue, router };
};
