import { type AxiosRequestTransformer } from "axios";
import snakecaseKeys from "snakecase-keys";
import { isObject } from "remeda";
import { is } from "@/helper";

export const intoBackendFormat: AxiosRequestTransformer = (data, headers) => {
  if (!is(FormData, data) && isObject(data)) {
    return snakecaseKeys(data, { deep: true });
  }

  return data;
};
