import { omit as omitRemeda, pick as pickRemeda } from "remeda";
import { toSingleDepthObject } from "../flatten";
import { type MapType } from "@/types/helpers/MapType";

export type SimpleObject = { [key: string]: any };

/**
 * `object[key]` という value の取り出し方をしたときに, 下記エラーを回避する.
 * `Element implicitly has an 'any' type because expression of type 'string' can't be used to index`
 */
export const getValueByAnyKey = <O extends Record<string, any>>(
  object: O,
  anyKey: string,
) => object[anyKey] as O[keyof O] | undefined;

// https://remedajs.com/docs#omit
export const omit = omitRemeda;

/**
 * @see https://remedajs.com/docs#pick
 */
export const pick = pickRemeda;

export const splitObject = <
  Names extends readonly string[],
  Obj extends SimpleObject,
>(
  names: Names,
  obj: Obj,
): [Pick<Obj, Names[number]>, Omit<Obj, Names[number]>] => {
  return [pick(obj, names), omit(obj, names)];
};

export function shallowObjectToFormData(obj: SimpleObject): FormData {
  return Object.entries(obj).reduce((acc, entry) => {
    acc.append(...entry);
    return acc;
  }, new FormData());
}

export const objectToFormData: (obj: SimpleObject) => FormData = (o) =>
  shallowObjectToFormData(toSingleDepthObject(o));

export const mapForObj = <Obj extends Record<string, T>, T, U>(
  obj: Obj,
  f: (x: T) => U,
): MapType<Obj, U> =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => {
      return { ...acc, [key]: f(value) };
    },
    {} as Record<string, U>,
  ) as MapType<Obj, U>;

export const getTrueKeys = (key2boolean: Record<string, boolean>): string[] =>
  Object.keys(key2boolean).reduce<string[]>(
    (acc, key) => (key2boolean[key] ? [...acc, key] : acc),
    [],
  );
export const getTrueIndexes = (
  index2boolean: Record<number, boolean>,
): number[] => getTrueKeys(index2boolean).map(Number);

export const filterNotIncludeUndefined = <T>(arr: T[]): T[] =>
  arr.filter((x) => x !== undefined);
