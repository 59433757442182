import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type IndividualToyStatus } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<IndividualToyStatus>;
const initialState = getInitialState<IndividualToyStatus>();

const IndividualsToysStatusesSlice = createSlice({
  name: "individualsToysStatuses",
  initialState,
  reducers: {
    rehydrateIndividualsToysStatuses: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateIndividualsToysStatuses } =
  IndividualsToysStatusesSlice.actions;
export default IndividualsToysStatusesSlice.reducer;
