export const COLUMNS_LABELS_TOY_SUPPLIERS = {
  id: "ID",
  uuid: "UUID",
  comment: "コメント",
  condition_of_fee: "料金条件",
  condition_of_purchase: "購入条件",
  name: "名前", // ja.ts:801 で "仕入れ先企業名" となっていました
  note_of_how_to_return: "返却方法",
  order_direct_tel: "発注直通電話番号",
  order_first_name: "発注担当者名（名前）",
  order_first_name_of_pronunciation: "発注担当者名（名前・カナ）",
  order_last_name: "発注担当者名（苗字）",
  order_last_name_of_pronunciation: "発注担当者名（苗字・カナ）",
  order_mail: "発注メールアドレス",
  order_tel: "発注代表電話番号",
  purchase_note: "購入時メモ",
  return_address: "返品先住所",
  return_address_zip_code: "返品先郵便番号",
  url: "URL",
};
