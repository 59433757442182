import { Typography as MuiTypography } from "@mui/material";
import { type ComponentPropsWithoutRef, forwardRef } from "react";
import { type DatadogAttributes } from "@/types/datadog";
import { addDDAllowPrivacy } from "@/helper/datadog";

export type TypographyProps = ComponentPropsWithoutRef<typeof MuiTypography> & {
  dataTestId?: string;
} & DatadogAttributes;

export const Typography = forwardRef<HTMLDivElement, TypographyProps>(
  (props, ref) => {
    const { ddAllowPrivacy, ...rest } = props;
    return (
      <MuiTypography
        ref={ref}
        {...(props.dataTestId ? { "data-testid": props.dataTestId } : {})}
        {...addDDAllowPrivacy(ddAllowPrivacy)}
        {...rest}
      />
    );
  },
);

Typography.displayName = "Typography";
