import { isPrd, isQa, isStg } from "./env";
import { ddLogs } from "@/lib/datadog/datadog";

const levels = ["info", "warn", "error"] as const;
type Level = (typeof levels)[number];
export type Logger = (message: string, context?: object, err?: Error) => void;

/**
 * datadog logsにログを出力するかどうかの条件
 */
const isRemote = isStg() || isPrd() || isQa();

const logMsg = (level: Level, msg: string) =>
  isRemote ? msg : `logger.${level}: ${msg}`;

const baseLog = (
  level: Level,
  message: string,
  context?: object,
  err?: Error,
) => {
  // 出力文字列からundefinedが除外されるようにする
  const optionalArr = [context, err].filter((v) => v !== undefined);
  if (isRemote) {
    ddLogs((datadogLogs) => {
      datadogLogs.logger?.[level](logMsg(level, message), ...optionalArr);
    });
  } else {
    console?.[level](logMsg(level, message), ...optionalArr);
  }
};
const warn: Logger = (message, context) => {
  baseLog("warn", message, context);
};

const error: Logger = (message, context, err) => {
  baseLog("error", message, context, err);
};

const info: Logger = (message, context) => {
  baseLog("info", message, context);
};

export const logger = {
  info,
  warn,
  error,
};
