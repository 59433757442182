export enum CamelizeOption {
  CAMELIZE_BOTH,
  CAMELIZE_KEY_ONLY,
}

export const camelizeString = (s: string | undefined): string => {
  if (s === undefined) return "";
  return s.replace(/_([a-z])/g, (v) => v.replace(/^_/, "").toUpperCase());
};

export const camelize = (
  o: any,
  option = CamelizeOption.CAMELIZE_BOTH,
): any => {
  if (o === null) {
    return null;
  }
  if (Array.isArray(o)) {
    const newArray = [];
    for (const k of o) {
      newArray.push(camelize(k, option));
    }
    return newArray;
  }
  if (typeof o === "object") {
    return Object.keys(o).reduce<Record<string, any>>((carry, keyName) => {
      carry[camelizeString(keyName)] = camelize(o[keyName], option);
      return carry;
    }, {});
  }

  if (option === CamelizeOption.CAMELIZE_BOTH && typeof o === "string") {
    return camelizeString(o);
  }

  return o;
};
