import {
  couponCustomerFilterLabels,
  couponTypeFilterLabels,
  customerFilterLabels,
  inspectionFilterLabels,
  inventoryFilterLabels,
  planFilterLabels,
  shipmentFilterLabels,
  staffFilterLabels,
  stockFilterLabels,
  subscriptionFilterLabels,
  toyCategoryFilterLabels,
  toyFilterLabels,
  toyIndividualFilterLabels,
  toyMakerFilterLabels,
  toySupplierFilterLabels,
  warehouseTransferFilterLabels,
} from "@/constants/config/filterBox/labels";
import {
  PAGE_COUPON_CUSTOMER,
  PAGE_COUPON_TYPE,
  PAGE_CUSTOMER,
  PAGE_CUSTOMER_CONTRACTED_PLAN,
  PAGE_INDIVIDUAL_TOY,
  PAGE_INSPECTION,
  PAGE_INVENTORY,
  PAGE_PLAN,
  PAGE_PLANNER,
  PAGE_RETURN,
  PAGE_SHIPMENT,
  PAGE_STAFF,
  PAGE_STOCK,
  PAGE_SUBSCRIPTION,
  PAGE_TOY,
  PAGE_TOY_CATEGORY,
  PAGE_TOY_MAKER,
  PAGE_TOY_SUPPLIER,
  PAGE_WAREHOUSE_TRANSFER,
} from "@/constants/config/pages";

/**
 * @type DBの hoge_atに寄せたイメージ優先の名前
 * */
export type DateAt = {
  start: string;
  end: string;
  name?: string;
};

/**
 * @type
 * 確実にpageId.key or pageId.key.hogeAt(日付のstart, endオブジェクト)
 * の３層までしかないため、再帰を使わずに定義。
 * 再帰にしないため、pageId.valueの存在しない形式を弾く処理を入れずに済む
 * */
type Tags = {
  [key: string]: {
    [key: string]: string | DateAt;
  };
};

export const tags: Tags = {
  [PAGE_COUPON_CUSTOMER]: couponCustomerFilterLabels,
  [PAGE_COUPON_TYPE]: couponTypeFilterLabels,
  [PAGE_CUSTOMER_CONTRACTED_PLAN]: {},
  [PAGE_CUSTOMER]: customerFilterLabels,
  [PAGE_INSPECTION]: inspectionFilterLabels,
  [PAGE_INVENTORY]: inventoryFilterLabels,
  [PAGE_PLAN]: planFilterLabels,
  [PAGE_PLANNER]: planFilterLabels,
  [PAGE_RETURN]: shipmentFilterLabels,
  [PAGE_SHIPMENT]: shipmentFilterLabels,
  [PAGE_STAFF]: staffFilterLabels,
  [PAGE_STOCK]: stockFilterLabels,
  [PAGE_SUBSCRIPTION]: subscriptionFilterLabels,
  [PAGE_TOY_CATEGORY]: toyCategoryFilterLabels,
  [PAGE_INDIVIDUAL_TOY]: toyIndividualFilterLabels,
  [PAGE_TOY_MAKER]: toyMakerFilterLabels,
  [PAGE_TOY_SUPPLIER]: toySupplierFilterLabels,
  [PAGE_TOY]: toyFilterLabels,
  [PAGE_WAREHOUSE_TRANSFER]: warehouseTransferFilterLabels,
};
