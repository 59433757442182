/**
 * - `as const` で定義されたオブジェクトから、string型の文字列をキーとして、型エラーなくデータを取得する。
 * - 複雑な条件が付与された型を持つオブジェクトから特定のプロパティを取得する。
 */
export const propOrUndefined = <T>(
  obj: Record<string, T> | undefined,
  propName: string,
): T | undefined => {
  return obj?.[propName];
};
