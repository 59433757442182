import { COLUMNS_LABELS_AUDIT_LOGS } from "./auditLogs";
import {
  COLUMNS_LABELS_COMMENTS,
  COLUMNS_LABELS_COMMENT_IMAGES,
  COLUMNS_LABELS_COMMENT_STATUSES,
} from "./comments";
import { COLUMNS_LABELS_COUNTRIES } from "./countries";
import {
  COLUMNS_LABELS_COUPON_STATUSES,
  COLUMNS_LABELS_COUPONS,
} from "./coupons";
import {
  COLUMNS_LABELS_DELIVERY_METHOD_TIMES,
  COLUMNS_LABELS_DELIVERY_METHODS,
} from "./deliveryMethods";
import {
  COLUMNS_LABELS_LEGACY_DEPOSITS,
  COLUMNS_LABELS_DEPOSITS,
} from "./deposits";
import {
  COLUMNS_LABELS_FAMILY_CHILDREN,
  COLUMNS_LABELS_FAMILY_CHILD_PLANS,
  COLUMNS_LABELS_FAMILY_HOUSE_TYPES,
  COLUMNS_LABELS_FAMILIES,
} from "./families";
import {
  COLUMNS_LABELS_FAMILY_CUSTOMER_ADDRESSES,
  COLUMNS_LABELS_FAMILY_CUSTOMER_BILLING_ADDRESSES,
  COLUMNS_LABELS_FAMILY_CUSTOMER_CONTRACTED_PLANS,
  COLUMNS_LABELS_FAMILY_CUSTOMER_COUPONS,
  COLUMNS_LABELS_FAMILY_CUSTOMER_DETAIL_SURVEY_LABELS,
  COLUMNS_LABELS_FAMILY_CUSTOMER_DETAIL_SURVEYS,
  COLUMNS_LABELS_FAMILY_CUSTOMER_EXTERNAL_SERVICES,
  COLUMNS_LABELS_FAMILY_CUSTOMER_EXTERNAL_SERVICE_TYPES,
  COLUMNS_LABELS_FAMILY_CUSTOMER_FEATURE_RELEASE_TYPES,
  COLUMNS_LABELS_FAMILY_CUSTOMER_FEATURE_RELEASES,
  COLUMNS_LABELS_FAMILY_CUSTOMER_PRE_SURVEYS,
  COLUMNS_LABELS_FAMILY_CUSTOMER_PRE_SURVEY_LABELS,
  COLUMNS_LABELS_FAMILY_CUSTOMER_STATUSES,
  COLUMNS_LABELS_FAMILY_CUSTOMER_TOY_IMAGES,
  COLUMNS_LABELS_FAMILY_CUSTOMER_TOY_RELATIONS,
  COLUMNS_LABELS_FAMILY_CUSTOMER_TYPES,
  COLUMNS_LABELS_FAMILY_CUSTOMERS,
} from "./familyCustomers";
import { COLUMNS_LABELS_HOLIDAYS } from "./holidays";
import {
  COLUMNS_LABELS_INDIVIDUAL_TOY_STATUSES,
  COLUMNS_LABELS_INDIVIDUAL_TOY_STATUS_REASONS,
} from "./individualToys";
import {
  COLUMNS_LABELS_INVENTORIES,
  COLUMNS_LABELS_INVESTORY_ITEMS,
  COLUMNS_LABELS_INVENTORY_STAFFS,
  COLUMNS_LABELS_INVENTORY_WAREHOUSES,
} from "./inventories";
import {
  COLUMNS_LABELS_PAYMENT_CYCLES,
  COLUMNS_LABELS_PAYMENT_TYPES,
} from "./payments";
import {
  COLUMNS_LABELS_PLANNER_MESSAGES,
  COLUMNS_LABELS_PLAN_TEMPLATE_CATEGORIES,
  COLUMNS_LABELS_PLAN_TEMPLATE_CATEGORY_RELATIONS,
} from "./plans";
import { COLUMNS_LABELS_SHELVES } from "./shelves";
import {
  COLUMNS_LABELS_LEGACY_SHIPMENTS,
  COLUMNS_LABELS_PACKING_MATERIALS,
  COLUMNS_LABELS_SHIPMENT_EVALUATIONS,
  COLUMNS_LABELS_SHIPMENT_EXPIRATION_DATE_SETTINGS,
  COLUMNS_LABELS_SHIPMENT_STATUSES,
  COLUMNS_LABELS_SHIPMENT_TOY_CUSTOMER_RECEIVE_STATUSES,
  COLUMNS_LABELS_SHIPMENT_TOY_STATUS_REASONS,
  COLUMNS_LABELS_SHIPMENT_TOY_STATUSES,
  COLUMNS_LABELS_SHIPMENT_TOYS,
  COLUMNS_LABELS_SHIPMENT_TYPES,
  COLUMNS_LABELS_SHIPMENTS,
} from "./shipments";
import {
  COLUMNS_LABELS_STAFF_ACCESS_KEYS,
  COLUMNS_LABELS_STAFF_ALLOW_DOMAINS,
  COLUMNS_LABELS_STAFF_BACKLOG_KEYS,
  COLUMNS_LABELS_STAFFS,
} from "./staffs";
import { COLUMNS_LABELS_STOCK_TOYS, COLUMNS_LABELS_STOCKS } from "./stocks";
import {
  COLUMNS_LABELS_CHANGE_CYCLES,
  COLUMNS_LABELS_SUBSCRIPTION_STATUSES,
  COLUMNS_LABELS_SUBSCRIPTIONS,
} from "./subscriptions";
import {
  COLUMNS_LABELS_INCOMBINABLE_TOY_LISTS,
  COLUMNS_LABELS_INCOMBINABLE_TOY_REASONS,
  COLUMNS_LABELS_INCOMBINABLE_TOYS,
  COLUMNS_LABELS_REQUIRED_BATTERY_SIZES,
  COLUMNS_LABELS_REQUIRED_BATTERY_TYPES,
  COLUMNS_LABELS_SAFETY_BORDERS,
  COLUMNS_LABELS_TOY_ATTACHMENTS,
  COLUMNS_LABELS_TOY_CATEGORIES,
  COLUMNS_LABELS_TOY_CATEGORY_RELATIONS,
  COLUMNS_LABELS_TOY_CAUTIONS,
  COLUMNS_LABELS_TOY_CLEANING_IMAGES,
  COLUMNS_LABELS_TOY_CLEANING_DIFFICULTY_RATES,
  COLUMNS_LABELS_TOY_IMAGES,
  COLUMNS_LABELS_TOY_MAKERS,
  COLUMNS_LABELS_TOY_MATERIAL_RELATIONS,
  COLUMNS_LABELS_TOY_MATERIALS,
  COLUMNS_LABELS_TOY_PARTS,
  COLUMNS_LABELS_TOY_PARTS_IMAGES,
  COLUMNS_LABELS_TOY_PRODUCE_STATUSES,
  COLUMNS_LABELS_TOY_REQUIRED_BATTERIES,
  COLUMNS_LABELS_TOY_SAFETY_BORDER_RELATIONS,
  COLUMNS_LABELS_TOY_STATUSES,
  COLUMNS_LABELS_TOY_SUPPLIER_RELATIONS,
  COLUMNS_LABELS_TOY_SUPPLIERS,
  COLUMNS_LABELS_TOY_INSTRUCTION_ATTACHMENTS,
  COLUMNS_LABELS_TOY_SUPPLIER_STAFFS,
  COLUMNS_LABELS_TOYS,
  COLUMNS_LABELS_TOY_TOY_CAUTION_RELATIONS,
  COLUMNS_LABELS_UNAVAILABLE_TOY_EXAMPLE_IMAGES,
} from "./toys";
import {
  COLUMNS_LABELS_WAREHOUSE_TRANSFER_INDIVIDUAL_TOYS,
  COLUMNS_LABELS_WAREHOUSE_TRANSFERS,
  COLUMNS_LABELS_WAREHOUSE_TRANSFER_STAFFS,
  COLUMNS_LABELS_WAREHOUSE_TYPES,
  COLUMNS_LABELS_WAREHOUSES,
} from "./warehouses";
import {
  COLUMNS_LABELS_MAIL_TEMPLATES,
  COLUMNS_LABELS_SENT_MAILS,
} from "./mails";
import { COLUMNS_LABELS_PAGES } from "./pages";
import { type TABLE_LABELS } from "../table";

export const COLUMNS_LABELS_PER_TABLE: Record<
  TABLE_LABELS,
  Record<string, string>
> = {
  audit_logs: { ...COLUMNS_LABELS_AUDIT_LOGS },
  comment_images: { ...COLUMNS_LABELS_COMMENT_IMAGES },
  comments: { ...COLUMNS_LABELS_COMMENTS },
  comment_statuses: { ...COLUMNS_LABELS_COMMENT_STATUSES },
  change_cycles: { ...COLUMNS_LABELS_CHANGE_CYCLES },
  countries: { ...COLUMNS_LABELS_COUNTRIES },
  coupon_statuses: { ...COLUMNS_LABELS_COUPON_STATUSES },
  coupons: { ...COLUMNS_LABELS_COUPONS },
  delivery_method_times: { ...COLUMNS_LABELS_DELIVERY_METHOD_TIMES },
  delivery_methods: { ...COLUMNS_LABELS_DELIVERY_METHODS },
  deposits: { ...COLUMNS_LABELS_DEPOSITS },
  families: { ...COLUMNS_LABELS_FAMILIES },
  family_child_plans: { ...COLUMNS_LABELS_FAMILY_CHILD_PLANS },
  family_children: { ...COLUMNS_LABELS_FAMILY_CHILDREN },
  family_customer_addresses: { ...COLUMNS_LABELS_FAMILY_CUSTOMER_ADDRESSES },
  family_customer_billing_addresses: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_BILLING_ADDRESSES,
  },
  family_customer_contracted_plans: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_CONTRACTED_PLANS,
  },
  family_customer_coupons: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_COUPONS,
  },
  family_customer_detail_survey_labels: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_DETAIL_SURVEY_LABELS,
  },
  family_customer_detail_surveys: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_DETAIL_SURVEYS,
  },
  family_customer_external_service_types: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_EXTERNAL_SERVICE_TYPES,
  },
  family_customer_external_services: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_EXTERNAL_SERVICES,
  },
  family_customer_feature_release_types: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_FEATURE_RELEASE_TYPES,
  },
  family_customer_feature_releases: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_FEATURE_RELEASES,
  },
  family_customer_pre_survey_labels: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_PRE_SURVEY_LABELS,
  },
  family_customer_pre_surveys: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_PRE_SURVEYS,
  },
  family_customer_statuses: { ...COLUMNS_LABELS_FAMILY_CUSTOMER_STATUSES },
  family_customer_toy_images: { ...COLUMNS_LABELS_FAMILY_CUSTOMER_TOY_IMAGES },
  family_customer_toy_relations: {
    ...COLUMNS_LABELS_FAMILY_CUSTOMER_TOY_RELATIONS,
  },
  family_customer_types: { ...COLUMNS_LABELS_FAMILY_CUSTOMER_TYPES },
  family_customers: { ...COLUMNS_LABELS_FAMILY_CUSTOMERS },
  family_house_types: { ...COLUMNS_LABELS_FAMILY_HOUSE_TYPES },
  holidays: { ...COLUMNS_LABELS_HOLIDAYS },
  incombinable_toy_lists: { ...COLUMNS_LABELS_INCOMBINABLE_TOY_LISTS },
  incombinable_toy_reasons: { ...COLUMNS_LABELS_INCOMBINABLE_TOY_REASONS },
  incombinable_toys: { ...COLUMNS_LABELS_INCOMBINABLE_TOYS },
  individual_toy_statuses: { ...COLUMNS_LABELS_INDIVIDUAL_TOY_STATUSES },
  individual_toy_status_reasons: {
    ...COLUMNS_LABELS_INDIVIDUAL_TOY_STATUS_REASONS,
  },
  inventories: { ...COLUMNS_LABELS_INVENTORIES },
  inventory_items: { ...COLUMNS_LABELS_INVESTORY_ITEMS },
  inventory_staff: { ...COLUMNS_LABELS_INVENTORY_STAFFS },
  inventory_warehouses: { ...COLUMNS_LABELS_INVENTORY_WAREHOUSES },
  legacy_deposits: { ...COLUMNS_LABELS_LEGACY_DEPOSITS },
  legacy_shipments: { ...COLUMNS_LABELS_LEGACY_SHIPMENTS },
  mail_templates: { ...COLUMNS_LABELS_MAIL_TEMPLATES },
  packing_materials: { ...COLUMNS_LABELS_PACKING_MATERIALS },
  pages: { ...COLUMNS_LABELS_PAGES },
  payment_cycles: { ...COLUMNS_LABELS_PAYMENT_CYCLES },
  payment_types: { ...COLUMNS_LABELS_PAYMENT_TYPES },
  plan_template_categories: { ...COLUMNS_LABELS_PLAN_TEMPLATE_CATEGORIES },
  plan_template_category_relations: {
    ...COLUMNS_LABELS_PLAN_TEMPLATE_CATEGORY_RELATIONS,
  },
  planner_messages: { ...COLUMNS_LABELS_PLANNER_MESSAGES },
  required_battery_sizes: { ...COLUMNS_LABELS_REQUIRED_BATTERY_SIZES },
  required_battery_types: { ...COLUMNS_LABELS_REQUIRED_BATTERY_TYPES },
  safety_borders: { ...COLUMNS_LABELS_SAFETY_BORDERS },
  sent_mails: { ...COLUMNS_LABELS_SENT_MAILS },
  shelves: { ...COLUMNS_LABELS_SHELVES },
  shipment_evaluation: { ...COLUMNS_LABELS_SHIPMENT_EVALUATIONS },
  shipment_expiration_date_settings: {
    ...COLUMNS_LABELS_SHIPMENT_EXPIRATION_DATE_SETTINGS,
  },
  shipment_statuses: { ...COLUMNS_LABELS_SHIPMENT_STATUSES },
  shipment_toy_customer_receive_statuses: {
    ...COLUMNS_LABELS_SHIPMENT_TOY_CUSTOMER_RECEIVE_STATUSES,
  },
  shipment_toy_status_reasons: {
    ...COLUMNS_LABELS_SHIPMENT_TOY_STATUS_REASONS,
  },
  shipment_toy_statuses: { ...COLUMNS_LABELS_SHIPMENT_TOY_STATUSES },
  shipment_toys: { ...COLUMNS_LABELS_SHIPMENT_TOYS },
  shipment_types: { ...COLUMNS_LABELS_SHIPMENT_TYPES },
  shipments: { ...COLUMNS_LABELS_SHIPMENTS },
  staff_access_keys: { ...COLUMNS_LABELS_STAFF_ACCESS_KEYS },
  staff_allow_domains: { ...COLUMNS_LABELS_STAFF_ALLOW_DOMAINS },
  staff_backlog_keys: { ...COLUMNS_LABELS_STAFF_BACKLOG_KEYS },
  staff: { ...COLUMNS_LABELS_STAFFS },
  stock_toys: { ...COLUMNS_LABELS_STOCK_TOYS },
  subscriptions: { ...COLUMNS_LABELS_SUBSCRIPTIONS },
  subscription_statuses: { ...COLUMNS_LABELS_SUBSCRIPTION_STATUSES },
  stocks: { ...COLUMNS_LABELS_STOCKS },
  toy_attachments: { ...COLUMNS_LABELS_TOY_ATTACHMENTS },
  toy_category_relations: { ...COLUMNS_LABELS_TOY_CATEGORY_RELATIONS },
  toy_categories: { ...COLUMNS_LABELS_TOY_CATEGORIES },
  toy_cautions: { ...COLUMNS_LABELS_TOY_CAUTIONS },
  toy_cleaning_images: { ...COLUMNS_LABELS_TOY_CLEANING_IMAGES },
  toy_cleaning_difficulty_rate: {
    ...COLUMNS_LABELS_TOY_CLEANING_DIFFICULTY_RATES,
  },
  toy_images: { ...COLUMNS_LABELS_TOY_IMAGES },
  toy_instructions_attachments: {
    ...COLUMNS_LABELS_TOY_INSTRUCTION_ATTACHMENTS,
  },
  toys: { ...COLUMNS_LABELS_TOYS },
  toy_makers: { ...COLUMNS_LABELS_TOY_MAKERS },
  toy_material_relations: { ...COLUMNS_LABELS_TOY_MATERIAL_RELATIONS },
  toy_materials: { ...COLUMNS_LABELS_TOY_MATERIALS },
  toy_parts: { ...COLUMNS_LABELS_TOY_PARTS },
  toy_parts_images: { ...COLUMNS_LABELS_TOY_PARTS_IMAGES },
  toy_produce_statuses: { ...COLUMNS_LABELS_TOY_PRODUCE_STATUSES },
  toy_required_batteries: { ...COLUMNS_LABELS_TOY_REQUIRED_BATTERIES },
  toy_safety_border_relations: {
    ...COLUMNS_LABELS_TOY_SAFETY_BORDER_RELATIONS,
  },
  toy_statuses: { ...COLUMNS_LABELS_TOY_STATUSES },
  toy_supplier_relations: { ...COLUMNS_LABELS_TOY_SUPPLIER_RELATIONS },
  toy_suppliers: { ...COLUMNS_LABELS_TOY_SUPPLIERS },
  toy_supplier_staff: { ...COLUMNS_LABELS_TOY_SUPPLIER_STAFFS },
  toy_toy_caution_relations: { ...COLUMNS_LABELS_TOY_TOY_CAUTION_RELATIONS },
  unavailable_toy_example_images: {
    ...COLUMNS_LABELS_UNAVAILABLE_TOY_EXAMPLE_IMAGES,
  },
  warehouse_transfer_individual_toys: {
    ...COLUMNS_LABELS_WAREHOUSE_TRANSFER_INDIVIDUAL_TOYS,
  },
  warehouse_transfers: { ...COLUMNS_LABELS_WAREHOUSE_TRANSFERS },
  warehouse_types: { ...COLUMNS_LABELS_WAREHOUSE_TYPES },
  warehouses: { ...COLUMNS_LABELS_WAREHOUSES },
  warehouse_transfer_staff: { ...COLUMNS_LABELS_WAREHOUSE_TRANSFER_STAFFS },
};
