import axios, { type AxiosInstance } from "axios";
import { type ApiClientWrapperArgs, type ApiClientReturn } from "./types";
import { getPath } from "../get-url";
import { type Methods, type ApiPaths, type ApiResponse } from "@/api/types";
import { propOrUndefined } from "@/helper";

/**
 * api client を型安全にする。
 * @FIXME
 * madras api を前提としない wrapper と 前提とする wrapper に分ける。
 * そうしないと、madras 以外の api を使用する可能性を考慮した useMadrasAPI
 * という命名との整合性が取れないため。
 */
export const apiClientWrapper = (client: AxiosInstance = axios) =>
  async function apiClient<
    Path extends ApiPaths,
    Method extends Methods<Path>,
    ForcePagerType extends boolean = false,
  >({
    path,
    method,
    contentType,
    params = {},
    body,
    axiosRequestConfig,
  }: ApiClientWrapperArgs<Path, Method, ForcePagerType>): Promise<
    ApiClientReturn<Path, Method, ForcePagerType>
  > {
    const url = getPath(
      path as any,
      propOrUndefined(params, "path") as any,
      /* eslint-enable @typescript-eslint/no-explicit-any */
    );
    // params 引数の型チェックにより、発生しないはずのエラーだが、any を使えば入るロジック
    if (url === null) {
      // TODO: https://github.com/torana-us/madras/issues/3039 エラークラスを利用する。
      throw new Error(
        `[src/api/helpers/api-client-wrapper/apiClientWrapper.ts] 引数に誤りがあります: ${JSON.stringify(
          { path, params },
        )}`,
      );
    }
    return client.request<ApiResponse<Path, Method>>({
      url,
      method,
      data: body, // Automatic request body serialization を利用 (cf. https://github.com/axios/axios/blob/v0.27.2/lib/defaults/index.js#L80)
      params: params?.query,
      headers: {
        ...(typeof contentType === "string"
          ? { "Content-Type": contentType }
          : // application/json の付与 は axios で対応. cf. https://github.com/axios/axios/blob/v0.27.2/lib/defaults/index.js#L82
            {}),
      },
      ...axiosRequestConfig,
    }) as any;
  };
