import * as React from "react";

interface RefObject<T> {
  current: T | null;
}

const setRef = <T>(
  ref: RefObject<T> | ((instance: T | null) => void) | null | undefined,
  value: T | null,
) => {
  if (typeof ref === "function") {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

/**
 * @see
 * https://bit.cloud/mui-org/material-ui/internal/use-fork-ref/~code?version=4.9.10
 */
export function useForkRef<T>(
  refA: React.Ref<T>,
  refB: React.Ref<T> | undefined,
): React.Ref<T> {
  return React.useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }
    return (refValue) => {
      setRef(refA, refValue);
      if (refB !== undefined) {
        setRef(refB, refValue);
      }
    };
  }, [refA, refB]);
}
