// ja.ts になかったです
export const COLUMNS_LABELS_AUDIT_LOGS = {
  id: "ID",
  uuid: "UUID",
  staff_id: "スタッフID",
  target_id: "対象ID",
  main_table_name: "メインテーブル名",
  type: "種類",
  previous_data: "前データ",
  next_data: "次データ",
  diff: "差分",
  attributes: "属性",
  ip_address: "IPアドレス",
  user_agent: "ユーザーエージェント",
};
