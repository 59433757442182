import { useSnackbar as useSnackbarOriginal } from "notistack";
import { useCallback, useMemo } from "react";
import { type EnqueueSnackbarParams } from "./types";
import { customEnqueueSnackbarOption } from "./enqueue-snackbar";

/**
 * 追加設定を付与するためのラッパー
 */
export function useSnackbar() {
  const { enqueueSnackbar: enqueueSnackbarOriginalUse, closeSnackbar } =
    useSnackbarOriginal();

  const customEnqueueSnackbar = useCallback(
    (...params: EnqueueSnackbarParams) => {
      const [msg, options] = customEnqueueSnackbarOption(params);
      return enqueueSnackbarOriginalUse(msg, options);
    },
    [enqueueSnackbarOriginalUse],
  );

  const customContextValue = useMemo(() => {
    return {
      closeSnackbar,
      enqueueSnackbar: customEnqueueSnackbar,
    };
  }, [closeSnackbar, customEnqueueSnackbar]);

  return customContextValue;
}
