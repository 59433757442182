import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getInitialState, type RehydratePager } from "@/types/Pager";
import { type FamilyCustomerBillingAddress } from "@/openapi/api";

type PayloadType = RehydratePager<FamilyCustomerBillingAddress>;
const initialState = getInitialState<FamilyCustomerBillingAddress>();

const FamiliesCustomersBillingsAddressesSlice = createSlice({
  name: "customersContractedPlans",
  initialState,
  reducers: {
    rehydrateFamiliesCustomersBillingsAddresses: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateFamiliesCustomersBillingsAddresses } =
  FamiliesCustomersBillingsAddressesSlice.actions;

export default FamiliesCustomersBillingsAddressesSlice.reducer;
