import snakecaseKeys from "snakecase-keys";
import { PAGE_COUPON_TYPE } from "@/constants/config/pages";
import { commonColumnsLabels } from "@/configs/labels/columns/constants";
import { Rule } from "@/rules/types/ValidationRule";

export const rules = {
  name: { required: true, maxLength: Rule.string },
  price: { required: true, pattern: Rule.number },
  note: { maxLength: Rule.text },
} as const;

export const labels = {
  name: "クーポン名称",
  price: "発行金額",
  note: "備考",
} as const;

export const columnsLabelsCoupons = {
  ...commonColumnsLabels,
  ...snakecaseKeys(labels, { deep: true }),
};

export const configs = {
  rules,
  labels,
  pageId: PAGE_COUPON_TYPE,
};

export const couponTypeListHeader = ["クーポン名称", "発行金額", "備考"];

export const couponCustomerListHeader = [
  "顧客名",
  "クーポン種別",
  "ステータス",
  "利用金額",
  "利用詳細",
  "利用日",
  "発行日",
  "有効期限",
  "備考",
];
