import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ShipmentExpirationDateSetting } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<ShipmentExpirationDateSetting>;
const initialState = getInitialState<ShipmentExpirationDateSetting>();

const ExpirationsDatesSlice = createSlice({
  name: "expirationsDates",
  initialState,
  reducers: {
    rehydrateExpirationsDates: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateExpirationsDates } = ExpirationsDatesSlice.actions;
export default ExpirationsDatesSlice.reducer;
