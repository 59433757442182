import { useMemo, type FC } from "react";
import { Alert, Container, Stack } from "@mui/material";
import CopyToClipboard from "react-copy-to-clipboard";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import { testIdDisplayError } from "./__tests__/testId";
import { Button, Typography } from "@/components/essentials";
import { useSnackbar } from "@/lib/notistack";
import { FormatDateType, formatDate } from "@/helper/date";

export const DisplayError: FC<{
  title: string;
  message: string;
  severity: "error" | "warning" | "info";
  clipboardText?: string;
  sessionId: string | undefined;
}> = ({ title, message, severity, clipboardText, sessionId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const now = useMemo(() => formatDate(undefined, FormatDateType.DATETIME), []);
  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Alert severity={severity} icon={false}>
        <Stack gap="1rem" width="100%">
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <Typography
              width={5 / 6}
              variant="h4"
              textAlign="center"
              ddAllowPrivacy
              dataTestId={testIdDisplayError.title}
              whiteSpace="pre-wrap"
            >
              {title}
            </Typography>
          </Stack>

          <Typography
            variant="body1"
            ddAllowPrivacy
            textAlign="start"
            px="1rem"
            whiteSpace="pre-wrap"
            minHeight="8rem"
            maxHeight="30vh"
            mt="1rem"
            width="90%"
            sx={{
              overflowY: "auto",
            }}
            dataTestId={testIdDisplayError.message}
          >
            {message}
          </Typography>

          <Typography variant="caption" ddAllowPrivacy>
            ※お手数ですが、画面をリロードしても解決しない場合はご連絡をお願いします
            {clipboardText && (
              <>
                <br />
                ※お問い合わせの際は、「エラー情報をクリップボードにコピー」をクリックし、エラー情報を添付してください
              </>
            )}
          </Typography>

          {clipboardText && (
            <CopyToClipboard
              text={`発生時刻: ${now} ${clipboardText}`}
              onCopy={() =>
                enqueueSnackbar("クリップボードにコピーしました", {
                  variant: "info",
                })
              }
            >
              <Button
                color="error"
                variant="contained"
                ddAllowPrivacy
                startIcon={<CopyAllIcon />}
              >
                エラー情報をクリップボードにコピー
              </Button>
            </CopyToClipboard>
          )}
          <Typography
            variant="caption"
            ddAllowPrivacy
            textAlign="start"
            dataTestId={testIdDisplayError.date}
          >
            発生時刻: {now}
          </Typography>
          {sessionId !== "" && (
            <Typography
              variant="caption"
              ddAllowPrivacy
              textAlign="start"
              dataTestId={testIdDisplayError.sessionId}
            >
              セッションID: {sessionId}
            </Typography>
          )}
        </Stack>
      </Alert>
    </Container>
  );
};
