import Isemail from "isemail";
import {
  type FamilyCustomerAddress,
  type FamilyCustomerBillingAddress,
} from "@/openapi/api";

export const zipCode = (
  code: string | undefined,
  includePostMark = true,
): string => {
  return `${includePostMark ? "〒" : ""}${code?.replace(
    /^(\d{3})(\d{4})$/,
    "$1-$2",
  )}`;
};

export const extractAddressString = ({
  pref,
  address1,
  address2,
}: FamilyCustomerAddress | FamilyCustomerBillingAddress): string =>
  `${pref ?? ""}${address1 ?? ""}${address2 ?? ""}`;

export const getPrimaryAddress = <
  T extends {
    // openapiが吐き出す型がoptionalなのでそれに合わせている
    isPrimary?: boolean;
  },
>(
  addresses: T[] | undefined,
): T | undefined => {
  if (addresses === undefined) return undefined;
  const primaryAddress = addresses.find((address) => address.isPrimary);
  return primaryAddress ?? addresses[0];
};

export const badZipCodeMessage =
  "郵便番号７桁を半角数字のみで入力してください。";
export const zipCodeRegex = /^\d{7}$/;
export const isZipCode = (str: string) => zipCodeRegex.test(str);
export const zipCodeRule = {
  value: zipCodeRegex,
  message: badZipCodeMessage,
};

export const addressLabel = <
  T extends {
    zipCode?: string;
    pref?: string;
    address1?: string;
    address2?: string;
    buildingName?: string;
  },
>(
  billingAddress: T | undefined,
) =>
  `${zipCode(billingAddress?.zipCode ?? "")} ${billingAddress?.pref ?? ""} ${
    billingAddress?.address1 ?? ""
  } ${billingAddress?.address2 ?? ""} ${billingAddress?.buildingName ?? ""}`;

export const isEmail = (str: string) => Isemail.validate(str);
export const validateEmail = (str: string) =>
  isEmail(str) || "有効なメールアドレスではありません。";
