import {
  Input as MuiInput,
  type InputProps as MuiInputProps,
} from "@mui/material";
import { type FC } from "react";
import { type DatadogAttributes } from "@/types/datadog";
import { addDDAllowPrivacy } from "@/helper/datadog";

export type InputProps = MuiInputProps & DatadogAttributes;
export const Input: FC<InputProps> = (props) => {
  const { ddAllowPrivacy, ...rest } = props;
  return (
    <MuiInput fullWidth {...addDDAllowPrivacy(ddAllowPrivacy)} {...rest} />
  );
};
