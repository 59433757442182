export const plannerFilterLabels = {
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
  plannerMessageCategory: "プランナーメッセージカテゴリー",
  plannerMessageCategory_Name: "プランナーメッセージカテゴリー名",
  plannerMessageCategory_Uuid: "プランナーメッセージカテゴリーUUID",
  name: "テンプレート名",
  message: "テンプレート本文",
  uuid: "UUID",
};
