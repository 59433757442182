export const toySupplierFilterLabels = {
  uuid: "UUID",
  comment: "コメント",
  conditionOfFee: "料金条件",
  conditionOfPurchase: "購入条件",
  name: "仕入れ先企業名",
  noteOfHowToReturn: "返却方法",
  orderDirectTel: "仕入先直通電話番号",
  orderTel: "仕入先代表電話番号",
  orderMail: "仕入先メールアドレス",
  orderFirstName: "仕入先担当者の名前",
  orderFirstNameOfPronunciation: "仕入先担当者の名前（カナ)",
  orderLastName: "仕入先担当者の苗字",
  orderLastNameOfPronunciation: "仕入先担当者の苗字（カナ)",
  purchaseNote: "仕入れ時メモ",
  returnAddress: "返品先住所",
  returnAddressZipCode: "返品先郵便番号",
  paymentCycle: "支払いサイクル",
  handlingBrands: "ブランドの取り扱い",
  mailList: "メーリングリスト",
  paymentTerms: "支払い条件 ",
  shipmentTerms: "出荷条件",
  shippingFeeTerms: "配送料条件",
  transactionTerms: "取引条件",
  paymentCycle_Name: "決済サイクル名",
  paymentCycle_Uuid: "決済サイクルのUUID",
  toySupplierStaff_Department: "仕入れ先企業の担当者の部署",
  toySupplierStaff_DirectTel: "仕入れ先企業の担当者の直通電話番号",
  toySupplierStaff_FirstName: "仕入れ先企業の担当者の名前",
  toySupplierStaff_FirstNameOfPronunciation:
    "仕入れ先企業の担当者の名前 (カナ)",
  toySupplierStaff_LastName: "仕入れ先企業の担当者の苗字",
  toySupplierStaff_LastNameOfPronunciation: "仕入れ先企業の担当者の苗字 (カナ)",
  toySupplierStaff_Mail: "仕入れ先企業の担当者のメールアドレス",
  toySupplierStaff_Tel: "仕入れ先企業の担当者の電話番号",
  toySupplierStaff_Uuid: "仕入れ先企業の担当者のUUID",
  url: "URL",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
};
