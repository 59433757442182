export const couponTypeFilterLabels = {
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  name: "クーポン名称",
  note: "備考",
  price: "発行金額",
  updatedAt: { start: "更新日時 (開始)", end: "更新日時 (終了)" },
  uuid: "UUID",
};
