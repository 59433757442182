import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type PackingMaterial } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<PackingMaterial>;
const initialState = getInitialState<PackingMaterial>();

const PackingMaterialsSlice = createSlice({
  name: "packingMaterials",
  initialState,
  reducers: {
    rehydratedPackingMaterials: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydratedPackingMaterials } = PackingMaterialsSlice.actions;
export default PackingMaterialsSlice.reducer;
