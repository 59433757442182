export const warehouseTransferFilterLabels = {
  uuid: "UUID",
  afterWarehouse_Address1: "入庫先 住所 1",
  afterWarehouse_Address2: "入庫先 住所 2",
  afterWarehouse_Name: "入庫先 事業所名",
  afterWarehouse_Pref: "入庫先 都道府県",
  afterWarehouse_Tel: "入庫先 電話番号",
  afterWarehouse_Uuid: "入庫先 UUID",
  afterWarehouse_ZipCode: "入庫先 郵便番号",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  leftAt: {
    start: "出庫日時 (開始)",
    end: "出庫日時 (終了)",
  },
  name: "事業所間移動ラベル名",
  previousWarehouse_Address1: "出庫元 住所 1",
  previousWarehouse_Address2: "出庫元 住所 2",
  previousWarehouse_Name: "出庫元 事業所名",
  previousWarehouse_Pref: "出庫元 都道府県",
  previousWarehouse_Tel: "出庫元 電話番号",
  previousWarehouse_Uuid: "出庫元 UUID",
  previousWarehouse_ZipCode: "出庫元 郵便番号",
  scheduledStockAt: {
    start: "入庫予定日時 (開始)",
    end: "入庫予定日時 (終了)",
  },
  stockedAt: {
    start: "入庫日時 (開始)",
    end: "入庫日時 (終了)",
  },
  warehouseTransferStaff_Uuid: "実施スタッフ UUID",
  warehouseTransferToys_Uuid: "移動対象のおもちゃ UUID",
};
