import {
  type AutocompleteProps as MuiAutocompleteProps,
  Autocomplete as MuiAutocomplete,
} from "@mui/material";
import { TextField, type TextFieldProps } from "../textfield";
import { type DatadogAttributes } from "@/types/datadog";
import { addDDAllowPrivacy } from "@/helper/datadog";

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> extends Omit<
      MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
      "renderInput"
    >,
    DatadogAttributes {
  textFieldProps?: TextFieldProps;
  label?: TextFieldProps["label"];
  dataTestId?: TextFieldProps["dataTestId"];
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  textFieldProps,
  label,
  dataTestId,
  ddAllowPrivacy,
  ...props
}: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) {
  return (
    <MuiAutocomplete<T, Multiple, DisableClearable, FreeSolo>
      loadingText="読込中..."
      renderInput={({ inputProps, ...params }) => (
        <TextField
          {...params}
          {...textFieldProps}
          ddAllowPrivacy={ddAllowPrivacy}
          inputProps={{
            ...inputProps,
            ...(textFieldProps?.inputProps ?? {}),
          }}
          dataTestId={dataTestId}
          label={label}
        />
      )}
      {...addDDAllowPrivacy(ddAllowPrivacy)}
      {...props}
    />
  );
}
