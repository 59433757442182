import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

type PayloadType<T extends { pageId: string; uuid: string }> = T;

type InitialStateParameter<T> = {
  [key: string]: {
    [uuid: string]: T;
  };
};

const initialState: InitialStateParameter<any> = {};

const DetailsSlice = createSlice({
  name: "details",
  initialState,
  reducers: {
    rehydrateDetails: (state, { payload }: PayloadAction<PayloadType<any>>) => {
      state[payload.pageId] = {
        ...(state[payload.pageId] ?? {}),
        [payload.uuid]: { ...payload.response },
      };
      return state;
    },
  },
});

export const { rehydrateDetails } = DetailsSlice.actions;
export default DetailsSlice.reducer;
