export const toyMakerFilterLabels = {
  uuid: "UUID",
  comment: "コメント",
  name: "メーカー名",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
  url: "URL",
  nameOfPronunciation: "メーカー名（カナ）",
};
