export const stockFilterLabels = {
  uuid: "UUID",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
  purchasedAt: {
    start: "仕入日時 (開始)",
    end: "仕入日時 (終了)",
  },
  toySupplier: "仕入れ先企業",
  orderNumber: "注文番号",
  exchangeCurrency: "通貨両替",
  warehouse: "事業所・倉庫",
  discountPrice: "割引価格",
  stockToys_Uuid: "仕入れのUUID",
  toySupplier_Comment: "仕入れ先企業のコメント",
  toySupplier_ConditionOfFee: "仕入れ先企業の料金条件",
  toySupplier_ConditionOfPurchase: "仕入れ先企業の購入条件",
  toySupplier_Name: "仕入れ先企業名",
  toySupplier_NoteOfHowToReturn: "仕入れ先企業への返却方法",
  toySupplier_OrderDirectTel: "仕入れ先企業の発注直通電話番号",
  toySupplier_OrderFirstName: "仕入れ先企業の発注担当者名（名前)",
  toySupplier_OrderFirstNameOfPronunciation:
    "仕入れ先企業の発注担当者名（名前・カナ)",
  toySupplier_OrderLastName: "仕入れ先企業の発注担当者名（苗字)",
  toySupplier_OrderLastNameOfPronunciation:
    "仕入れ先企業の発注担当者名（苗字・カナ)",
  toySupplier_OrderMail: "仕入れ先企業の発注メールアドレス",
  toySupplier_OrderTel: "仕入れ先企業の発注代表電話番号",
  toySupplier_PurchaseNote: "仕入れ先企業の購入時メモ",
  toySupplier_ReturnAddress: "仕入れ先企業の返品先住所",
  toySupplier_ReturnAddressZipCode: "仕入れ先企業の返品先郵便番号",
  toySupplier_Url: "仕入れ先企業のURL",
  toySupplier_Uuid: "仕入れ先企業のUUID",
  toySupplier_PaymentCycle_Name: "仕入れ先企業の決済サイクル名",
  toySupplier_PaymentCycle_Uuid: "仕入れ先企業の決済サイクルのUUID",
  toySupplier_ToySupplierStaff_Department: "仕入れ先企業の担当者の部署",
  toySupplier_ToySupplierStaff_DirectTel: "仕入れ先企業の担当者の直通電話番号",
  toySupplier_ToySupplierStaff_FirstName: "仕入れ先企業の担当者の名前",
  toySupplier_ToySupplierStaff_FirstNameOfPronunciation:
    "仕入れ先企業の担当者の名前 (カナ)",
  toySupplier_ToySupplierStaff_LastName: "仕入れ先企業の担当者の苗字",
  toySupplier_ToySupplierStaff_LastNameOfPronunciation:
    "仕入れ先企業の担当者の苗字 (カナ)",
  toySupplier_ToySupplierStaff_Mail: "仕入れ先企業の担当者のメールアドレス",
  toySupplier_ToySupplierStaff_Tel: "仕入れ先企業の担当者の電話番号",
  toySupplier_ToySupplierStaff_Uuid: "仕入れ先企業の担当者のUUID",
  warehouse_Address1: "倉庫住所 1",
  warehouse_Address2: "倉庫住所 2",
  warehouse_Name: "倉庫名",
  warehouse_Pref: "倉庫の都道府県",
  warehouse_Tel: "倉庫の電話番号",
  warehouse_Uuid: "倉庫のUUID",
  warehouse_Shelves: "倉庫棚",
  warehouse_WarehouseType: "倉庫の種別",
  warehouse_ZipCode: "倉庫の郵便番号",
  warehouse_Country: "倉庫の所在国",
  warehouse_Country_Uuid: "倉庫の所在国のUUID",
  warehouse_Shelves_Uuid: "倉庫棚のUUID",
  warehouse_WarehouseType_Uuid: "倉庫の種別のUUID",
  stockToys_Stock_ExchangeCurrency: "仕入れの通貨",
  stockToys_Stock_OrderNumber: "仕入れの注文番号",
  stockToys_Stock_Uuid: "仕入れのUUID",
  stockToys_Toy_Accessories: "おもちゃの付属品",
  stockToys_Toy_Description: "おもちゃの概要",
  stockToys_Toy_Instruction: "おもちゃの使用方法",
  stockToys_Toy_MakerToyUrl: "おもちゃの商品URL",
  stockToys_Toy_Name: "おもちゃの名前",
  stockToys_Toy_NoteOfPurchaseForCustomer: "おもちゃのお客様に対する購入メモ",
  stockToys_Toy_Uuid: "おもちゃのUUID",
  totalArrivedAmount: "入庫済み個数",
  totalPaidAmount: "発注個数",
  stockToys_Stock_StockToys_Uuid: "仕入れおもちゃのUUID",
  stockToys_Stock_ToySupplier_Comment: "仕入れ先企業のコメント",
  stockToys_Stock_ToySupplier_ConditionOfFee: "仕入れ先企業の料金条件",
  stockToys_Stock_ToySupplier_ConditionOfPurchase: "仕入れ先企業の購入条件",
  stockToys_Stock_ToySupplier_Name: "仕入れ先企業名",
  stockToys_Stock_ToySupplier_NoteOfHowToReturn: "仕入れ先企業への返却方法",
  stockToys_Stock_ToySupplier_OrderDirectTel: "仕入れ先企業の発注直通電話番号",
  stockToys_Stock_ToySupplier_OrderFirstName:
    "仕入れ先企業の発注担当者名（名前)",
  stockToys_Stock_ToySupplier_OrderFirstNameOfPronunciation:
    "仕入れ先企業の発注担当者名（名前・カナ)",
  stockToys_Stock_ToySupplier_OrderLastName:
    "仕入れ先企業の発注担当者名（苗字)",
  stockToys_Stock_ToySupplier_OrderLastNameOfPronunciation:
    "仕入れ先企業の発注担当者名（苗字・カナ)",
  stockToys_Stock_ToySupplier_OrderMail: "仕入れ先企業の発注メールアドレス",
  stockToys_Stock_ToySupplier_OrderTel: "仕入れ先企業の発注電話番号",
  stockToys_Stock_ToySupplier_PurchaseNote: "仕入れ先企業の購入時メモ",
  stockToys_Stock_ToySupplier_ReturnAddress: "仕入れ先企業の返品先住所",
  stockToys_Stock_ToySupplier_ReturnAddressZipCode:
    "仕入れ先企業の返品先郵便番号",
  stockToys_Stock_ToySupplier_Url: "仕入れ先企業のURL",
  stockToys_Stock_ToySupplier_Uuid: "仕入れ先企業のUUID",
  stockToys_Stock_Warehouse_Address1: "倉庫住所 1",
  stockToys_Stock_Warehouse_Address2: "倉庫住所 2",
  stockToys_Stock_Warehouse_Name: "倉庫名",
  stockToys_Stock_Warehouse_Pref: "倉庫の都道府県",
  stockToys_Stock_Warehouse_Tel: "倉庫の電話番号",
  stockToys_Stock_Warehouse_Uuid: "倉庫のUUID",
  stockToys_Stock_Warehouse_ZipCode: "倉庫の郵便番号",
  stockToys_Toy_Comment_Comment: "おもちゃのコメント",
  stockToys_Toy_Comment_CommentForCustomer: "顧客へのコメント",
  stockToys_Toy_Comment_CommentOfCleaning: "おもちゃの清掃方法",
  stockToys_Toy_Comment_CommentOfPlan: "プラン留意事項",
  stockToys_Toy_Comment_CommentOfPurchase: "買取り留意事項",
  stockToys_Toy_Comment_CommentOfShipping: "発送時の確認事項",
  stockToys_Toy_Comment_CommentOfReturning: "返却時の確認事項",
  stockToys_Toy_Comment_CommentOfStock: "仕入れ留意事項",
  stockToys_Toy_Comment_PreparationOfShipping: "商品化作業内容",
  stockToys_ToyId: "おもちゃのID",
  stockToys_Toy_InventoryItems_Uuid: "棚卸し商品のUUID",
  stockToys_Toy_PackingMaterial_Name: "梱包資材",
  stockToys_Toy_PackingMaterial_Uuid: "梱包資材のUUID",
  stockToys_Toy_PlanSheet_CommentOfPlanSheet: "プランシートコメント",
  stockToys_Toy_PlanSheet_NameOfPlanSheet: "プランシート名",
  stockToys_Toy_PlanSheet_NoteOfPlanSheet: "プランシート留意事項",
  stockToys_Toy_PlanSheet_ToyMakerNameOfPlanSheet:
    "おもちゃメーカーのプランシート名",
  stockToys_Toy_RequiredBatteryType_Name: "電池の種類",
  stockToys_Toy_RequiredBatteryType_Uuid: "電池の種類のUUID",
  stockToys_Toy_SafetyBorders_Name: "安全基準名",
  stockToys_Toy_SafetyBorders_Uuid: "安全基準のUUID",
  stockToys_Toy_ToyAttachments_MimeType: "おもちゃのアタッチメントのMIMEタイプ",
  stockToys_Toy_ToyAttachments_Name: "おもちゃのアタッチメント名",
  stockToys_Toy_ToyAttachments_OriginalName: "おもちゃのアタッチメント元の名",
  stockToys_Toy_ToyAttachments_Path: "おもちゃのアタッチメントのパス",
  stockToys_Toy_ToyAttachments_Uuid: "おもちゃのアタッチメントのUUID",
  stockToys_Toy_ToyCategories_Name: "おもちゃのカテゴリー名",
  stockToys_Toy_ToyCategories_Uuid: "おもちゃのカテゴリーのUUID",
  stockToys_Toy_ToyCautions_Name: "おもちゃの注意事項",
  stockToys_Toy_ToyCautions_Uuid: "おもちゃの注意事項のUUID",
  stockToys_Toy_ToyCleaningImages_MimeType: "清掃・梱包方法画像のMIMEタイプ",
  stockToys_Toy_ToyCleaningImages_Name: "清掃・梱包方法画像名",
  stockToys_Toy_ToyCleaningImages_OriginalName: "清掃・梱包方法画像の元の名前",
  stockToys_Toy_ToyCleaningImages_Path: "清掃・梱包方法画像のパス",
  stockToys_Toy_ToyCleaningImages_Uuid: "清掃・梱包方法画像のUUID",
  stockToys_Toy_ToyImages_MimeType: "おもちゃの画像のMIMEタイプ",
  stockToys_Toy_ToyImages_Name: "おもちゃの画像名",
  stockToys_Toy_ToyImages_OriginalName: "おもちゃの画像の元の名前",
  stockToys_Toy_ToyImages_Path: "おもちゃの画像のパス",
  stockToys_Toy_ToyImages_Uuid: "おもちゃの画像のUUID",
  stockToys_Toy_ToyInstructionAttachments_MimeType:
    "おもちゃの説明画像のMIMEタイプ",
  stockToys_Toy_ToyInstructionAttachments_Name: "おもちゃの説明画像名",
  stockToys_Toy_ToyInstructionAttachments_OriginalName:
    "おもちゃの説明画像の元の名前",
  stockToys_Toy_ToyInstructionAttachments_Path: "おもちゃの説明画像のパス",
  stockToys_Toy_ToyInstructionAttachments_Uuid: "おもちゃの説明画像のUUID",
  stockToys_Toy_ToyMaker_Comment: "おもちゃメーカーのコメント",
  stockToys_Toy_ToyMaker_Name: "おもちゃのメーカー名",
  stockToys_Toy_ToyMaker_Url: "おもちゃメーカーのURL",
  stockToys_Toy_ToyMaker_Uuid: "おもちゃメーカーのUUID",
  stockToys_Toy_ToyMaterials_Name: "おもちゃの材質",
  stockToys_Toy_ToyMaterials_Uuid: "おもちゃの材質のUUID",
  stockToys_Toy_ToyProduceStatus_Name: "おもちゃの生産状況",
  stockToys_Toy_ToyProduceStatus_Uuid: "おもちゃの生産状況のUUID",
  stockToys_Toy_ToyStatus_Name: "おもちゃの取り扱いステータス",
  stockToys_Toy_ToyStatus_Uuid: "おもちゃの取り扱いステータスのUUID",
  stockToys_Toy_ToySuppliers_Comment: "仕入れ先企業のコメント",
  stockToys_Toy_ToySuppliers_ConditionOfFee: "仕入れ先企業の料金条件",
  stockToys_Toy_ToySuppliers_ConditionOfPurchase: "仕入れ先企業の購入条件",
  stockToys_Toy_ToySuppliers_Name: "仕入れ先企業名",
  stockToys_Toy_ToySuppliers_NoteOfHowToReturn: "仕入れ先企業への返却方法",
  stockToys_Toy_ToySuppliers_OrderDirectTel: "仕入れ先企業の発注直通電話番号",
  stockToys_Toy_ToySuppliers_OrderFirstName:
    "仕入れ先企業の発注担当者名（名前)",
  stockToys_Toy_ToySuppliers_OrderFirstNameOfPronunciation:
    "仕入れ先企業の発注担当者名（名前・カナ)",
  stockToys_Toy_ToySuppliers_OrderLastName: "仕入れ先企業の発注担当者名（苗字)",
  stockToys_Toy_ToySuppliers_OrderLastNameOfPronunciation:
    "仕入れ先企業の発注担当者名（苗字・カナ)",
  stockToys_Toy_ToySuppliers_OrderMail: "仕入れ先企業の発注メールアドレス",
  stockToys_Toy_ToySuppliers_OrderTel: "仕入れ先企業の発注代表電話番号",
  stockToys_Toy_ToySuppliers_PurchaseNote: "仕入れ先企業の購入時メモ",
  stockToys_Toy_ToySuppliers_ReturnAddress: "仕入れ先企業の返品先住所",
  stockToys_Toy_ToySuppliers_ReturnAddressZipCode:
    "仕入れ先企業の返品先郵便番号",
  stockToys_Toy_ToySuppliers_Url: "仕入れ先企業のURL",
  stockToys_Toy_ToySuppliers_Uuid: "仕入れ先企業のUUID",
  toySupplier_ToySupplierStaff_ToySupplier_Comment: "仕入れ先企業のコメント",
  toySupplier_ToySupplierStaff_ToySupplier_ConditionOfFee:
    "仕入れ先企業の料金条件",
  toySupplier_ToySupplierStaff_ToySupplier_ConditionOfPurchase:
    "仕入れ先企業の購入条件",
  toySupplier_ToySupplierStaff_ToySupplier_Name: "仕入れ先企業名",
  toySupplier_ToySupplierStaff_ToySupplier_NoteOfHowToReturn:
    "仕入れ先企業への返却方法",
  toySupplier_ToySupplierStaff_ToySupplier_OrderDirectTel:
    "仕入れ先企業の発注直通電話番号",
  toySupplier_ToySupplierStaff_ToySupplier_OrderFirstName:
    "仕入れ先企業の発注担当者名（名前)",
  toySupplier_ToySupplierStaff_ToySupplier_OrderFirstNameOfPronunciation:
    "仕入れ先企業の発注担当者名（名前・カナ)",
  toySupplier_ToySupplierStaff_ToySupplier_OrderLastName:
    "仕入れ先企業の発注担当者名（苗字)",
  toySupplier_ToySupplierStaff_ToySupplier_OrderLastNameOfPronunciation:
    "仕入れ先企業の発注担当者名（苗字・カナ)",
  toySupplier_ToySupplierStaff_ToySupplier_OrderMail:
    "仕入れ先企業の発注メールアドレス",
  toySupplier_ToySupplierStaff_ToySupplier_OrderTel:
    "仕入れ先企業の発注電話番号",
  toySupplier_ToySupplierStaff_ToySupplier_PurchaseNote:
    "仕入れ先企業の購入時メモ",
  toySupplier_ToySupplierStaff_ToySupplier_ReturnAddress:
    "仕入れ先企業の返品先住所",
  toySupplier_ToySupplierStaff_ToySupplier_ReturnAddressZipCode:
    "仕入れ先企業の返品先郵便番号",
  toySupplier_ToySupplierStaff_ToySupplier_Url: "仕入れ先企業のURL",
  toySupplier_ToySupplierStaff_ToySupplier_Uuid: "仕入れ先企業のUUID",
  warehouse_Shelves_Warehouse: "倉庫",

  stockToys_Toy_PlanTemplate_Comment: "プランテンプレートコメント",
  stockToys_Toy_PlanTemplate_Id: "プランテンプレートのID",
  stockToys_Toy_PlanTemplate_Title: "プランテンプレート名",
  stockToys_Toy_PlanTemplate_Uuid: "プランテンプレートのUUID",
  stockToys_Toy_ToyRequiredBatteries_Uuid: "おもちゃに必要な電池のUUID",
  stockToys_AcceptedAt: {
    start: "検収日（開始）",
    end: "検収日（終了）",
  },
};
