import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Inventory } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<Inventory>;
const initialState = getInitialState<Inventory>();

const InventoriesSlice = createSlice({
  name: "inventories",
  initialState,
  reducers: {
    rehydrateInventories: (state, { payload }: PayloadAction<PayloadType>) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateInventories } = InventoriesSlice.actions;
export default InventoriesSlice.reducer;
