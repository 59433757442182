import dayjs from "dayjs";
import { type CalendarHolidays } from "@/api/generated/openapi-typescript/apiv1/schemas/camel";

const defaultFormat = "YYYY-MM-DD";

// What the fucking error...

export enum FormatDateType {
  DATE,
  DATETIME,
}

export const dateFormat: Record<FormatDateType | string, string> = {
  [FormatDateType.DATE]: defaultFormat,
  [FormatDateType.DATETIME]: "YYYY-MM-DDTHH:mm",
  "YYYY/MM/DD": "YYYY/MM/DD",
};

/**
 * @note
 * value が undefined である場合に、今日日付を返却する。
 */
export const formatDate = (
  value: string | Date | undefined,
  format: FormatDateType = FormatDateType.DATE,
): string => {
  if (value === "") {
    return "";
  }
  return dayjs(value).format(dateFormat[format]);
};

/**
 * 高頻度で利用する日付フォーマット
 */
type DateFormat =
  | "YYYY-MM-DD"
  | "YYYY-MM-DDTHH:mm"
  | "YYYY-MM-DD HH:mm"
  | "YYYY-MM-DD HH:mm:ss"
  | "YYYY/MM/DD"
  | "YYYY/MM/DD HH:mm"
  | "YYYY年MM月DD日"
  | "YYYY年MM月DD日 HH時mm分"
  | "MM/DD"
  | "HH:mm";

/**
 * @note
 * value が undefined である場合に、今日日付を返却せずに、空文字列を返却する。
 * Format一覧
 * | "YYYY-MM-DD"
 * | "YYYY-MM-DDTHH:mm"
 * | "YYYY-MM-DD HH:mm"
 * | "YYYY-MM-DD HH:mm:ss"
 * | "YYYY/MM/DD"
 * | "YYYY/MM/DD HH:mm"
 * | "YYYY年MM月DD日"
 * | "YYYY年MM月DD日 HH時mm分"
 * | "MM/DD"
 * | "HH:mm"
 */
export const formatDateOrEmptyString = (
  value: string | Date | undefined,
  format: DateFormat = defaultFormat,
): string => {
  if (value === undefined || value === "") {
    return "";
  }
  return dayjs(value).format(format);
};

export const validateDateString = (
  value: string | undefined,
  format: FormatDateType = FormatDateType.DATE,
): boolean => dayjs(value, dateFormat[format]).isValid();

/**
 *
 * @param value string | Date | undefined
 * @returns number
 *
 * @note
 * valueにfalsyな文字列やundefinedを受け取った場合に現在のUNIX時間を返却
 */
export const unixTime = (value: string | Date | undefined): number => {
  if (value != null && value !== "") {
    return dayjs(value).unix();
  }
  return dayjs().unix();
};

/**
 *
 * @param calenderHoliday 休日の情報が入ったオブジェクト
 * @param daysAfter 何営業日後か、デフォルトは1営業日後
 * @param limit 何日後まで営業日を検索するか、デフォルトは60日
 * @returns 営業日のDateオブジェクト、limitまで検索しても見つからなかった場合はundefinedが帰ってくる
 *
 * @example
 * 3日(金曜日)の2営業日後は火曜日であるので、7日(火曜日)のDateオブジェクトが返却される
 *
 */
export const businessDay = (
  calendarHolidays: CalendarHolidays | undefined,
  daysAfter = 1,
  limit = 60,
): Date | undefined => {
  const today = dayjs();
  const limitDate = today.add(limit, "day");
  let businessDayCount = 0;
  for (
    let currentDate = today;
    currentDate.isBefore(limitDate);
    currentDate = currentDate.add(1, "day")
  ) {
    const isHoliday =
      calendarHolidays?.holidays?.[currentDate.format("YYYYMMDD")] === true;
    if (!isHoliday) {
      // カウントした日数がdaysAfterと同じになったら、指定した営業日まで回ったということなので、その日のDateオブジェクトを返却
      if (businessDayCount === daysAfter) {
        return currentDate.toDate();
      }
      // 休日ではないが、指定した営業日まで回っていないので、
      businessDayCount += 1;
    }
  }
  return undefined;
};
