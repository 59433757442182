import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type PlannerMessage } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<PlannerMessage>;
const initialState = getInitialState<PlannerMessage>();

const PlannersMessagesSlice = createSlice({
  name: "plannersMessages",
  initialState,
  reducers: {
    rehydratePlannersMessages: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydratePlannersMessages } = PlannersMessagesSlice.actions;
export default PlannersMessagesSlice.reducer;
