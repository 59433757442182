import { enqueueSnackbar as enqueueSnackbarOriginal } from "notistack";
import { type EnqueueSnackbarParams } from "./types";

export const customEnqueueSnackbarOption = ([
  msg,
  options,
]: EnqueueSnackbarParams): [
  EnqueueSnackbarParams[0],
  EnqueueSnackbarParams[1],
] => {
  const optionObject = options ?? {};
  switch (options?.variant) {
    case "error":
      return [
        msg,
        {
          persist: true,
          ...optionObject,
        },
      ];
    default:
      return [msg, optionObject];
  }
};
export const enqueueSnackbar = (...params: EnqueueSnackbarParams) => {
  const [msg, options] = customEnqueueSnackbarOption(params);
  return enqueueSnackbarOriginal(msg, options);
};
