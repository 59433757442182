import { type Order, isOrder } from "@/helper/sort";

export interface Sort {
  by: string;
  order: Order;
}

const createSortQuery = (sort: Sort) => {
  const { by, order } = sort;
  return {
    [`sort[${by}]`]: order,
  };
};
export const createSortQueries = (sort?: Sort | Sort[]) => {
  if (sort === undefined) {
    return {};
  }
  if (Array.isArray(sort)) {
    return sort.reduce((acc, s) => {
      return {
        ...acc,
        ...createSortQuery(s),
      };
    }, {});
  }

  return createSortQuery(sort);
};

export interface LooseSort {
  by?: string;
  order?: string;
}

export const createSortQueryLoose = (sort?: LooseSort) => {
  if (sort === undefined) {
    return {};
  }
  if (!isOrder(sort.order) || sort.by === undefined) {
    return {};
  }

  return createSortQuery({ order: sort.order, by: sort.by });
};
