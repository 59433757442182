export const COLUMNS_LABELS_WAREHOUSES = {
  id: "ID",
  uuid: "UUID",
  address1: "住所1",
  address2: "住所2",
  name: "名前", // ja.ts:295 では "倉庫名" でした
  pref: "都道府県",
  tel: "電話番号",
  zip_code: "郵便番号",
  shelves: "棚",
  warehouse_type: "種別",
  country: "所在国",
};
