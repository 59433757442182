import { localStorageAvailable } from "./available";
import { type SearchSettingOptions } from "../search-setting";
import { logger } from "../logger";
import { localStorageKeys } from "@/configs/localStorage";

const get = (): SearchSettingOptions => {
  if (!localStorageAvailable()) {
    return {};
  }

  try {
    return JSON.parse(
      localStorage.getItem(localStorageKeys.searchSettings) ?? "{}",
    );
  } catch (e) {
    logger.error(
      "failed to get searchSettings from localStorage",
      {
        error: !(e instanceof Error)
          ? e
          : {
              name: e.name,
              message: e.message,
              stack: e.stack,
            },
      },
      e instanceof Error ? e : undefined,
    );
    return {};
  }
};

const set = (option: SearchSettingOptions) => {
  if (!localStorageAvailable()) {
    return;
  }
  localStorage.setItem(localStorageKeys.searchSettings, JSON.stringify(option));
};

export const searchSettings = {
  get,
  set,
} as const;
