// ja.ts になかったです
export const COLUMNS_LABELS_WAREHOUSE_TRANSFERS = {
  id: "ID",
  uuid: "UUID",
  name: "名前",
  previous_warehouse_id: "前の事業所のID",
  after_warehouse_id: "後の事業所のID",
  left_at: "出庫日時",
  scheduled_stock_at: "入庫予定日時",
  stocked_at: "入庫日時",
};
