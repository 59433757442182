import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RehydratePager, getInitialState } from "@/types/Pager";
import { type FamilyCustomerType } from "@/openapi/api";

type PayloadType = RehydratePager<FamilyCustomerType>;
const initialState = getInitialState<FamilyCustomerType>();

const FamiliesCustomersTypesSlice = createSlice({
  name: "customersTypes",
  initialState,
  reducers: {
    rehydrateFamiliesCustomersTypes: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateFamiliesCustomersTypes } =
  FamiliesCustomersTypesSlice.actions;
export default FamiliesCustomersTypesSlice.reducer;
