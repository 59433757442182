import {
  Controller,
  type RegisterOptions,
  type ControllerProps,
  type FieldPath,
  type FieldValues,
} from "react-hook-form";
import { TextField, type TextFieldProps } from "./TextField";
import { type RhfControllerForm } from "@/types/react-hook-form";
import { type DatadogAttributes } from "@/types/datadog";

export type RhfCTextFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  defaultValue?: ControllerProps<TFieldValues, TName>["defaultValue"];
  /**
   * @NOTE このpropsが定義された場合、muiのrequiredと、react-hook-formのrequiredが両方onになりますが、
   * rules.requiredも定義されている場合は、rules.requiredが優先されます
   */
  required?: RegisterOptions<TFieldValues, TName>["required"];
} & RhfControllerForm<TFieldValues, TName> &
  Partial<Omit<TextFieldProps, "defaultValue" | "name" | "required">> &
  DatadogAttributes;

/**
 * NOTE: RhfTextFieldによる動作がうまく行かない場合に利用してください
 */
export const RhfCTextField = <
  T extends FieldValues,
  TName extends FieldPath<T> = FieldPath<T>,
>({
  name,
  control,
  rules,
  defaultValue,
  onChange,
  required,
  ddAllowPrivacy,
  ...textFieldProps
}: RhfCTextFieldProps<T, TName>) => {
  return (
    <Controller<T, TName>
      name={name}
      rules={{ required, ...rules }}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { ref, onChange: rOnChange, ...params },
        fieldState: { error },
      }) => (
        <TextField
          {...params}
          inputRef={ref}
          error={error !== undefined}
          onChange={(e) => {
            rOnChange(e);
            onChange?.(e);
          }}
          // required propsの方で定義されていれば、そちらを使うが、
          // rules.requiredで定義されていれば、利用者はmuiのtextfieldもrequiredになると思っているので、
          // 両方onにする
          required={required === true || rules?.required !== undefined}
          helperText={error?.message}
          ddAllowPrivacy={ddAllowPrivacy}
          {...textFieldProps}
        />
      )}
    />
  );
};
