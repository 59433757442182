import styled from "@emotion/styled";
import { isStg } from "@/helper/env";
import { addDDAllowPrivacy } from "@/helper/datadog";
import { type DatadogAttributes } from "@/types/datadog";

const TestModeBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 6px 0;
  background-color: ${isStg() ? "#0000ff" : "#ff0000"};
  font-size: 10px;
  text-align: center;
  font-weight: bolder;
  color: #ffffff;
  z-index: 1030;
  border-bottom: 1px solid #ffffff;

  &.position-static {
    position: static;
  }

  @media print {
    display: none;
  }
`;

const TestMessageWrapper = styled.div`
  display: flex;
`;

const TestMessage = styled.div`
  flex: auto;
`;

const TestMode = ({
  position,
  ddAllowPrivacy,
}: { position?: "static" | "absolute" } & DatadogAttributes) => {
  return (
    <TestModeBox className={position ? `position-${position}` : ""}>
      <TestMessageWrapper>
        <TestMessage {...addDDAllowPrivacy(ddAllowPrivacy)}>
          THIS IS A {isStg() ? "STAGING" : "DEVELOPMENT"} MODE
        </TestMessage>
        <TestMessage>
          F: {process.env.APP_VERSION_HASH ?? ""} | build_mode:{" "}
          {process.env.NODE_ENV ?? ""}
        </TestMessage>
      </TestMessageWrapper>
    </TestModeBox>
  );
};

export default TestMode;
