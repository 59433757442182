export const toyCategoryFilterLabels = {
  uuid: "UUID",
  name: "カテゴリー名",
  createdAt: {
    start: "作成日時 (開始)",
    end: "作成日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
};
