import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RehydratePager, getInitialState } from "@/types/Pager";
import { type Shelf } from "@/openapi/api";

type PayloadType = RehydratePager<Shelf>;
const initialState = getInitialState<Shelf>();

const ShelvesSlice = createSlice({
  name: "shelves",
  initialState,
  reducers: {
    rehydrateShelves: (state, { payload }: PayloadAction<PayloadType>) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateShelves } = ShelvesSlice.actions;
export default ShelvesSlice.reducer;
