import { useCallback } from "react";
import { useSnackbar } from "@/lib/notistack";
import { MadrasAPIResponseError } from "@/api/errors";
import { logger } from "@/helper/logger";

/**
 * APIレスポンスのエラーハンドリングを行うhooks
 * @note
 * httpメソッド名を関数名に含めていません
 * 例えば、usePostErrorHandlerなどの名前には意図的にしていません
 */
export const useAPIErrorHandler = () => {
  const { enqueueSnackbar } = useSnackbar();
  const handleAPIError = useCallback(
    (
      error: unknown,
      options?: {
        overrideMessage?: (originalMessage: string) => string;
        translate?: (fieldName: string) => string;
      },
    ) => {
      if (error instanceof MadrasAPIResponseError) {
        // MadrasAPIResponseErrorがすでに渡ってきているということは、api client側の
        // エラーハンドリングの段階でログは出力されているはずなので、ここではログを出力しない
        const { message } = error.getErrorForUser(options?.translate);
        enqueueSnackbar(options?.overrideMessage?.(message) ?? message, {
          variant: "error",
        });
      } else if (error instanceof Error) {
        const errMessage = `意図しないエラーが発生しました\n${error.name} ${error.message}`;
        enqueueSnackbar(errMessage, { variant: "error" });
        logger.error(`${error.name} ${error.message}`, {
          cause: error.cause,
          stack: error.stack,
        });
      } else {
        const errMessage = `意図しないエラーが発生しました\n${JSON.stringify(
          error,
          null,
          2,
        )}`;
        enqueueSnackbar(errMessage, { variant: "error" });
        logger.error(errMessage);
      }
    },
    [enqueueSnackbar],
  );

  return {
    handleAPIError,
  };
};
