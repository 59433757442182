import {
  type MadrasAPIResponseErrorConstructor,
  type APIClient,
} from "./types";
import {
  errorTypeFromAxios,
  errorTypeFromFetch,
} from "../../helpers/convert-error-type";
import { type Translate, type APIErrorType } from "../../helpers/types";
import {
  userErrorMessage,
  userErrorSeverity,
  userErrorTitle,
} from "../../helpers/user-error-message";
import { getCodesOrCodeArray } from "../../helpers/get-codes";
import { logger } from "@/helper/logger";
import { debugFormData } from "@/helper";
/**
 * madrasから帰ってくるエラーを管理するクラス。
 * クラスである理由は、エラーハンドリング時に、エラーのインスタンスによって処理を分けるため。
 * ここでapiClientの個別のプロパティをできる限り隠蔽する
 * @note
 * できる限りクラス内でUIに関する処理を行わないようにする
 */
export class MadrasAPIResponseError<T extends APIClient> extends Error {
  public errorType: APIErrorType = "deadLogic";

  /**
   * @note clientTypeがaxiosの場合はAxiosError、fetchの場合はResponse型の値が入る
   */
  private rawError: MadrasAPIResponseErrorConstructor<T> | undefined =
    undefined;

  public response: {
    statusCode: number | undefined;
    body: object | undefined;
  } = {
    statusCode: undefined,
    body: undefined,
  };

  private request: {
    method: string;
    url: string;
    body: object | undefined;
  } = {
    method: "unknown",
    url: "unknown",
    body: undefined,
  };

  private severity: keyof typeof logger = "error";

  private apiClient: APIClient = "unknown";

  private ignoreDDLogs = false;

  private static axiosConstructorValues(
    error: MadrasAPIResponseErrorConstructor<"axios">["error"],
  ): {
    request: MadrasAPIResponseError<"axios">["request"];
    response: MadrasAPIResponseError<"axios">["response"];
    errorType: MadrasAPIResponseError<"axios">["errorType"];
  } {
    return {
      request: {
        method: error.config?.method?.toUpperCase() ?? "unknown",
        url: error.config?.url ?? "unknown",
        body:
          error.config?.data instanceof FormData
            ? debugFormData(error.config.data)
            : error.config?.data,
      },
      response: {
        statusCode: error?.response?.status,
        body: error?.response?.data ?? undefined,
      },
      errorType: errorTypeFromAxios(error),
    };
  }

  /**
   * @todo
   * api clientをaxiosに統一したらfetch関連のコードはすべて削除する
   */
  private static fetchConstructorValues(
    error: MadrasAPIResponseErrorConstructor<"fetch">["error"],
  ): {
    request: MadrasAPIResponseError<"fetch">["request"];
    response: MadrasAPIResponseError<"fetch">["response"];
    errorType: MadrasAPIResponseError<"fetch">["errorType"];
  } {
    return {
      request: {
        method: error.request.method.toUpperCase(),
        url: error.request.url,
        body:
          error.request.body instanceof FormData
            ? debugFormData(error.request.body)
            : error.request.body,
      },
      response: {
        statusCode: error?.response?.status,
        body: error?.response?.body,
      },
      errorType: errorTypeFromFetch(error),
    };
  }

  constructor(
    args: MadrasAPIResponseErrorConstructor<T> & { ignoreDDLogs?: boolean },
  ) {
    super();
    this.name = "MadrasAPIResponseError";

    const { clientType, error } = args;

    this.apiClient = clientType;
    this.rawError = args;
    this.ignoreDDLogs = args.ignoreDDLogs ?? false;

    let constructorValue:
      | {
          request: MadrasAPIResponseError<T>["request"];
          response: MadrasAPIResponseError<T>["response"];
          errorType: MadrasAPIResponseError<T>["errorType"];
        }
      | undefined;
    // 各apiClientごとにプロパティの設定経路が異なるので、ここで分岐させる
    switch (this.apiClient) {
      case "axios": {
        constructorValue = MadrasAPIResponseError.axiosConstructorValues(
          error as MadrasAPIResponseErrorConstructor<"axios">["error"],
        );
        break;
      }
      case "fetch": {
        constructorValue = MadrasAPIResponseError.fetchConstructorValues(
          error as MadrasAPIResponseErrorConstructor<"fetch">["error"],
        );
        break;
      }
      default: {
        this.apiClient = "unknown";

        constructorValue = {
          request: {
            method: "unknown",
            url: "unknown",
            body: undefined,
          },
          response: {
            statusCode: undefined,
            body: undefined,
          },
          errorType: "deadLogic",
        };
        break;
      }
    }

    this.errorType = constructorValue.errorType;
    this.severity = userErrorSeverity[this.errorType];
    this.request = constructorValue.request;
    this.response = constructorValue.response;
    this.setErrorMessages();
  }

  private setErrorMessages() {
    // Datadogのissue自動起票によってクエリパラメータがあると常にエラーが起票されるので
    // FIXME: 完全なurlをここに表示したいがいくつかの問題がある
    // 1. fetch clientやaxios clientのurlは完全なurlではなくpathを渡している
    // 2. ネットワークエラーやabortエラーの場合など、this.request.urlがundefinedになる場合があり、その場合を考慮しなければならない
    // 上記の影響範囲を考慮すると回収範囲が広いので一旦urlはエラーメッセージから表示しないようにします
    // 詳細自体はdatadogログのエラーのattributeに表示されてるのでトラッキングには問題ありません
    const codes = getCodesOrCodeArray(this.response.body) ?? [];
    this.message = `MadrasAPIResponseError errorType:${this.errorType} method:${
      this.request.method
    }${codes.length > 0 ? ` codes:${codes.join(",")}` : ""}`;
  }

  public getErrorForUser(translate?: Translate): {
    title: string;
    message: string;
    severity: keyof typeof logger;
  } {
    const {
      response: { body },
      errorType,
      severity,
    } = this;

    const title = userErrorTitle[errorType];
    const userErrorMessageValue = userErrorMessage[errorType];
    const message =
      typeof userErrorMessageValue === "function"
        ? userErrorMessageValue(body, translate)
        : userErrorMessageValue;

    return { title, message, severity };
  }

  /**
   * @note
   * ignoreDDLogsがtrueの場合は何も行わない
   */
  public sendLog(): void {
    if (this.ignoreDDLogs) {
      return;
    }

    logger[this.severity](this.message, {
      madras_error: JSON.parse(JSON.stringify(this)),
    });
  }
}
