export const PAGE_CUSTOMER = "0733ff5d-134c-4d77-921c-e3acf5dd6197";
export const PAGE_CUSTOMER_CONTRACTED_PLAN =
  "91edc2a5-bed6-4f43-9234-4d4a3f34ae19";
export const PAGE_TOY = "f0ea9a99-38ab-4b9d-bcd9-c215d66332e0";
export const PAGE_TOY_MAKER = "91f8ea4d-0700-4285-aee8-e4053971c274";
export const PAGE_TOY_SUPPLIER = "3c3f53d0-5517-40e2-9629-df3d07dc638a";
export const PAGE_TOY_CATEGORY = "d67e1ad2-94c2-4383-a239-cda428cf334b";
export const PAGE_TOY_MATERIAL = "f38ecf79-6421-4db6-8b5a-be2cd0647627";
export const PAGE_INDIVIDUAL_TOY = "9c17d8af-0a9f-4ddb-bcdc-ad854b725eb1";
export const PAGE_SIMILAR_TOY = "972d9ee3-9192-4f99-a0e8-72867d04200c";
export const PAGE_PLAN = "e915164d-e962-4082-bfbd-3ad10ed5f9b8";
export const PAGE_INVENTORY = "51efb3c6-8c0f-425e-bc45-a29cb0ac779b";
export const PAGE_INSPECTION = "83127d59-0b7f-9eb1-bf29-24ace7a15c8e";
export const PAGE_SHIPMENT = "52dc0de0-376c-4050-aa8d-0df6411562d6";
export const PAGE_SHIPMENT_DELIVERY_METHOD =
  "838c6d65-715a-412d-8028-ce6b2f86bd8c";
export const PAGE_SHIPMENT_EXPIRATION_DATE_SETTINGS =
  "97edc5f0-bc93-45a4-888a-b18c931d805b";
export const PAGE_SUBSCRIPTION = "27567bdc-2b7c-4c55-891b-bf5bbddeba5f";
export const PAGE_STAFF = "322d7087-a82f-4dd9-8287-1f2d9de43c4a";
export const PAGE_WAREHOUSE = "bbad06f5-df52-43a7-89ae-a0c06cf8b02c";
export const PAGE_SHELF = "6de28b14-9cb2-40f0-af2e-a8e6ce995568";
export const PAGE_COUPON_TYPE = "0549840f-a643-47dd-b895-62181e4e2268";
export const PAGE_COUPON_CUSTOMER = "7c5e8ab7-dff0-41a0-b0a0-364dfef2f996";
export const PAGE_PLANNER = "aa9a1452-7e63-422b-9448-7f5cb23bb98e";
export const PAGE_PLANNER_CATEGORY = "50b07638-56a5-4dd6-8483-fb0d09f8439e";
export const PAGE_STOCK = "c7dc2fb4-13cc-411d-9ec1-bf58f6c23a07";
export const PAGE_PERKS = "218b5e0f-e101-4b15-92f9-8291b8a2e66f";
export const PAGE_WAREHOUSE_TRANSFER = "d6f50d44-4b1a-4dd9-bcac-47d188a6b8ba";
export const PAGE_RETURN = "682949f5-5216-2f96-0250-27cd4deb6098";

export const pageIds = [
  PAGE_CUSTOMER,
  PAGE_CUSTOMER_CONTRACTED_PLAN,
  PAGE_TOY,
  PAGE_TOY_MAKER,
  PAGE_TOY_SUPPLIER,
  PAGE_TOY_CATEGORY,
  PAGE_TOY_MATERIAL,
  PAGE_INDIVIDUAL_TOY,
  PAGE_SIMILAR_TOY,
  PAGE_PLAN,
  PAGE_INVENTORY,
  PAGE_INSPECTION,
  PAGE_SHIPMENT,
  PAGE_SHIPMENT_DELIVERY_METHOD,
  PAGE_SHIPMENT_EXPIRATION_DATE_SETTINGS,
  PAGE_SUBSCRIPTION,
  PAGE_STAFF,
  PAGE_WAREHOUSE,
  PAGE_SHELF,
  PAGE_COUPON_TYPE,
  PAGE_COUPON_CUSTOMER,
  PAGE_PLANNER,
  PAGE_PLANNER_CATEGORY,
  PAGE_STOCK,
  PAGE_PERKS,
  PAGE_WAREHOUSE_TRANSFER,
  PAGE_RETURN,
];

export type PageId =
  | typeof PAGE_CUSTOMER
  | typeof PAGE_CUSTOMER_CONTRACTED_PLAN
  | typeof PAGE_TOY
  | typeof PAGE_TOY_MAKER
  | typeof PAGE_TOY_SUPPLIER
  | typeof PAGE_TOY_CATEGORY
  | typeof PAGE_TOY_MATERIAL
  | typeof PAGE_INDIVIDUAL_TOY
  | typeof PAGE_SIMILAR_TOY
  | typeof PAGE_PLAN
  | typeof PAGE_INVENTORY
  | typeof PAGE_INSPECTION
  | typeof PAGE_SHIPMENT
  | typeof PAGE_SHIPMENT_DELIVERY_METHOD
  | typeof PAGE_SHIPMENT_EXPIRATION_DATE_SETTINGS
  | typeof PAGE_SUBSCRIPTION
  | typeof PAGE_STAFF
  | typeof PAGE_WAREHOUSE
  | typeof PAGE_SHELF
  | typeof PAGE_COUPON_TYPE
  | typeof PAGE_COUPON_CUSTOMER
  | typeof PAGE_PLANNER
  | typeof PAGE_PLANNER_CATEGORY
  | typeof PAGE_STOCK
  | typeof PAGE_PERKS
  | typeof PAGE_WAREHOUSE_TRANSFER
  | typeof PAGE_RETURN;

/**
 * @note
 * Excludeで除去しているページは、 api側のenumで定義されていないページです。
 */
export type PageEnumBackend = Exclude<
  PageId,
  typeof PAGE_PERKS | typeof PAGE_SIMILAR_TOY
>;

type PageUuidToIntId = Record<PageEnumBackend, number>;

/**
 * @note
 * バックエンド側では、int型のidでpage_idを管理している。
 * フロントエンドからバックエンドにpage_idを渡す際にはuuid -> idにマッピングする必要があるので、ここに定義する。
 *
 * @see
 * `apps/api/MadrasDomain/Enum/Page.php` のEnumと一致している必要がある
 */
export const pageUuidToId: PageUuidToIntId = {
  [PAGE_CUSTOMER]: 100,
  [PAGE_CUSTOMER_CONTRACTED_PLAN]: 101,
  [PAGE_TOY]: 300,
  [PAGE_TOY_MAKER]: 301,
  [PAGE_TOY_SUPPLIER]: 302,
  [PAGE_TOY_CATEGORY]: 303,
  [PAGE_TOY_MATERIAL]: 304,
  [PAGE_PLAN]: 400,
  [PAGE_INVENTORY]: 500,
  [PAGE_INDIVIDUAL_TOY]: 600,
  [PAGE_SHIPMENT]: 700,
  [PAGE_SHIPMENT_DELIVERY_METHOD]: 701,
  [PAGE_SHIPMENT_EXPIRATION_DATE_SETTINGS]: 702,
  [PAGE_SUBSCRIPTION]: 800,
  [PAGE_STAFF]: 900,
  [PAGE_WAREHOUSE]: 1000,
  [PAGE_SHELF]: 1001,
  [PAGE_WAREHOUSE_TRANSFER]: 1002,
  [PAGE_STOCK]: 1100,
  [PAGE_PLANNER]: 1200,
  [PAGE_PLANNER_CATEGORY]: 1201,
  [PAGE_INSPECTION]: 1300,
  [PAGE_COUPON_TYPE]: 1500,
  [PAGE_COUPON_CUSTOMER]: 1501,
  [PAGE_RETURN]: 1600,
} as const;
