import { useContext } from "react";
import { ModeContext } from "@/contexts/mode";
import { screenModes } from "@/helper/modes";

export const useMode = () => {
  const mode = useContext(ModeContext);

  if (mode === undefined) {
    throw new Error("MainLayout にて mode を指定してください。");
  }

  return screenModes(mode);
};
