export const IS_NONE = 0;
export const IS_VIEWER = 1;
export const IS_EDITOR = 2;
export const IS_ADMIN = 2147483647;

// ~~~~~~~~~~~~~~~~~~~~過去の遺産~~~~~~~~~~~~~~~
/**
 * 昔はbitmapで権限を管理しようとしていたが、中途半端になってしまっている
 * 現在は動作しない権限の数値です。
 * 将来的には、IPO対応のための権限管理実装で根本的に変わる予定です。
 *
 */
export const IS_MEMBER = 4;
export const IS_MANAGER = 32;
export const IS_LOGISTICS = 256;
export const IS_MARKETING = 512;
export const IS_CS = 1024;
export const IS_STOCKING = 2048;
export const IS_USER_READ = 16384;
export const IS_USER_WRITE = 32768;

type Permissions =
  | typeof IS_NONE
  | typeof IS_VIEWER
  | typeof IS_EDITOR
  | typeof IS_MEMBER
  | typeof IS_MANAGER
  | typeof IS_LOGISTICS
  | typeof IS_MARKETING
  | typeof IS_CS
  | typeof IS_STOCKING
  | typeof IS_USER_READ
  | typeof IS_USER_WRITE
  | typeof IS_ADMIN;

const permissionLabels: { [k in Permissions]: string } = {
  [IS_NONE]: "",
  [IS_VIEWER]: "閲覧権限",
  [IS_EDITOR]: "編集権限",
  [IS_MEMBER]: "メンバー権限",
  [IS_MANAGER]: "マネージャー権限",
  [IS_LOGISTICS]: "ロジスティクス権限",
  [IS_MARKETING]: "マーケティング権限",
  [IS_CS]: "CS権限",
  [IS_STOCKING]: "仕入れ権限",
  [IS_USER_READ]: "",
  [IS_USER_WRITE]: "",
  [IS_ADMIN]: "管理者権限",
};

// ~~~~~~~~~~~~~~~~~~~~過去の遺産~~~~~~~~~~~~~~~

const hasPermission = (
  combinedPermission: number | undefined,
  destPermission: number | undefined,
): boolean => {
  if (combinedPermission === undefined || destPermission === undefined)
    return false;
  if (destPermission === IS_ADMIN) {
    return combinedPermission === destPermission;
  }

  return !!(combinedPermission & destPermission);
};

export const permissionAccessor =
  (combinedPermission: number | undefined) =>
  (destPermission: number | undefined): boolean =>
    hasPermission(combinedPermission, destPermission);

export function getPermission(permission: number | undefined): string[] {
  const strings: string[] = [];
  if (permission === undefined) return strings;

  const hasPermissionShadow = permissionAccessor(permission);

  if (hasPermissionShadow(IS_ADMIN)) {
    return [permissionLabels[IS_ADMIN]];
  }

  if (hasPermissionShadow(IS_STOCKING)) {
    strings.push(permissionLabels[IS_STOCKING]);
  }

  if (hasPermissionShadow(IS_CS)) {
    strings.push(permissionLabels[IS_CS]);
  }

  if (hasPermissionShadow(IS_MARKETING)) {
    strings.push(permissionLabels[IS_MARKETING]);
  }

  if (hasPermissionShadow(IS_LOGISTICS)) {
    strings.push(permissionLabels[IS_LOGISTICS]);
  }

  if (hasPermissionShadow(IS_MANAGER)) {
    strings.push(permissionLabels[IS_MANAGER]);
  }

  if (hasPermissionShadow(IS_MEMBER)) {
    strings.push(permissionLabels[IS_MEMBER]);
  }

  if (hasPermissionShadow(IS_EDITOR)) {
    strings.push(permissionLabels[IS_EDITOR]);
  }

  if (hasPermissionShadow(IS_VIEWER)) {
    strings.push(permissionLabels[IS_VIEWER]);
  }
  return strings;
}
