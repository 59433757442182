import { composePath } from "./composePath";
import { type PathParams } from "./type";
import {
  type ApiPaths,
  type ApiPathsWithoutPathParam,
  type ApiPathsWithPathParam,
} from "../../types";
import { baseURL } from "@/configs/endpoint";

export function getUrl<Path extends ApiPathsWithoutPathParam>(
  path: Path,
): string | null;
export function getUrl<Path extends ApiPathsWithPathParam>(
  path: Path,
  paramsInPath: PathParams<Path>,
): string | null;
export function getUrl<Path extends ApiPaths>(
  path: Path,
  paramsInPath?: PathParams<Path>,
): string | null {
  const p = composePath(path, paramsInPath);
  return p === null ? null : `${baseURL}${p}`;
}

export function getUrlUnsafe<Path extends ApiPaths>(
  path: Path,
  paramsInPath?: PathParams<Path>,
): string {
  const p = composePath(path, paramsInPath);
  if (p === null) {
    throw new Error(
      `[getUrlUnsafe] 引数に誤りがあります。引数: ${JSON.stringify({
        path,
        paramsInPath,
      })}`,
    );
  }
  return `${baseURL}${p}`;
}
