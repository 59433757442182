import merge from "deepmerge";
import { type MadrasAPIReturn, type MadrasAPIOptions } from "./type";
import { useMadrasAPICore } from "./useMadrasAPICore";
import {
  type ApiPathsWithoutPathParam,
  type ApiPathsWithGetJsonMethod,
  type Methods,
  type ApiPathsWithPathParam,
} from "@/api/types";
import { propOrUndefined } from "@/helper/object";
import { createRevalidateConfig } from "@/api/swr/helpers";
import { optionsToQuery } from "@/api/helpers/optionsToQuery";

/**
 * madras api を利用するためのカスタムフック
 * @note
 * madras に依存する実装を追加する。
 * @example
 * * Pager 型強制付与
 * const v1PagerResult = useMadrasAPI("/api/v1/coupons", {
 *   $Pager: true, // 引数で Pager 型の付与をコントロールする
 * });
 *
 * * path parameter がない場合
 * useMadrasAPI("/api/v1/coupons");
 *
 * * path parameter がある場合、 params.path が必須となる
 *   （path parameter として undefined を渡せるが、その場合 request は送信されない。）
 * useMadrasAPI("/api/v1/coupons/{uuid}", {
 *   params: { path: { uuid: undefined } },
 * });
 *
 * @FIXME
 * * params.query に必須項目が存在する場合、 params を必須かする
 */
function useMadrasAPI<
  Path extends ApiPathsWithoutPathParam & ApiPathsWithGetJsonMethod,
  Method extends Methods<Path> & "get",
  ForcePagerType extends boolean = false,
>(
  path: Path,
  options?: MadrasAPIOptions<Path, Method, ForcePagerType>,
): MadrasAPIReturn<Path, Method, ForcePagerType>;
function useMadrasAPI<
  Path extends ApiPathsWithPathParam & ApiPathsWithGetJsonMethod,
  Method extends Methods<Path> & "get",
  ForcePagerType extends boolean = false,
>(
  path: Path,
  options: MadrasAPIOptions<Path, Method, ForcePagerType>, // params.path が必須
): MadrasAPIReturn<Path, Method, ForcePagerType>;
function useMadrasAPI<
  Path extends ApiPathsWithGetJsonMethod,
  Method extends Methods<Path> & "get",
  ForcePagerType extends boolean = false,
>(
  path: Path,
  options?: MadrasAPIOptions<Path, Method, ForcePagerType>,
): MadrasAPIReturn<Path, Method, ForcePagerType> {
  const configuredOption = {
    ...createRevalidateConfig(options?.immutable),
  };

  const response = useMadrasAPICore(path, {
    enabled: options?.enabled,
    key: options?.key,
    axiosRequestConfig: options?.axiosRequestConfig,
    params: {
      query: merge(optionsToQuery(options), options?.params?.query ?? {}),
      path: propOrUndefined(options?.params, "path"),
    } as any,
    swrOptions: merge(
      configuredOption,
      (options?.swrOptions ?? {}) as MadrasAPIOptions<
        Path,
        Method,
        ForcePagerType
      >,
    ),
  });

  return response as unknown as MadrasAPIReturn<Path, Method, ForcePagerType>;
}

export default useMadrasAPI;
