import {
  range as rangeRemeda,
  findIndex as findIndexRemeda,
  chunk as chunkRemeda,
} from "remeda";

export const chunk = chunkRemeda;

/**
 * c.f. https://remedajs.com/docs#range
 */
export const range = rangeRemeda;

// https://remedajs.com/docs#findIndex
export const findIndex = findIndexRemeda;

export const toArray = <T>(x: T[] | T): T[] => (Array.isArray(x) ? x : [x]);

export const toNonNilArray = <T>(
  x: T[] | T | undefined | null,
): NonNullable<T>[] =>
  toArray(x).filter((xx) => xx != null) as NonNullable<T>[];

export const mapIfArray = (f: (x: any) => any) => (xs: unknown) => {
  if (Array.isArray(xs)) {
    return xs.map(f);
  }
  return xs;
};

export const remove = <T>(array: T[], index: number): T[] => {
  const copied = [...array];
  copied.splice(index, 1);

  return copied;
};

export const update = <T extends object | string | number>(
  array: T[],
  element: T,
  index?: number,
): T[] => {
  const copied = [...array];
  if (index === undefined) {
    copied.push(element);
  } else {
    copied[index] = element;
  }

  return copied;
};
