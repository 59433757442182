import { logger } from "@/helper/logger";

export class UnExpectedError extends Error {
  constructor(error: unknown) {
    super(JSON.stringify(error, null, 2));
    this.name = "UnExpectedError";

    logger.error(
      `UnExpectedError: ${this.message}`,
      error instanceof Error ? undefined : { error }, // Errorインスタンスでないならばcontextに渡してログに出るようにする
      error instanceof Error ? error : undefined, // Errorインスタンスならばcontextに渡してもログに出ないので、ここで渡す
    );
  }
}
