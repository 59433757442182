export const MAX_LIMIT = 1000;

export const allFetchable = (parameters?: URLSearchParams): URLSearchParams => {
  const newParameters = new URLSearchParams(parameters);

  if (newParameters.has("limit")) {
    newParameters.delete("limit");
  }

  newParameters.append("limit", `${MAX_LIMIT}`);

  return newParameters;
};

export default {
  allFetchable,
};
