import { isTest } from "@/helper/env";

/**
 * cf. https://swr.vercel.app/docs/revalidation#disable-automatic-revalidations
 */
export const immutableConfig = {
  revalidateOnFocus: false,
  revalidateIfStale: false,
  revalidateOnReconnect: false,
};

const _swrConfigValue = {
  ...immutableConfig,

  /**
   * @NOTE revalidateOnMount = false の場合、設定によっては、リクエストが一度も送られない。
   * cf. https://github.com/vercel/swr/issues/943
   */
  revalidateOnMount: true, // ページ遷移した時に最新情報を取得したい
};

export const swrConfigValueForTest = {
  ..._swrConfigValue,
  /**
   * テストケース間のキャッシュのリセット
   * cf. https://swr.vercel.app/docs/advanced/cache#reset-cache-between-test-cases
   * @returns
   */
  provider: () => new Map(),

  /**
   * dedupingInterval = 0 としないと、テストを並列実行したときにテストが失敗してしまう。
   * cf. https://github.com/vercel/swr/issues/781#issuecomment-952738214
   */
  dedupingInterval: 0,

  /**
   * dedupingInterval = 0 とすると 「重複排除」（cf. https://swr.vercel.app/docs/advanced/performance.en-US#deduplication）
   * が無効になり、「重複排除」を前提とする実装では不具合が発生する。
   * revalidateOnMount = false にすることで、この不具合を防止する。
   * revalidateOnMount = false とすると、リクエストが一度も行われない場合がある。（ cf. https://github.com/vercel/swr/issues/943 ）
   * この問題には別途 Custom Hook で対応。
   */
  revalidateOnMount: false,
};

export const swrConfigValue = isTest()
  ? swrConfigValueForTest
  : _swrConfigValue;
