import { type NextRouter } from "next/router";
import { isDefined } from "remeda";
import { mapForObj, pick } from "@/helper/object";

type QueryValue = NextRouter["query"][string];

export const throwErrorWhenArray = (value: QueryValue): string | undefined => {
  if (Array.isArray(value)) {
    throw new Error("unexpected search param is used");
  }
  return value;
};

export const throwErrorWhenUnexpected = (value: QueryValue): string => {
  if (Array.isArray(value) || value === undefined) {
    throw new Error("unexpected search param is used");
  }
  return value;
};

/**
 * router.query の value について、配列型を排除する。
 */
export const pickStringQueries = <Names extends readonly string[]>(
  names: Names,
  query: NextRouter["query"],
): Record<Names[number], string | undefined> =>
  mapForObj(pick(query, names), throwErrorWhenArray) as Record<
    Names[number],
    string | undefined
  >;

export const getStringQuery = <Name extends string>(
  name: Name,
  query: NextRouter["query"],
): string | undefined =>
  mapForObj(pick(query, [name]), throwErrorWhenArray)[name];

/**
 * @deprecated
 * query に 最初から name が存在しない場合、error が throw されないバグあり。
 * 既存コードでも as string としている箇所が多く、同様の効果であるため、動作に直ちにに影響はない。
 */
export const pickRequiredStringQueries = <Names extends readonly string[]>(
  names: Names,
  query: NextRouter["query"],
): Record<string, string> =>
  mapForObj(pick(query, names), throwErrorWhenUnexpected);

/**
 * @deprecated
 * query に 最初から name が存在しない場合、error が throw されないバグあり。
 * 既存コードでも as string としている箇所が多く、同様の効果であるため、動作に直ちにに影響はない。
 */
export const getRequiredStringQuery = <Name extends string>(
  name: Name,
  query: NextRouter["query"],
): string => {
  const requiredString = mapForObj(
    pick(query, [name]),
    throwErrorWhenUnexpected,
  )[name];
  return isDefined(requiredString) ? requiredString : "";
};

export const omitStringQuery = <Name extends string>(
  names: Name[],
  query: NextRouter["query"],
): NextRouter["query"] => {
  const newQuery = { ...query };
  names.forEach((name) => {
    delete newQuery[name];
  });
  return newQuery;
};
