import deepmerge from "deepmerge";
import { useEffect } from "react";
import { useSWRConfig, type SWRConfiguration, type SWRResponse } from "swr";
import { mutateOnce } from "./_helpers";
import { type ValueKey } from "@/api/types/AspidaSWROption";

/**
 * revalidateOnMount = false の時、リクエストされない挙動を修正する。
 * cf. https://github.com/vercel/swr/issues/943
 * cf. https://github.com/vercel/swr/issues/455#issuecomment-643504686
 */
function useRevalidate(
  { response, key }: { response: SWRResponse; key: ValueKey },
  config?: SWRConfiguration,
) {
  const providedConfig = useSWRConfig();
  const { revalidateOnMount } = deepmerge(providedConfig, config ?? {});

  useEffect(() => {
    mutateOnce({ key, response, revalidateOnMount });
  }, [key, response, revalidateOnMount]);
}

export default useRevalidate;
