import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Coupon } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<Coupon>;
const initialState = getInitialState<Coupon>();

const CouponTypesSlice = createSlice({
  name: "coupons",
  initialState,
  reducers: {
    rehydrateCouponTypes: (state, { payload }: PayloadAction<PayloadType>) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateCouponTypes } = CouponTypesSlice.actions;
export default CouponTypesSlice.reducer;
