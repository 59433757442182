import { type FC, forwardRef } from "react";
import Box, { type BoxProps } from "@mui/material/Box";
import { type DatadogAttributes } from "@/types/datadog";
import { addDDAllowPrivacy } from "@/helper/datadog";

export type CenterProps = BoxProps & DatadogAttributes;
export const Center: FC<CenterProps> = forwardRef(function Center(
  { children, ddAllowPrivacy, ...boxProps },
  ref,
) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
      {...addDDAllowPrivacy(ddAllowPrivacy)}
      {...boxProps}
      ref={ref}
    >
      {children}
    </Box>
  );
});
