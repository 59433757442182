import axios from "axios";
import { intoBackendFormat } from "./helpers/intoBackendFormat";
import { intoFrontendFormat } from "./helpers/intoFrontendFormat";
import { axiosErrorInterceptor } from "../helpers/errorHandler";
import { baseURL } from "@/configs/endpoint";
import { toArray } from "@/helper/array";
import { dataToURLString } from "@/helper/object/dataToURLString";

const instance = axios.create({
  baseURL,
  withCredentials: true,
  /**
   * @note
   * ステータスコードのバリデーションは既存と同様。
   * * axios: https://github.com/axios/axios/blob/649d739288c8e2c55829ac60e2345a0f3439c730/lib/defaults/index.js#L167
   * * 既存: https://github.com/torana-us/madras-crm-frontend/blob/2efccc4f61a638ecee1b0360250bb53869c00d7d/src/helper/url-helper.ts#L167
   */
  validateStatus(status) {
    return status >= 200 && status < 300;
  },
  /**
   * @note
   * MADRAS では大多数の response の media type が "application/json" であるため（そもそもデフォルトが "json" だが明示的に指定しておく。）
   */
  responseType: "json",
  /**
   * @note
   * かつては AxiosInstance.interceptors.request を利用していたが、
   * axios.defaults.transformRequest で object を stringify される前に object を編集するために
   * transformRequest を利用する方針に変更した。
   */
  transformRequest: [
    intoBackendFormat,
    ...toArray(axios.defaults.transformRequest ?? []),
  ],
  transformResponse: [
    ...toArray(axios.defaults.transformResponse ?? []),
    intoFrontendFormat,
  ],

  paramsSerializer: (data) => dataToURLString(data), // TODO: axios の機能で代替できる場合は代替する
});

instance.interceptors.response.use(
  (data) => Promise.resolve(data),
  async (error) => {
    const customError = await axiosErrorInterceptor(error);
    return Promise.reject(customError);
  },
);

export default instance;
