import { type UuidObject, type UuidToBoolean } from "./types";

export const trueUuidKeysIntoUuidObjectArray = (
  object: UuidToBoolean,
): UuidObject[] => {
  return Object.keys(object).reduce<UuidObject[]>((acc, key) => {
    if (object[key]) {
      return [...acc, { uuid: key }];
    }
    return acc;
  }, []);
};
