import { isAxiosError } from "./predicates";
import { UnExpectedError } from "@/configs/classes/unexpected";
import { MadrasAPIResponseError } from "@/api/errors/classes/madras-api/madras-api-response-error";

type AxiosErrorHandler = (errorResponse: unknown) => Promise<Error>;

const clientType = "axios" as const;
/**
 * madras-platform-api のエラーハンドリングを実装する。
 * @note axios を前提としてます。
 * @prerequisite
 * madras-platform-api の status code が エラーの場合を前提とする。
 */
export const axiosErrorInterceptor: AxiosErrorHandler = async (error) => {
  if (!isAxiosError(error)) {
    // この関数はaxiosのintercepterに渡す関数であるため、必ずaxiosErrorになってなければならない。
    // そのため、ここはデッドロジックである想定
    return new UnExpectedError(error);
  }
  const madrasError = new MadrasAPIResponseError({
    clientType,
    error,
  });
  madrasError.sendLog();
  return madrasError;
};
