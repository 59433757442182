import {
  Checkbox as MuiCheckbox,
  type CheckboxProps as MuiCheckboxProps,
  FormControlLabel,
  type FormControlLabelProps,
} from "@mui/material";
import { forwardRef, type FC } from "react";
import { addDDAllowPrivacy } from "@/helper/datadog";
import { type DatadogAttributes } from "@/types/datadog";

export interface CheckboxProps extends MuiCheckboxProps, DatadogAttributes {
  label?: FormControlLabelProps["label"];
  dataTestId?: string;
}
export const Checkbox: FC<CheckboxProps> = forwardRef<
  HTMLButtonElement,
  CheckboxProps
>(({ label, dataTestId, inputProps, ddAllowPrivacy, ...props }, ref) => {
  return (
    <FormControlLabel
      label={label}
      control={
        <MuiCheckbox
          ref={ref}
          color="primary"
          inputProps={
            {
              "data-testid": dataTestId,
              ...inputProps,
            } as MuiCheckboxProps["inputProps"]
          }
          {...props}
        />
      }
      {...addDDAllowPrivacy(ddAllowPrivacy)}
    />
  );
});

Checkbox.displayName = "Checkbox";
