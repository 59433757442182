// madras api boolean
export const boolean = {
  true: "1",
  false: "0",
} as const;

export type MadrasBoolean = (typeof boolean)["true" | "false"];

export const intoMadrasBool = (bool: boolean): MadrasBoolean =>
  bool ? boolean.true : boolean.false;

export const fromMadrasBool = (
  bool: MadrasBoolean,
  defaultTo = false,
): boolean => {
  if (bool === boolean.true) return true;
  if (bool === boolean.false) return false;
  return defaultTo;
};

export const intoMadrasBoolIfBoolean = (x: unknown) =>
  typeof x === "boolean" ? intoMadrasBool(x) : x;
