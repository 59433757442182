/**
 * @note
 * https://github.com/remeda/remeda/issues/37#issue-523979527
 * has は未実装
 */
export function has<T extends object = object, Prop extends string = string>(
  prop: Prop,
  obj: T,
): obj is T & Record<Prop, unknown>;
export function has<Prop extends string = string>(
  prop: Prop,
): {
  <T extends object = object>(obj: T): obj is T & Record<Prop, unknown>;
  <T extends Partial<Record<Prop, unknown>>>(
    obj: T,
  ): obj is T & Record<Prop, T[Prop]>;
};
export function has(prop: any, obj?: any): any {
  if (arguments.length === 1) return (o: any) => has(prop, o); // curry

  if (obj === undefined) return false;

  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const hasName = has("name");
