import { type AppProps } from "next/app";
import SignIn from "@/components/sign-in/SignIn";
import { Loading } from "@/components/fragments/Loading";
import { useSignIn } from "@/hooks/useSignIn";
import { isNotProduction } from "@/helper/env";
import TestMode from "@/components/essentials/styled/TestMode";
import { type DatadogAttributes } from "@/types/datadog";

interface Props extends AppProps, DatadogAttributes {}

export default function Main({ Component, pageProps, ddAllowPrivacy }: Props) {
  const { isSignIn, isLoading } = useSignIn();

  return (
    <>
      {isNotProduction() && <TestMode ddAllowPrivacy />}
      {isLoading && <Loading />}
      {isSignIn ? (
        <Component {...pageProps} />
      ) : (
        <SignIn ddAllowPrivacy={ddAllowPrivacy} />
      )}
    </>
  );
}
