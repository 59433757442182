import { isDefined } from "remeda";
import { type DateAt, tags } from "@/configs/labels";

const filterDateAtTagLabel = (
  dateAt: DateAt,
  key: string | undefined,
): string => {
  if (key === "name" && dateAt.name !== undefined) {
    return dateAt.name;
  }
  return key === "end" ? dateAt.end : dateAt.start;
};
/**
 * 定義されているfieldsにpageId(１つ目のキー)、filterName(2つ目のキー)を指定することで、
 * 紐づく文字列かオブジェクト、該当するものがない場合にはpageIdとfilterNameを連結した文字列を返します
 *
 * ex.
 * おもちゃのUUIDの場合
 * (PAGE_TOY, "uuid") を渡すと文字列で field.[PAGE_TOY].uuidに該当する値
 *
 * おもちゃの登録日時（start, end）の場合
 * ※ start, endを個別に指定して文字列や日付で返す想定はしていません
 * (PAGE_TOY, "createdAt") を渡すとオブジェクトの field.[PAGE_TOY].createdATに該当する値
 *
 * 存在しないキーの場合
 * pageId, filterName どちらか１方でも存在しないキーor undefinedを渡すと空文字列を返す
 * @param pageId
 * @param tagKey
 * @returns
 */
export const findFilterTagLabel = (
  pageId?: string,
  tagKey?: string,
): string | DateAt => {
  const period = ".";

  if (!tagKey?.includes(period)) {
    return tags?.[String(pageId)]?.[String(tagKey)] ?? "";
  }

  const [tagKeyDateAt, tagKeyDatePeriod] = tagKey.split(period);
  const foundDateAt = tags?.[String(pageId)]?.[String(tagKeyDateAt)];
  if (typeof foundDateAt !== "string" && isDefined(foundDateAt)) {
    return filterDateAtTagLabel(foundDateAt, tagKeyDatePeriod);
  }

  return "";
};
