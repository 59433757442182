import { type HTMLAttributes } from "react";
import { type OptionValue } from "./types";
import { isOptionValue } from "./helpers";

export const comboboxDefaultProps = {
  freeSolo: false,
  fullWidth: true,
  autoSelect: false,
  getOptionLabel: <AT extends OptionValue = OptionValue>(
    option: AT | OptionValue,
  ) => {
    if (isOptionValue(option)) {
      return option.name ?? "( no name )";
    }
    return "( no name )";
  },
  renderOption: <AT extends OptionValue = OptionValue>(
    p: HTMLAttributes<HTMLLIElement>,
    option: AT | OptionValue,
  ) => {
    return (
      <li {...p} key={option?.uuid}>
        {option?.name}
      </li>
    );
  },
  isOptionEqualToValue: <AT extends OptionValue = OptionValue>(
    o: AT | OptionValue,
    v: AT | OptionValue,
  ) => {
    return isOptionValue(o) && isOptionValue(v) ? o.uuid === v.uuid : o === v;
  },
};
