import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getInitialState, type RehydratePager } from "@/types/Pager";
import { type ToySoundVolume } from "@/openapi/api";

type PayloadType = RehydratePager<ToySoundVolume>;
const initialState = getInitialState<ToySoundVolume>();

const ToysSoundsVolumes = createSlice({
  name: "toySoundVolume",
  initialState,
  reducers: {
    rehydrateToySoundVolumes: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateToySoundVolumes } = ToysSoundsVolumes.actions;
export default ToysSoundsVolumes.reducer;
