import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ToyInspectionManagementMethod } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<ToyInspectionManagementMethod>;
const initialState = getInitialState<ToyInspectionManagementMethod>();

const ToyInspectionManagementMethodsSlice = createSlice({
  name: "toyInspectionManagementMethod",
  initialState,
  reducers: {
    rehydrateToyInspectionManagementMethods: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined) {
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      }
      return state;
    },
  },
});

export const { rehydrateToyInspectionManagementMethods } =
  ToyInspectionManagementMethodsSlice.actions;

export default ToyInspectionManagementMethodsSlice.reducer;
