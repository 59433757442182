// Errors of Laravel
export const ErrorMessages: Record<string, string> = {
  // Errors of bad request
  E000000: ":attributeを承認してください。",
  E000001: ":attributeが有効なURLではありません。",
  E000002: ":attributeには、1900-01-01 より後の日付を指定してください。",
  E000003: ":attributeの日付が不正です。",
  E000004: ":attributeはアルファベットのみがご利用できます。",
  E000005:
    ":attributeはアルファベットとダッシュ(-)及び下線(_)がご利用できます。",
  E000006: ":attributeはアルファベット数字がご利用できます。",
  E000007: ":attributeは配列でなくてはなりません。",
  E000008: ":attributeの日付が不正です。",
  E000009: ":attributeの日付が不正です。",

  E000010: ":attributeの数字が大きすぎるか、または少なすぎます。",
  E000011: ":attributeのファイルサイズが大きすぎか、または少なすぎます。",
  E000012: ":attributeの文字数が多すぎか、または少なすぎます。。",
  E000013: ":attributeの数が多すぎか、または少なすぎます。",

  E000014: ":attributeは、trueかfalseを指定してください。",
  E000015: ":attributeと、確認フィールドとが、一致していません。",
  E000016: ":attributeには有効な日付を指定してください。",
  E000017: ":attributeが正しくありません。",
  E000018: ":attributeが正しくありません。",
  E000019: ":attributeが正しくありません。",
  E000020: ":attributeが正しくありません。",
  E000021: ":attributeが大きすぎるか、または小さすぎます。",
  E000022: ":attributeの図形サイズが正しくありません。",
  E000023: ":attributeには異なった値を指定してください。",
  E000024: ":attributeには、有効なメールアドレスを指定してください。",
  E000025: "選択された:attributeは正しくありません。",
  E000026: ":attributeにはファイルを指定してください。",
  E000027: ":attributeに値を指定してください。",

  E000028: ":attributeの数字が少なすぎます。",
  E000029: ":attributeのファイルサイズが少なすぎます。",
  E000030: ":attributeの文字数が少なすぎます。",
  E000031: ":attributeの選択している数が少なすぎます。",

  E000032: ":attributeの数字が大きすぎます。",
  E000033: ":attributeのファイルサイズが大きすぎます。",
  E000034: ":attributeの文字数が多すぎます。",
  E000035: ":attributeの数が多すぎます",

  E000037: ":attributeには画像ファイルを指定してください。",
  E000038: "選択された:attributeは正しくありません。",
  E000039: ":attributeは値を固定する必要があります",
  E000040: ":attributeは整数で指定してください。",
  E000041: ":attributeには、有効なIPアドレスを指定してください。",
  E000042: ":attributeには、有効なIPv4アドレスを指定してください。",
  E000043: ":attributeには、有効なIPv6アドレスを指定してください。",
  E000044: ":attributeには、有効なJSON文字列を指定してください。",

  E000045: ":attributeの数字が少なすぎます。",
  E000046: ":attributeのファイルサイズが少なすぎます。",
  E000047: ":attributeの文字数が少なすぎます。",
  E000048: ":attributeの選択している数が少なすぎます。",

  E000049: ":attributeの数字が大きすぎます。",
  E000050: ":attributeのファイルサイズが大きすぎます。",
  E000051: ":attributeの文字数が多すぎます。",
  E000052: ":attributeの数が多すぎます",

  E000053: ":attributeの数字が大きすぎます。",
  E000054: ":attributeのファイルサイズが大きすぎます。",
  E000055: ":attributeの文字数が多すぎます。",
  E000056: ":attributeの数が多すぎます",

  E000057: ":attributeには PNG, JPEG, GIF, SVG のファイルを指定してください。",
  E000058: ":attributeには PNG, JPEG, GIF, SVG のファイルを指定してください。",

  E000059: ":attributeの数字が少なすぎます。",
  E000060: ":attributeのファイルサイズが少なすぎます。",
  E000061: ":attributeの文字数が少なすぎます。",
  E000062: ":attributeの選択している数が少なすぎます。",

  E000063: "選択された:attributeは正しくありません。",
  E000064: ":attributeの形式が正しくありません。",
  E000065: ":attributeには、数字を指定してください。",
  E000066: ":attributeが存在していません。",
  E000067: ":attributeに正しい形式を指定してください。",
  E000068: ":attributeは必ず指定してください。",
  E000069: ":attributeを指定する必要があります。",
  E000070: ":attributeを指定する必要があります。",
  E000071: ":attributeを指定する必要があります。",
  E000072: ":attributeを指定する必要があります。",
  E000073: ":attributeを指定する必要があります。",
  E000074: ":attributeを指定する必要があります。",
  E000075: ":attributeの値が正しくありません。",

  E000076: ":attributeは指定のサイズである必要があります。",
  E000077: ":attributeのファイルは、指定のサイズでなくてはなりません。",
  E000078: ":attributeは指定文字数である必要があります。",
  E000079: ":attributeは指定の数量以上を指定する必要があります。",

  E000080: ":attributeは文字列を指定してください。",
  E000081: ":attributeには、有効なゾーンを指定してください。",
  E000082: ":attributeの値は既に存在しています。",
  E000083: ":attributeのアップロードに失敗しました。",
  E000084: ":attributeに正しい形式を指定してください。",
  E000085: ":attributeに有効なUUIDを指定してください。",

  // Errors of business logics
  E001000: "おもちゃの個体が不足しているため保存に失敗しました。",
  E001001:
    "現在のステータスから変更しようとしているステータスにはシステムの安全上変更できません。",
  E001002: "顧客情報に発送元倉庫情報が紐付いていないため、保存に失敗しました。",
  E001003:
    "期待する数量と実際の数量の差分にて削除できるおもちゃの個体がデータ上に存在しないため、棚卸しに失敗しました。",
  E001004:
    "到着したおもちゃの数量が購入したおもちゃの数量より大きいか、購入したおもちゃの数量または到着したおもちゃの数量が不正です。",
  E001005: "購入済みのおもちゃは既に全て受け取り済みです。",
  E001006:
    "送信されているデータが不正またはデータの整合性が保てていないため、仕入れ済みおもちゃの数量が一致しません。",
  E001007:
    "棚卸しする対象のおもちゃが多すぎるため、作成するデータを分割してください。",
  E001008: "発送先倉庫の選定に失敗しました。",
  E001009: "おもちゃの個体が不足しているため、この処理は許可されていません。",
  E001010:
    "発送おもちゃのステータスと取り扱い理由の組み合わせが不正なデータです。",
  E001011:
    "入庫済み個数がマイナスになってしまいます。検収個数をご確認ください。",
  E001012: "仕入れに紐づくおもちゃのため、削除できません。",

  E001800:
    "送信されたデータが不正または未対応であるため、正常に解析できませんでした。",

  E001900: "送信されているデータが正しくありません。",

  E001997: "保存に失敗しました。",
  E001998: "許可されていないパラメータを送信しています。",
  E001999: "データが存在しません。",

  E002000: "選択されたおもちゃ「:key」の在庫が不足しています。",
  E002001: "現在、事業所間移動の対象のため変更できません。",
  E002002: "既に出庫済みのため変更できません。",
  E002003:
    "既に入庫済みのため変更ができません。新しく事業所間の移動を実施してください。",
  E002004:
    "選択されたおもちゃ「:key」の実移動数が移動予定数より大きいため、保存に失敗しました。",
  E002005: "実移動数を指定されていないため、保存に失敗しました。",

  E002100: "契約に紐付いた発送が存在するため、削除できません。",
  E002101: "Madrasに登録されていないアカウントトークンが存在します",

  E002201: "売却完了日は本日以前の日付を指定してください。",
  E002202: "売却完了日が今月より前の日付のため、売却完了日を変更できません。",
  E002203: "取扱ステータスが売却済みでないため、売却完了日は指定できません。",
  E002204:
    "売却完了日が設定済みかつ今月より前のため、売却完了日を変更できません。",
  E002205: "廃棄日は必須です。",
  E002206: "廃棄日は本日以前の日付を指定してください。",
  E002207: "廃棄日が設定済みかつ今月より前のため、廃棄日を変更できません。",
  E002208: "廃棄日が今月より前の日付のため、廃棄日を変更できません。",
  E002209: "取扱ステータスが廃棄でないため、廃棄日は指定できません。",
  E002210: "売却連絡日または売却完了日は必須です。",
  E002211: "取扱理由は必須です。",
  E002212: "売却完了日は必須です。",
  E002213: "売却済みおもちゃのステータスを変更することはできません。",
  E002214: "廃棄おもちゃのステータスを変更することはできません。",
  E002215: "取扱ステータスが「発送済み」でないため、売却済みに変更できません。",
  E002216: "売却連絡日を空に変更することはできません。",

  E002301: "最古の検収日が今月より前のため、為替レートと通貨は変更できません。",
  E002302: "最古の検収日が今月より前のため、マイナス検収できません。",

  E002402: "最古の検収日が今月より前のため、購入単価は変更できません。",

  E002500: "発送日変更不可のため、発送日は変更できません。",

  E002701:
    "棚卸月を過ぎているため、棚卸結果CSVがアップロードできませんでした。",
  E002702:
    "千葉事業所、および丸山事業所の運用中・非運用の棚卸CSV（計4ファイル）が出揃っていないため、0円仕入れを開始することができませんでした。",
  E002703:
    "ファイル名が不正です。ファイル名を「YYYY-MM-DD_{事業所名} - 【{非運用 or 運用中}】_出力用.csv」にしてください。",
  E002704:
    "0円仕入れを行う前に、仕入れ管理画面より棚卸結果CSVファイルをアップロードしてください。",
  E002705: "スタッフ名、事業所名、個体ステータスのいずれかの情報が不正です。",

  // Errors of others
  E999999: "原因不明なエラーです。以下を開発部にお伝え下さい。\n:backtrace",
};

export default ErrorMessages;
