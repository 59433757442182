import { replaceCurlyBrackets } from "./replaceCurlyBrackets";
import { type PathParams } from "./type";
import { composePath } from "./composePath";
import { removeTrailingSlash } from "../removeTrailingSlash";
import {
  type ApiPaths,
  type ApiPathsWithoutPathParam,
  type ApiPathsWithPathParam,
} from "@/api/types";

export function getPath<Path extends ApiPathsWithoutPathParam>(
  path: Path,
): string | null;
export function getPath<Path extends ApiPathsWithPathParam>(
  path: Path,
  paramsInPath: PathParams<Path>,
): string | null;
export function getPath<Path extends ApiPaths>(
  path: Path,
  paramsInPath?: PathParams<Path>,
): string | null {
  return composePath(path, paramsInPath);
}

/**
 * path parameter を含まない path 文字列を型から生成する。
 */
export function getPathWithoutPathParam<Path extends ApiPathsWithoutPathParam>(
  path: Path,
): Path {
  return path;
}

/**
 * OpenAPI で定義された path について、
 * path parameter の key が slug name ( curly brackets 内の placeholder 名 ) と一致する場合、
 * path parameter の value で { slug name } を置換する。
 * path parameter の value が空文字列であっても置換を行う。
 * @note
 * テストでの利用を想定
 */
export function getPathWithParam<Path extends ApiPathsWithPathParam>(
  path: Path,
  paramsInPath: PathParams<Path>,
): string {
  return removeTrailingSlash(
    Object.entries(paramsInPath as Record<string, string>).reduce(
      replaceCurlyBrackets,
      path,
    ),
  );
}
