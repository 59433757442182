import {
  type FC,
  type PropsWithChildren,
  createContext,
  useContext,
  useState,
  useMemo,
} from "react";
import { localStorageKeys } from "@/configs/localStorage";
import { localStorageAvailable } from "@/helper/local-storage";

const initialValue = false;
const _useExperimentalFeatureDisclosure = () => {
  const [experimentalFeature, setExperimentalFeature] = useState(() => {
    if (!localStorageAvailable()) {
      return initialValue;
    }
    const boolString =
      localStorage.getItem(localStorageKeys.experimentalFeatures) ??
      String(initialValue);
    return boolString === "true";
  });

  const toggleExperimentalFeature = () => {
    const setItem = !experimentalFeature;
    if (localStorageAvailable()) {
      localStorage.setItem(
        localStorageKeys.experimentalFeatures,
        String(setItem),
      );
    }

    setExperimentalFeature(setItem);
  };
  return { experimentalFeature, toggleExperimentalFeature } as const;
};

const ExperimentalFeatureContext = createContext<
  ReturnType<typeof _useExperimentalFeatureDisclosure>
  // biome-ignore lint/suspicious/noExplicitAny :[AUTOMATICALLY-INSERTED] Unexpected any. Specify a different type.
>({} as any);

export const useExperimentalFeatureDisclosure = () => {
  return useContext(ExperimentalFeatureContext);
};

export const ExperimentalFeatureToggleProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const experimentalfeature = _useExperimentalFeatureDisclosure();
  const memorizedExperimentalFeature = useMemo(
    () => experimentalfeature,
    [experimentalfeature],
  );
  return (
    <ExperimentalFeatureContext.Provider value={memorizedExperimentalFeature}>
      {children}
    </ExperimentalFeatureContext.Provider>
  );
};
