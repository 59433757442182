export const toyIndividualFilterLabels = {
  uuid: "UUID",
  depreciationMoney: "減価償却費",
  depreciationMonths: "減価償却日",
  disposedAt: "廃棄日",
  individualToyStatus: "個体のステータス",
  individualToyStatusReason: "個体のステータスの理由",
  noteOfDefect: "不備詳細",
  noteOfDisposition: "廃棄詳細",
  noteOfNonOperation: "非運用詳細",
  price: "購入金額",
  saleCompletionDate: {
    start: "売却完了日 (開始)",
    end: "売却完了日 (終了)",
  },
  saleContactDate: {
    start: "売却連絡日 (開始)",
    end: "売却連絡日 (終了)",
  },
  salePrice: "販売額",
  secondHandsPrice: "中古販売金額",
  shelf_Name: "棚名",
  shelf_Uuid: "棚管理の UUID",
  shipment_ChildInterestingOfReceived: "お子様の発育、好みや興味のあるもの",
  shipment_CommentOfReceived: "お客様からのその他、ご意見・ご感想",
  shipment_PlanComment: "プランコメント",
  shipment_PlannerMessage: "プランナーメッセージ",
  shipment_Uuid: "発送管理の UUID",
  toy_MakerToyUrl: "おもちゃのメーカー URL",
  toy_Description: "おもちゃの詳細",
  shipment_ShippingNumber: "伝票番号",
  shipment_PlanTitle: "プランタイトル",
  warehouse: "事業所・倉庫",
  toy_Accessories: "おもちゃの付属品",
  toy_Name: "おもちゃ名",
  toy_Instruction: "おもちゃの使用方法",
  toy_Uuid: "おもちゃ管理の UUID",
  toy_NoteOfPurchaseForCustomer: "顧客売却時メモ",
  stockToy_Uuid: "仕入れ管理の UUID",
  toyId: "おもちゃ ID",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  stockedAt: {
    start: "入庫日 (開始)",
    end: "入庫日 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
};
