// ja.ts になかったです
export const COLUMNS_LABELS_COMMENT_IMAGES = {
  id: "ID",
  uuid: "UUID",
  comment_id: "コメントID",
  mime_type: "MIMEの種類",
  original_name: "元の名前",
  size: "サイズ",
  name: "名前",
  path: "パス",
};
