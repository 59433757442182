import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type PlannerMessageCategory } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<PlannerMessageCategory>;
const initialState = getInitialState<PlannerMessageCategory>();

const PlannersMessagesCategoriesSlice = createSlice({
  name: "plannersMessagesCategories",
  initialState,
  reducers: {
    rehydratePlannersMessagesCategories: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydratePlannersMessagesCategories } =
  PlannersMessagesCategoriesSlice.actions;
export default PlannersMessagesCategoriesSlice.reducer;
