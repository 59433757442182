import { type OptionForVariableQuery, type UnsafeOption } from "../types";
import {
  createSortQueries,
  createValueFilterQuery,
  createUuidFilterQuery,
  createUuidsFilterQuery,
  createDatePeriodFilterQuery,
} from "@/api/swr/helpers";
import { MAX_LIMIT } from "@/features/helper/allFetchable";
import { isFalsyString } from "@/helper/string";

export const optionsToQuery = (
  option?: OptionForVariableQuery & UnsafeOption,
) => {
  if (option === undefined) return {};
  const {
    unsafeQuery,
    sort,
    valueFilter,
    uuidFilter,
    uuidsFilter,
    datePeriodFilter,
    keyword,
    maxLimit,
    ..._rest
  } = option;
  return {
    ...unsafeQuery,
    ...createSortQueries(sort),
    ...createValueFilterQuery(valueFilter),
    ...createUuidFilterQuery(uuidFilter),
    ...createUuidsFilterQuery(uuidsFilter),
    ...createDatePeriodFilterQuery(datePeriodFilter),
    ...(isFalsyString(keyword) ? {} : { keyword }),
    ...(maxLimit === true ? { limit: MAX_LIMIT } : {}),
  };
};
