// ja.ts になかったです
export const COLUMNS_LABELS_DEPOSITS = {
  id: "ID",
  uuid: "UUID",
  family_customer_id: "契約者のID",
  family_customer_contracted_plan_id: "契約プランのID",
  payment_type_id: "契約方法",
  status: "ステータス",
  price: "決済金額",
  total_price: "決済総額",
  tax: "税",
  response: "レスポンス",
};
