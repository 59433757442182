import { isNil as isNilRemeda } from "remeda";

type Predicate<T = any> = (x: T) => boolean;

// c.f. lodash.js implementation
export const isObject = (value: any): value is object => {
  const type = typeof value;
  return value != null && (type === "object" || type === "function");
};

export const isInteger = (num: string) => /^-?[0-9]+$/.test(String(num));

export const isBoolean = (x: any): x is boolean => typeof x === "boolean";

export const isNil = isNilRemeda;

type Constructor<T> = new (...args: any[]) => T;
/**
 * @see https://github.com/selfrefactor/rambda/blob/master/src/is.js
 * @example
 *   is(Object, {}); //=> true
 *   is(Number, 1); //=> true
 *   is(Object, 1); //=> false
 *   is(String, 's'); //=> true
 *   is(String, new String('')); //=> true
 *   is(Object, new String('')); //=> true
 *   is(Object, 's'); //=> false
 *   is(Number, {}); //=> false
 */
export const is = <T>(targetPrototype: Constructor<T>, x: any): x is T =>
  (x != null && x.constructor === targetPrototype) ||
  x instanceof targetPrototype;

const executeWhen =
  (bool = false) =>
  <T>(predicate: Predicate<T>, executeFn: (x: T) => void): Predicate<T> =>
  (xx) => {
    const result = predicate(xx);
    if (result === bool) {
      executeFn(xx);
    }
    return result;
  };

/**
 * @param predicate 任意の引数を取り、 boolean を返却する関数
 * @param executeFn 引数の `predicate` の結果が false の場合に実行する関数
 * @returns 任意の引数を取り、 boolean を返却する関数
 */
export const executeWhenFalse = executeWhen(false);
