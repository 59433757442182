// ja.ts になかったです
export const COLUMNS_LABELS_TOY_SUPPLIER_RELATIONS = {
  id: "ID",
  uuid: "UUID",
  toy_id: "おもちゃのID",
  toy_supplier_id: "おもちゃの仕入先",
  toy_url: "おもちゃのURL",
  purchase_price: "仕入れ金額",
  purchase_note: "仕入れメモ",
  condition_of_shipment: "発送状態",
  condition_of_shipment_fee: "送料",
};
