import { type ControllerProps } from "react-hook-form";

export { isBoolean } from "@/helper/predicate";

export type ValidationRule = {
  [key: string]: ControllerProps["rules"];
};

export const Rule = {
  string: 255,
  text: 65535,
  number: /[0-9]/,
  tel: /^\d{2}-\d{4,}-\d{4,}$|^\d{3}-\d{3,}-\d{4,}$|^\d{4}-\d{2,}-\d{4,}$|^\d{5}-\d{1,}-\d{4,}$|^\d{10,16}$/,
  zip_code: /^\d{7}$/,
  url: /https?:\/\/.+/,
  naturalNumber: /^[1-9][0-9]*$/,
};
