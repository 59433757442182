import { type ErrorInfo } from "react";
import { MadrasAPIResponseError } from "@/api/errors";
import { logger } from "@/helper/logger";

export const sendLogOnFallback = async (
  error: unknown,
  { componentStack }: ErrorInfo,
) => {
  if (error instanceof MadrasAPIResponseError) {
    // api client側でログを送信しているので、ここでは何もしない
  } else if (error instanceof Error) {
    logger.error(
      `${error.name}: ${error.message}`,
      {
        error: {
          stack_trace: error.stack,
          name: error.name,
          message: error.message,
          cause: JSON.stringify(error.cause),
        },
        component_stack: componentStack,
      },
      error,
    );
  } else {
    logger.error(`unknown error: ${JSON.stringify(error, null, 2)}`, {
      error: JSON.parse(JSON.stringify(error)),
      component_stack: componentStack,
    });
  }
};
