import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RehydratePager, getInitialState } from "@/types/Pager";
import { type FamilyCustomerContractedPlan } from "@/openapi/api";

type PayloadType = RehydratePager<FamilyCustomerContractedPlan>;
const initialState = getInitialState<FamilyCustomerContractedPlan>();

const FamiliesCustomersContractedPlans = createSlice({
  name: "customersContractedPlans",
  initialState,
  reducers: {
    rehydrateFamiliesCustomersContractedPlans: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateFamiliesCustomersContractedPlans } =
  FamiliesCustomersContractedPlans.actions;

export default FamiliesCustomersContractedPlans.reducer;
