import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Describe } from "@/openapi/api";

export type ExtendedDescribe = Readonly<
  Pick<Describe, "data" | "endpoint" | "name">
>;

const initialState: ExtendedDescribe[] = [];

const DescribeSlice = createSlice({
  name: "describe",
  initialState,
  reducers: {
    rehydrateDescribe: (
      state,
      { payload }: PayloadAction<ExtendedDescribe[]>,
    ) => {
      return payload;
    },
  },
});

export const { rehydrateDescribe } = DescribeSlice.actions;
export default DescribeSlice.reducer;
