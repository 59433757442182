import { type SimpleObject } from "@/helper/object";

export const orderDict = {
  asc: "asc",
  desc: "desc",
} as const;
export type Order = keyof typeof orderDict;

type Comparator<T = any> = (a: T, b: T) => number;

export type Property2Comparator<T extends SimpleObject> = Partial<
  Record<keyof T, Comparator>
>;

export interface SortKeyAndOrder<T extends SimpleObject = SimpleObject> {
  order: Order;
  orderBy: keyof T;
}

export interface LooseSortKeyAndOrder {
  order: Order;
  orderBy: string;
}

export const isOrder = (order: unknown): order is Order =>
  order === orderDict.asc || order === orderDict.desc;

const reverseOrder = (order: Order): Order =>
  order === orderDict.asc ? orderDict.desc : orderDict.asc;

const changeOrdering = (ascendingOrdering: number, order: Order): number =>
  order === orderDict.asc ? ascendingOrdering : -ascendingOrdering;

export const followOrder =
  <T>(ascendingComparator: Comparator<T>, order: Order): Comparator<T> =>
  (a: T, b: T) => {
    return changeOrdering(ascendingComparator(a, b), order);
  };

export const getNextOrder = <T extends SimpleObject>(
  currentKeyAndOrder: SortKeyAndOrder<T> | undefined,
  nextOrderBy: keyof T,
): Order => {
  const isSortKeyChanged = nextOrderBy !== currentKeyAndOrder?.orderBy;

  const nextOrder =
    isSortKeyChanged || currentKeyAndOrder === undefined
      ? orderDict.asc
      : reverseOrder(currentKeyAndOrder?.order);

  return nextOrder;
};

export const getNextKeyAndOrder = <T extends SimpleObject>(
  currentKeyAndOrder: SortKeyAndOrder<T> | undefined,
  nextOrderBy: keyof T,
): SortKeyAndOrder<T> => {
  return {
    order: getNextOrder(currentKeyAndOrder, nextOrderBy),
    orderBy: nextOrderBy,
  };
};

export const isBlank = (x: unknown) => x === undefined || x === "";

const getEmptyItemOrdering = <T>(a: T, b: T) => {
  const [aIsBlank, bIsBlank] = [isBlank(a), isBlank(b)];
  if (aIsBlank && bIsBlank) return 0;
  if (aIsBlank && !bIsBlank) return 1;
  if (!aIsBlank && bIsBlank) return -1;
  throw new Error(`use this function when "isBlank(a) || isBlank(b)"`);
};

export const createDefaultComparator =
  (order: Order) =>
  <T>(a: T, b: T): number => {
    // blank value always come last.
    if (isBlank(a) || isBlank(b)) return getEmptyItemOrdering(a, b);

    const ascendingOrdering = (() => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    })();

    return changeOrdering(ascendingOrdering, order);
  };

export const sortWithKeyAndOrder = <T extends SimpleObject>(
  sortKeyAndOrder: SortKeyAndOrder<T> | undefined,
  ascendingComparators: Property2Comparator<T> | undefined,
  items: T[],
) => {
  if (sortKeyAndOrder === undefined) return items;
  const { order, orderBy: key } = sortKeyAndOrder;

  const ascendingComparator = ascendingComparators?.[key];
  const comparator =
    ascendingComparator === undefined
      ? createDefaultComparator(order)
      : followOrder(ascendingComparator, order);

  return [...items].sort((a, b) => comparator(a[key], b[key]));
};

const ascendingOrderOfTaskState = [
  "未対応",
  "処理中",
  "処理済み",
  "→変更待ち",
  "→課金調整",
  "→振込依頼",
  "完了",
];
export const ascComparatorOfTaskStatus = (a: string, b: string) =>
  ascendingOrderOfTaskState.indexOf(a) - ascendingOrderOfTaskState.indexOf(b);
