import { isTest } from "@/helper/env";

function getKeyInTest(key: string) {
  const state = expect.getState();
  const testName = state.currentTestName;
  return `${testName}:${key}`;
}

/**
 * Test 用の Set。
 * Test 毎に 呼び出し済みか判定するため用の key を区別する。
 */
export class SetInTest {
  private set: Set<string>;

  constructor() {
    this.set = new Set();
  }

  add(key: string) {
    this.set.add(getKeyInTest(key));
    return this;
  }

  has(key: string) {
    return this.set.has(getKeyInTest(key));
  }
}

const hasMutatedInTest = new SetInTest();

/**
 * mount 時に余計なリクエストが飛ばないようにしたい（revalidateOnMount = false）という文脈なので、
 * unmount 後も利用できるようにしたい。そのため、コンポーネント外に定義する。
 */
export const hasMutated = isTest() ? hasMutatedInTest : new Set<string>();
