import { isFalsyString } from "@/helper/string";

/**
 *
 * @example
 *
 * // now: /hoge/fuga/<uuid>
 * // output: /hoge/fuga/
 * const result = poppedPathUrl();
 *
 * // now: /hoge/fuga/<uuid>
 * // output: /hoge/
 * const result = poppedPathUrl(2);
 *
 */
export const poppedPathUrl = (
  popCount?: number,
  /**
   * 初期化するURLオブジェクトを外側から注入したい場合に指定してください
   */
  url?: URL,
): string => {
  const currentUrl = url ?? new URL(window.location.href);
  const { pathname } = currentUrl;
  const splittedPath = pathname.split("/");
  if (popCount === undefined || popCount === 0) {
    splittedPath.pop();
  } else {
    for (let i = 0; i < popCount; i += 1) {
      splittedPath.pop();
    }
  }
  const joinedUrl = splittedPath.join("/");
  return joinedUrl;
};

export const replacePathUrl = (
  replacePath:
    | string
    | {
        pathname: string;
        query: Record<string, string>;
      },
  popCount = 1,
): string => {
  const url = new URL(window.location.href);
  const poppedPath = poppedPathUrl(popCount, url);

  const joinPath = (path: string, concatPath: string): string =>
    `${path}${isFalsyString(concatPath) ? "" : `/${concatPath}`}`;

  if (typeof replacePath === "string") {
    return joinPath(poppedPath, replacePath);
  }

  url.pathname = joinPath(poppedPath, replacePath.pathname);
  const searchParams = new URLSearchParams(replacePath.query);
  url.search = searchParams.toString();

  return `${url.pathname}${url.search}`;
};
