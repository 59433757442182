/**
 * @see https://ramdajs.com/docs/#evolve
 * @example
 * ```
 *  const evolver = evolve({ a: Number, b: { c: String } });
 *  const source =         { a: "1", b: { c: 2   }, d: 3 };
 *  const received = evolver(source);
 *  const expected =       { a: 1,   b: { c: "2" }, d: 3 };
 *  expect(received).toEqual(expected);
 * ```
 */

export const evolve =
  (
    transformations: Record<string, Record<string, any> | ((prop: any) => any)>,
  ) =>
  (object: Record<string, any>) => {
    const result = {} as Record<string, any>;
    Object.keys(object).forEach((key) => {
      const transformation = transformations[key];
      result[key] = (() => {
        if (typeof transformation === "function") {
          return transformation(object[key]);
        }
        if (typeof transformation === "object") {
          return evolve(transformation)(object[key]);
        }
        return object[key];
      })();
    });
    return result;
  };
