import { MadrasAPIResponseError } from "@/api/errors";

export const getDisplayError = (
  error: unknown,
): {
  title: string;
  message: string;
  severity: "error" | "warning" | "info";
} => {
  if (error instanceof MadrasAPIResponseError) {
    const { message, severity, title } = error.getErrorForUser();
    return {
      title,
      message,
      severity: severity === "warn" ? "warning" : severity,
    };
  }
  if (error instanceof Error) {
    return {
      title: `エラー(${error.name})`,
      message: `意図しないエラーが発生しました。画面を更新し、再度お試しください\nエラー内容: ${error.message}`,
      severity: "error",
    };
  }
  return {
    title: "エラー(unknown)",
    message: `意図しないエラーが発生しました。画面を更新し、再度お試しください\nエラー内容:${JSON.stringify(
      error,
      null,
      2,
    )}`,
    severity: "error",
  };
};
