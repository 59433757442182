export const planFilterLabels = {
  uuid: "UUID",
  comment: "コメント",
  planTemplateCategory: "プランテンプレートカテゴリー",
  planTemplateCategory_Name: "プランテンプレートカテゴリー名",
  planTemplateCategory_Uuid: "プランテンプレートカテゴリーのUUID",

  elapsedTime: "経過時間",
  staff: "スタッフ",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },
  endAt: {
    start: "終了日時 (開始)",
    end: "終了日時 (終了)",
  },
  startAt: {
    start: "開始日時 (開始)",
    end: "開始日時 (終了)",
  },
  title: "プラン名",
};
