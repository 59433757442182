export const customerIdFilterLabels = {
  accountToken: "アカウントトークン",
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  minUsagePeriodEndDate: {
    start: "最低利用期間終了日 (開始)",
    end: "最低利用期間終了日 (終了)",
  },
  cancelledAt: {
    start: "退会日時 (開始)",
    end: "退会日時 (終了)",
  },
  contractedAt: { start: "契約日時 (開始)", end: "契約日時 (終了)" },
  familyChildren_FirstName: "子供の苗字",
  familyChildren_LastName: "子供の名前",
  familyChildren_LastNameOfPronunciation: "子供の苗字 (カナ)",
  familyChildren_FirstNameOfPronunciation: "子供の名前 (カナ)",
  familyChildren_NoteFromCustomer: "子供の発育興味",
  familyChildren_Uuid: "子供のUUID",
  familyCustomer_requestModificationChannel: "顧客の申し込みチャネル",
  familyCustomer_FirstName: "顧客の名前",
  familyCustomer_FirstNameOfPronunciation: "顧客の名前 (カナ)",
  familyCustomer_LastName: "顧客の苗字",
  familyCustomer_LastNameOfPronunciation: "顧客の苗字 (カナ)",
  familyCustomer_Mail: "顧客のメールアドレス",
  familyCustomer_Relationship: "顧客の続柄",
  familyCustomer_Tel: "顧客の電話番号",
  familyCustomer_Uuid: "顧客のUUID",
  familyCustomerBillingAddress_Address1: "顧客の請求先住所1",
  familyCustomerBillingAddress_Address2: "顧客の請求先住所2",
  familyCustomerBillingAddress_FirstName: "顧客の請求先住所の宛名（名前)",
  familyCustomerBillingAddress_LastName: "顧客の請求先住所の宛名（苗字)",
  familyCustomerBillingAddress_FirstNameOfPronunciation:
    "顧客の請求先住所の宛名（名前・カナ)",
  familyCustomerBillingAddress_LastNameOfPronunciation:
    "顧客の請求先住所の宛名（苗字・カナ)",
  familyCustomerBillingAddress_Pref: "顧客の請求先住所の都道府県",
  familyCustomerBillingAddress_Tel: "顧客の請求先住所の電話番号",
  familyCustomerBillingAddress_Uuid: "顧客の請求先住所のUUID",
  familyCustomerBillingAddress_ZipCode: "顧客の請求先住所の郵便番号",
  familyCustomerExternalService_ServiceCode:
    "顧客の外部サービスのサービスコード",
  familyCustomerExternalService_Uuid: "顧客の外部サービスのUUID",
  isActive: "アクティブ",
  paymentType: "支払い方法",
  subscription: "契約プラン名",
  updatedAt: { start: "更新日時 (開始)", end: "更新日時 (終了)" },
  uuid: "UUID",
  warehouse: "事業所",
};
