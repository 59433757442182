import { identity as identityRemeda } from "remeda";
/**
 * @see https://remedajs.com/docs#identity
 */
export const identity = identityRemeda;

export type MayReturnFalse<T> = (x: T) => T | false;

export const pipeDataOrFalse =
  <T>(...fns: MayReturnFalse<T>[]): MayReturnFalse<T> =>
  (x: T) =>
    fns.reduce<T | false>((acc, fn) => (acc === false ? false : fn(acc)), x);

export const noop = () => {
  /* no operation */
};
