import { type MaybeUuidObject, type ItemWithName } from "./types";

export const getItemByUuid = <T extends MaybeUuidObject>(
  items: T[],
  uuid: string,
): T | undefined => {
  return items.find((item) => item.uuid === uuid);
};

/**
 * 引数の uuid で item を特定できることを前提とする。
 */
export const unsafeGetItemByUuid = getItemByUuid as <T extends MaybeUuidObject>(
  items: T[],
  uuid: string,
) => T;

export const getItemByUuidFromObject = <T extends MaybeUuidObject>(
  obj: Record<string, T>,
  uuid: string,
): T | undefined => {
  return getItemByUuid(Object.values(obj), uuid);
};

export const getNameOfItemByUUID = <T extends ItemWithName, DefaultValue>(
  items: T[] | undefined,
  uuid: string | undefined,
  defaultTo?: DefaultValue,
): DefaultValue extends string ? string : undefined => {
  type Ret = DefaultValue extends string ? string : undefined;
  if (
    items === undefined ||
    items.length === 0 ||
    uuid === undefined ||
    uuid === ""
  ) {
    return defaultTo as Ret;
  }
  return (getItemByUuid(items, uuid)?.name ?? defaultTo) as Ret;
};

export const getNames = <T extends ItemWithName>(
  items: T[] | undefined,
  defaultTo = "",
): string[] => {
  return (items ?? []).map((x) => x.name ?? defaultTo);
};
