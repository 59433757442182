import { isEmpty, isObject } from "remeda";
import { extractErrorMessage } from "./extract-error-code";
import { type Translate, type APIErrorType } from "./types";
import { type logger } from "@/helper/logger";

const defaultErrorMessage = (
  startMessage: string,
  errResponse: unknown | undefined,
) => {
  if (isObject(errResponse) && !isEmpty(errResponse)) {
    return `${startMessage}\n${JSON.stringify(errResponse, null, 2)}`;
  }
  return startMessage;
};
/**
 * fetchやaxiosなど、apiClient間によってerrorの種別の判定方法は異なるので
 * 文言のみを定義し、各apiClientでこの関数を呼び出す
 */
export const userErrorMessage = {
  abort: "通信が中断されました",
  network: "通信エラーが発生しました",
  timeout: "タイムアウトが発生しました",
  unauthorized: "認証に失敗しました",
  forbidden: "権限がありません",
  internal: (errResponse: unknown, translate?: Translate): string =>
    extractErrorMessage(errResponse, translate) ??
    defaultErrorMessage("サーバー側に問題が発生しました", errResponse),

  notfound: (errResponse: unknown, translate?: Translate): string =>
    extractErrorMessage(errResponse, translate) ??
    defaultErrorMessage(
      "存在しないページにアクセスしようとしました。urlを確認してください。",
      undefined,
    ),

  badRequest: (errResponse: unknown, translate?: Translate): string =>
    // 本当は入力が不正である旨のメッセージを表示したいが、既存の仕様を踏襲して文言は変えていない
    extractErrorMessage(errResponse, translate) ??
    defaultErrorMessage("サーバー側に問題が発生しました", errResponse),

  businessLogic: (errResponse: unknown, translate?: Translate): string =>
    extractErrorMessage(errResponse, translate) ??
    defaultErrorMessage("サーバー側に問題が発生しました", errResponse),

  deadLogic: "意図しないエラーデータを取得しました",
} as const;

export const userErrorTitle: Record<APIErrorType, string> = {
  abort: "通信切断",
  network: "ネットワークエラー",
  timeout: "タイムアウト",
  unauthorized: "認証エラー",
  forbidden: "権限エラー",
  internal: "サーバーエラー",
  notfound: "データが見つかりません",
  badRequest: "サーバーエラー",
  businessLogic: "ビジネスロジックエラー",
  deadLogic: "意図しないエラー",
};

export const userErrorSeverity: Record<APIErrorType, keyof typeof logger> = {
  abort: "warn",
  network: "warn",
  timeout: "warn",
  unauthorized: "warn",
  forbidden: "error",
  internal: "error",
  notfound: "warn",
  badRequest: "error",
  businessLogic: "info",
  deadLogic: "error",
};
