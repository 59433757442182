import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type IndividualToyStatusReason } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<IndividualToyStatusReason>;
const initialState = getInitialState<IndividualToyStatusReason>();

const IndividualsToysStatusesReasonsSlice = createSlice({
  name: "individualsToysStatusesReasons",
  initialState,
  reducers: {
    rehydrateIndividualsToysStatusesReasons: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateIndividualsToysStatusesReasons } =
  IndividualsToysStatusesReasonsSlice.actions;
export default IndividualsToysStatusesReasonsSlice.reducer;
