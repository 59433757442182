import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ToySupplierStaff } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<ToySupplierStaff>;
const initialState = getInitialState<ToySupplierStaff>();

const ToysSuppliersStaffSlice = createSlice({
  name: "toysSuppliersStaff",
  initialState,
  reducers: {
    rehydrateToysSuppliersStaff: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateToysSuppliersStaff } = ToysSuppliersStaffSlice.actions;
export default ToysSuppliersStaffSlice.reducer;
