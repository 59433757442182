import { AxiosError } from "axios";

export const isAxiosError = (error: unknown): error is AxiosError =>
  error instanceof Error;
export const isAxiosAborted = (error: AxiosError) => {
  return (
    error.code === AxiosError.ECONNABORTED &&
    error.message === "Request aborted"
  );
};

export const isAxiosNetWorkError = (error: AxiosError) => {
  return error.code === AxiosError.ERR_NETWORK;
};

export const isAxiosTimeout = (error: AxiosError) => {
  return (
    error.code === AxiosError.ECONNABORTED &&
    typeof error.message === "string" &&
    error.message.includes("timeout")
  );
};
