import { type StaffMe } from "@/api/generated/openapi-typescript/apiv1/schemas/camel";
import { ddLogs, ddRum } from "@/lib/datadog/datadog";

export const addDDAllowPrivacy = (ddAllowPrivacy: boolean) =>
  ddAllowPrivacy ? { "data-dd-privacy": "allow" } : {};

const MADRAS_STAFF_PROPERTY_FOR_DATADOG = "madras_staff";
export const setStaffToDatadogContext = (staff: StaffMe) => {
  const staffContext = {
    uuid: staff?.uuid,
    name: staff?.name,
    permission: staff?.permission,
  };
  ddLogs((datadogLogs) => {
    const exists = datadogLogs.getGlobalContext();
    if (MADRAS_STAFF_PROPERTY_FOR_DATADOG in exists) return;
    datadogLogs.setGlobalContextProperty(
      MADRAS_STAFF_PROPERTY_FOR_DATADOG,
      staffContext,
    );
  });
  ddRum((datadogRum) => {
    const exists = datadogRum.getRumGlobalContext();
    if (MADRAS_STAFF_PROPERTY_FOR_DATADOG in exists) return;
    datadogRum.addRumGlobalContext(
      MADRAS_STAFF_PROPERTY_FOR_DATADOG,
      staffContext,
    );
  });
};

export const removeStaffToDatadogContext = () => {
  ddLogs((datadogLogs) => {
    const exists = datadogLogs.getGlobalContext();
    if (MADRAS_STAFF_PROPERTY_FOR_DATADOG in exists) return;
    datadogLogs.removeGlobalContextProperty(MADRAS_STAFF_PROPERTY_FOR_DATADOG);
  });
  ddRum((datadogRum) => {
    const exists = datadogRum.getRumGlobalContext();
    if (MADRAS_STAFF_PROPERTY_FOR_DATADOG in exists) return;
    datadogRum.removeRumGlobalContext(MADRAS_STAFF_PROPERTY_FOR_DATADOG);
  });
};
