import { type SWRResponse } from "swr";
import {
  removeStaffToDatadogContext,
  setStaffToDatadogContext,
} from "@/helper/datadog";
import { useMadrasAPI } from "@/api/hooks";
import { apiClient } from "@/api/client";
import { useSnackbar } from "@/lib/notistack";
import { type Staff } from "@/api/generated/openapi-typescript/apiv1/schemas/camel";

const isSignIn = ({ isLoading, error, data }: SWRResponse<Staff>) =>
  isLoading === false && error === undefined && data !== undefined;
export const useSignIn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const responses = useMadrasAPI("/api/v1/staff/me", {
    swrOptions: {
      revalidateOnMount: false,
      onSuccess: (response) => {
        setStaffToDatadogContext(response);
      },
      onError: () => {
        removeStaffToDatadogContext();
      },
      shouldRetryOnError: false,
    },
    immutable: true,
    unsafeQuery: {
      "fields[]": ["*", "warehouse.uuid", "warehouse.name"],
    },
  });
  const { data, isLoading, mutate: signInMutate, error } = responses;
  const logout = async () => {
    await apiClient({
      path: "/api/v2/logout",
      method: "post",
    })
      .then(() => {
        enqueueSnackbar("ログアウトに成功しました", { variant: "success" });
        removeStaffToDatadogContext();
      })
      .catch(() => {
        enqueueSnackbar("ログアウトに失敗しました", { variant: "error" });
      });
    await signInMutate(undefined, { revalidate: false });
  };

  return {
    me: data,
    isSignIn: isSignIn(responses),
    isLoading,
    logout,
  };
};
