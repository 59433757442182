import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type RequiredBatteryType } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<RequiredBatteryType>;
const initialState = getInitialState<RequiredBatteryType>();

const RequiredBatteryTypesSlice = createSlice({
  name: "requiredBatteryTypes",
  initialState,
  reducers: {
    rehydratedRequiredBatteryTypes: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydratedRequiredBatteryTypes } =
  RequiredBatteryTypesSlice.actions;
export default RequiredBatteryTypesSlice.reducer;
