import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type PlanSheet } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<PlanSheet>;
const initialState = getInitialState<PlanSheet>();

const rehydratePlanSheetsSlice = createSlice({
  name: "planSheets",
  initialState,
  reducers: {
    rehydratePlanSheets: (state, { payload }: PayloadAction<PayloadType>) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydratePlanSheets } = rehydratePlanSheetsSlice.actions;
export default rehydratePlanSheetsSlice.reducer;
