import {
  Controller,
  type FieldPathValue,
  type FieldValues,
  type Path,
} from "react-hook-form";
import { type SyntheticEvent } from "react";
import {
  type AutocompleteChangeDetails,
  type AutocompleteChangeReason,
} from "@mui/material";
import { Autocomplete, type AutocompleteProps } from "./Autocomplete";
import { type OptionValue } from "./types";
import { comboboxDefaultProps } from "./constants";
import { type TextFieldProps } from "../textfield";
import { type RhfControllerForm } from "@/types/react-hook-form";
import { type DatadogAttributes } from "@/types/datadog";

/**
 * @note
 * mui の Autocomplete では options の型が `options: ReadonlyArray<T>;` となるようになっており、
 * node_modules/@mui/base/useAutocomplete/useAutocomplete.d.ts の UseAutocompleteProps 型の第一型引数 T が
 * 配列になっていると、options は T[][] となってしまう。そのため、 T は配列であってはならない。
 * 一方、RHF は `FieldPathValue<TFieldValues, TName>` で対象フィールドの型を取り出すが、取り出す対象のフィールドの方が配列であると
 * 上記第一型引数 T が配列となってしまう。
 * このジレンマを解消するために型に調整を加える。
 */
type GetOptionType<
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TOption extends OptionValue,
> = TOption extends never
  ? // Option の方が Autocomplete 側の Generic で確定しない場合、TFieldValues と TName から Option の型を取り出す。
    FieldPathValue<TFieldValues, TName> extends
      | (infer T extends OptionValue)[]
      | undefined
    ? T
    : never
  : // Option の方が Autocomplete 側の Generic で確定する場合
    TOption;

export type MultipleRhfComboboxProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
  TOption extends OptionValue = never,
> = {
  textFieldProps?: TextFieldProps;
  required?: TextFieldProps["required"];
  defaultValue?: FieldPathValue<TFieldValues, TName>;
  onChange?: (
    event: SyntheticEvent,
    value: GetOptionType<TFieldValues, TName, TOption>[],
    reason: AutocompleteChangeReason,
    rfhOnChange: (v: GetOptionType<TFieldValues, TName, TOption>[]) => void,
    details?: AutocompleteChangeDetails<
      GetOptionType<TFieldValues, TName, TOption>
    >,
  ) => void;
} & Omit<
  AutocompleteProps<
    GetOptionType<TFieldValues, TName, TOption>,
    true,
    false,
    false
  >,
  "defaultValue" | "onChange" | "multiple"
> &
  RhfControllerForm<TFieldValues, TName> &
  DatadogAttributes;

export const MultipleRhfCombobox = <
  TFieldValues extends FieldValues,
  TName extends Path<TFieldValues>,
  TOption extends OptionValue,
>({
  control,
  name,
  rules,
  defaultValue,
  onChange,
  textFieldProps,
  required,
  ddAllowPrivacy,
  ...props
}: MultipleRhfComboboxProps<TFieldValues, TName, TOption>) => {
  const mergeRules =
    rules?.required === undefined && required !== undefined
      ? { ...rules, required }
      : rules;

  return (
    <Controller<TFieldValues, TName>
      name={name}
      control={control}
      rules={mergeRules}
      defaultValue={defaultValue}
      render={({
        field: { ref, onChange: rhfOnChange, ...fields },
        fieldState,
      }) => {
        return (
          <Autocomplete<
            GetOptionType<TFieldValues, TName, TOption>,
            true,
            false,
            false
          >
            {...fields}
            {...comboboxDefaultProps}
            onChange={(e, v, r, d) => {
              if (onChange !== undefined) {
                onChange(e, v, r, rhfOnChange, d);
              } else {
                rhfOnChange(v);
              }
            }}
            multiple
            freeSolo={false}
            isOptionEqualToValue={(o, v) => o?.uuid === v?.uuid}
            textFieldProps={{
              helperText: fieldState?.error?.message,
              error: fieldState?.error !== undefined,
              required,
              inputRef: ref,
              ddAllowPrivacy,
              ...textFieldProps,
            }}
            ddAllowPrivacy={ddAllowPrivacy}
            {...props}
          />
        );
      }}
    />
  );
};
