import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ShipmentToyCustomerReceiveStatus } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<ShipmentToyCustomerReceiveStatus>;
const initialState = getInitialState<ShipmentToyCustomerReceiveStatus>();

const ShipmentToyCustomerReceiveStatusesSlice = createSlice({
  name: "shipmentsTypes",
  initialState,
  reducers: {
    rehydrateShipmentToyCustomerReceiveStatuses: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateShipmentToyCustomerReceiveStatuses } =
  ShipmentToyCustomerReceiveStatusesSlice.actions;
export default ShipmentToyCustomerReceiveStatusesSlice.reducer;
