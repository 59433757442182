import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface SearchParam {
  readonly queryString?: URLSearchParams;
}
const initialState: SearchParam = {
  queryString: new URLSearchParams(),
};

const SearchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    rehydrateSearch: (state, { payload }: PayloadAction<SearchParam>) => {
      // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
      state.queryString =
        payload.queryString || state.queryString || new URLSearchParams();
    },
    resetSearch: (state, { payload }: PayloadAction<void>) => {
      // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
      state.queryString = new URLSearchParams();
    },
  },
});

export const { rehydrateSearch, resetSearch } = SearchSlice.actions;
export default SearchSlice.reducer;
