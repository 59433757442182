import { type ErrorResponseData, isErrorResponseData } from "../error-response";

export const getCode = (
  responseData: ErrorResponseData,
): string[] | undefined => {
  return responseData?.details?.code;
};

export const getCodes = (
  responseData: ErrorResponseData,
):
  | {
      [key: string]: string[];
    }
  | undefined => {
  return responseData?.details?.codes;
};

export const getCodesOrCodeArray = (
  responseData: unknown,
): string[] | undefined => {
  if (!isErrorResponseData(responseData)) {
    return undefined;
  }

  const code = getCode(responseData);
  if (code !== undefined) {
    return code;
  }

  const codes = getCodes(responseData);

  if (codes !== undefined) {
    // Record<string, Array<string>>のようになっていて、Array部分にErrorCodeが格納されているので取り出して、1つの配列にする
    return Object.values(codes).flat();
  }

  return undefined;
};
