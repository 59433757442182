import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { getInitialState, type RehydratePager } from "@/types/Pager";
import { type Comment } from "@/openapi/api";

type PayloadType = RehydratePager<Comment>;
const initialState = getInitialState<Comment>();

const CommentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    rehydrateComments: (state, { payload }: PayloadAction<PayloadType>) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateComments } = CommentsSlice.actions;
export default CommentsSlice.reducer;
