import { type Cache } from "swr";
import { identity } from "remeda";
import { type AxiosResponse } from "axios";
import { type FieldValues } from "react-hook-form";
import {
  type OptionalArgsForSaveHandle,
  type DetailUrl,
  type PutRequest,
} from "./types";
import { getAuditLogsUrl } from "@/components/fragments/audit-logs/audit-logs-dialog/helpers";
import { type AddType } from "@/types/helpers";

export type CreatePutHandlerArgs<
  TFieldValues extends FieldValues = any,
  Options extends { body?: any } = any,
> = /** 引数渡し防止のため put は必須とする。
 * 一方で、undefined を許容しないと、undefined のハンドリングを呼び出し元で常にしないといけないので、許容する。
 * put が undefined の場合は、 返却される値を undefined として、後続のロジックにハンドリングを委譲する。
 */
AddType<PutRequest<Options>, undefined> &
  /**
   * @note
   * 渡し忘れると audit logs が更新されないので、渡し忘れ防止のため、必須とする。
   * 一方で、 undefined を許容して audit logs を利用しない選択肢を用意する。
   */
  AddType<DetailUrl, undefined> & {
    /**
     * @note
     * 渡し忘れると audit logs が更新されないので、渡し忘れ防止のため、必須とする。
     * 一方で、 undefined を許容して audit logs を利用しない選択肢を用意する。
     */
    cache: Cache | undefined;
  } & OptionalArgsForSaveHandle<TFieldValues>;
/**
 * put request 実行時のハンドリングを定義する。
 */
export const createPutHandler = ({
  put,
  detailUrl,
  cache,
  convertRequestBody = identity,
  onResponse,
  onError,
}: CreatePutHandlerArgs) => {
  if (put === undefined) {
    return undefined;
  }
  return async (data: FieldValues) => {
    return put({ body: convertRequestBody(data) })
      .then(async (res: AxiosResponse) => {
        // swr のキャッシュをクリアし、 audit log を再取得する
        if (cache !== undefined && detailUrl !== undefined) {
          cache.delete(getAuditLogsUrl(detailUrl));
        }
        await onResponse?.(res.data);
      })
      .catch((error: unknown) => {
        onError?.(error);
        throw error;
      });
  };
};
