import {
  type PlannableToy,
  type PlanShipmentShipmentToy,
} from "@/api/generated/openapi-typescript/apiv1/schemas/camel";

export const findStocks = (
  plannableToy: PlannableToy | PlanShipmentShipmentToy["toy"],
  warehouseUuid: string | undefined,
) => {
  const availableStock =
    plannableToy?.availableStocks?.find(
      (as) => as.warehouse.uuid === warehouseUuid,
    )?.stocks ?? 0;

  return availableStock;
};
