import { forwardRef, type Ref, useMemo } from "react";
import {
  TextField as MuiTextField,
  type TextFieldProps as MuiTextFieldProps,
} from "@mui/material";
import { type DatadogAttributes } from "@/types/datadog";
import { addDDAllowPrivacy } from "@/helper/datadog";
import { isPickableInput } from "@/components/essentials/helpers";

export type TextFieldProps = MuiTextFieldProps & {
  dataTestId?: string;
} & DatadogAttributes;

export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  (
    {
      type,
      InputLabelProps,
      inputProps,
      dataTestId,
      ddAllowPrivacy,
      onFocus,
      ...props
    },
    ref: Ref<HTMLDivElement>,
  ) => {
    const defaultLabelProps = useMemo(() => {
      return type === "date" || type === "number" || type === "datetime-local"
        ? { shrink: true }
        : undefined;
    }, [type]);

    return (
      <MuiTextField
        variant="standard"
        fullWidth
        ref={ref}
        type={type}
        InputLabelProps={{ ...defaultLabelProps, ...InputLabelProps }}
        inputProps={{
          ...inputProps,
          "data-testid": dataTestId,
        }}
        onFocus={(e) => {
          if (isPickableInput(type)) {
            const r = e.target as HTMLInputElement;
            r.showPicker();
          }
          onFocus?.(e);
        }}
        {...addDDAllowPrivacy(ddAllowPrivacy)}
        {...props}
      />
    );
  },
);

TextField.displayName = "TextField";
