/**
 * formData の key と value に対してそれぞれ、keyFn, valueFn を適用する。
 * ただし、valueFn は File type には適用されない。
 */
export const createFormDataWith = (
  keyFn: (key: string) => string,
  valueFn: (value: any) => any,
) => {
  const applyFnToString = (value: FormDataEntryValue) => {
    if (typeof value !== "string") return value; // File
    return valueFn(value);
  };

  return (formData: FormData): FormData => {
    return [...formData].reduce((acc, [key, value]) => {
      acc.append(keyFn?.(key) ?? key, applyFnToString(value));
      return acc;
    }, new FormData());
  };
};

// c.f. https://char0n.github.io/ramda-adjunct/2.35.0/RA.html#.renameKeysWith
export const renameKeysWith = (
  fn: (key: string) => string,
  formData: FormData,
): FormData => {
  return createFormDataWith(fn, (x) => x)(formData);
};

export const arrayFormDataToObj = (
  arrFormData: Array<[string, FormDataEntryValue]>,
): object => {
  return arrFormData.reduce((acc, f) => ({ ...acc, [f[0]]: f[1] }), {});
};
/**
 * formDataをobject形式で表示できるようにします
 * @example
 *  input:
 *   const object = {
 *     a: "a",
 *     b: {
 *      c: "c",
 *        d: {
 *          e: "e",
 *          f: 1,
 *          d: undefined,
 *        },
 *      },
 *      arr: [
 *        {
 *          aa: "aa",
 *          bb: [1, 23, 4],
 *          cc: true
 *        },
 *      ],
 *    };
 *
 *  output:
 *   "arr[0][aa]": "aa",
 *   "arr[0][bb][0]": "1",
 *   "arr[0][bb][1]": "23",
 *   "arr[0][bb][2]": "4",
 *   "arr[0][cc]": "1",
 *   "b[c]": "c",
 *   "b[d][e]": "e",
 *   "b[d][f]": "1",
 */
export const debugFormData = (formData: unknown): object => {
  if (!(formData instanceof FormData)) {
    return {};
  }
  return arrayFormDataToObj(Array.from(formData.entries()));
};
