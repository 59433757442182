import { isObject } from "remeda";
import { type ErrorDetail } from "@/api/generated/openapi-typescript/apiv1/schemas/camel";

export type Details = ErrorDetail;
/**
 * MADRAS Error response
 * @note
 * "@/api/generated/openapi-typescript/schemas/camel" に `Error` も存在するが現在別の型も混在している。
 */
export interface ErrorResponseData {
  details: Details;
}

export const isErrorResponseData = (
  responseData: unknown,
): responseData is ErrorResponseData => {
  if (!isObject(responseData)) {
    return false;
  }

  return Object.hasOwn(responseData, "details");
};
