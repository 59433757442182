import { type FieldValues, type Path, get } from "react-hook-form";
import { useContext } from "react";
import { RhfCombobox, type RhfComboboxProps } from "./RhfCombobox";
import { type OptionValue } from "./types";
import RuleContext from "@/contexts/rule";
import { usePageConfigs } from "@/hooks";
import { normalizeField } from "@/helper/normalize";

/**
 * RhfCombobox の責務（react-hook-form と mui の結合）外の責務を担う。
 * @param props
 * @returns
 */
const RhfTextFieldWrapper = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
  DisableClearable extends boolean = false,
  TOption extends OptionValue = never,
>(
  props: RhfComboboxProps<TFieldValues, TName, DisableClearable, TOption>,
) => {
  const ruleContext = useContext(RuleContext);
  const configs = usePageConfigs();
  return (
    <RhfCombobox
      label={get(configs?.labels, props.name) ?? props.name}
      rules={ruleContext.rule?.[normalizeField(props.name)]}
      {...props}
    />
  );
};

export default RhfTextFieldWrapper;
