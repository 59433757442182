import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type Subscription } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<Subscription>;
const initialState = getInitialState<Subscription>();

const SubscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {
    rehydrateSubscriptions: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateSubscriptions } = SubscriptionsSlice.actions;
export default SubscriptionsSlice.reducer;
