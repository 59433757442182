import loading from "@/features/loading/LoadingSlice";
import searchFilter from "@/features/search/SearchFilterSlice";
import countries from "@/features/countries/CountriesSlice";
import warehouses from "@/features/warehouses/WarehousesSlice";
import warehousesTypes from "@/features/warehouses/WarehousesTypesSlice";
import shelves from "@/features/shelves/ShelvesSlice";
import shelfToys from "@/features/shelves/ShelfToysSlice";
import shelvesFromToyUuid from "@/features/toys/[uuid]/ShelvesFromToyUuidSlice";
import loadingFullScreen from "@/features/loading/LoadingFullScreenSlice";
import search from "@/features/search/SearchSlice";
import staff from "@/features/staff/StaffSlice";
import subscriptions from "@/features/subscriptions/SubscriptionsSlice";
import subscriptionsStatuses from "@/features/subscriptions/SubscriptionsStatusesSlice";
import changesCycles from "@/features/changes/ChangesCyclesSlice";
import paymentsCycles from "@/features/payments/PaymentsCyclesSlice";
import paymentsTypes from "@/features/payments/PaymentsTypesSlice";
import toysMakers from "@/features/toys/ToysMakersSlice";
import toysSuppliers from "@/features/toys/ToysSuppliersSlice";
import toysSuppliersStaff from "@/features/toys/ToysSuppliersStaffSlice";
import toysStatuses from "@/features/toys/ToysStatusesSlice";
import toysCategories from "@/features/toys/ToysCategoriesSlice";
import toysCleaningDifficultyRates from "@/features/toys/ToysCleaningDifficultyRatesSlice";
import toys from "@/features/toys/ToysSlice";
import availableToys from "@/features/toys/AvailableToysSlice";
import commentsStatuses from "@/features/comments/CommentsStatusesSlice";
import comments from "@/features/comments/CommentsSlice";
import toysMaterials from "@/features/toys/ToysMaterialsSlice";
import toysSuppliersRelations from "@/features/toys/ToysSuppliersRelationsSlice";
import individualToy from "@/features/toys/IndividualToySlice";
import individualsToysStatuses from "@/features/toys/IndividualsToysStatusesSlice";
import individualsToysStatusesReasons from "@/features/toys/IndividualsToysStatusesReasonsSlice";
import deposits from "@/features/deposits/DepositSlice";
import deliveriesMethods from "@/features/deliveries/DeliveriesMethodsSlice";
import plansTemplates from "@/features/plans/PlansTemplatesSlice";
import plansTemplatesCategories from "@/features/plans/PlansTemplatesCategoriesSlice";
import inventories from "@/features/inventories/InventoriesSlice";
import stocks from "@/features/stocks/StocksSlice";
import stocksToys from "@/features/stocks/StocksToysSlice";
import toysStocks from "@/features/toys/ToysStocksSlice";
import familiesCustomers from "@/features/customers/FamiliesCustomersSlice";
import familiesCustomersTypes from "@/features/customers/FamilyCustomerTypeSlice";
import familiesCustomersStatuses from "@/features/customers/FamiliesCustomersStatusesSlice";
import familiesCustomersContractedPlans from "@/features/customers/FamiliesCustomersContractedPlansSlice";
import familiesCustomersDetailsSurveyLabels from "@/features/customers/FamiliesCustomersDetailsSurveysLabelsSlice";
import familiesHouseTypes from "@/features/customers/FamiliesHouseTypesSlice";
import shipments from "@/features/shipments/ShipmentsSlice";
import shipmentsStatuses from "@/features/shipments/ShipmentsStatusesSlice";
import shipmentToyCustomerReceiveStatuses from "@/features/shipments/ShipmentToyCustomerReceiveStatusesSlice";
import shipmentsTypes from "@/features/shipments/ShipmentsTypesSlice";
import shipmentToyStatuses from "@/features/shipments/ShipmentToyStatusesSlice";
import shipmentToyStatusesReasons from "@/features/shipments/ShipmentToyStatusesReasonsSlice";
import details from "@/features/details/DetailsSlice";
import deliveriesMethodsTimes from "@/features/deliveries/DeliveriesMethodsTimesSlice";
import toysProducesStatuses from "@/features/toys/ToysProducesStatusesSlice";
import familiesCustomersBillingsAddresses from "@/features/customers/FamiliesCustomersBillingsAddressesSlice";
import requiredBatteryTypes from "@/features/toys/RequiredBatteryTypesSlice";
import requiredBatterySizes from "@/features/toys/RequiredBatterySizesSlice";
import plannersMessages from "@/features/planner/PlannersMessagesSlice";
import plannersMessagesCategories from "@/features/planner/PlannersMessagesCategoriesSlice";
import packingMaterials from "@/features/toys/PackingMaterialsSlice";
import describe from "@/features/describes/DescribeSlice";
import expirationsDates from "@/features/expirations/ExpirationsDatesSlice";
import coupons from "@/features/coupons/CouponTypesSlice";
import couponStatuses from "@/features/coupons/CouponStatusesSlice";
import familyCustomerCoupons from "@/features/coupons/FamilyCustomerCouponsSlice";
import warehousesTransfers from "@/features/warehouses/WarehousesTransfersSlice";
import planSheets from "@/features/download/sheets/PlanSheetSlice";
import shipmentToyCleanings from "@/features/download/sheets/ShipmentToyCleaningSlice";
import pickSheets from "@/features/download/sheets/PickSheetSlice";
import toyInspectionManagementMethods from "@/features/toys/ToyInspectionManagementMethodsSlice";
import toysPartsDangersRates from "@/features/toys/ToysPartsDangersRatesSlice";
import toysPartsQuantitiesRates from "@/features/toys/ToysPartsQuantitiesRatesSlice";
import toysSoundsVolumes from "@/features/toys/ToysSoundsVolumes";

export default {
  loading,
  searchFilter,
  search,
  loadingFullScreen,
  shelves,
  shelfToys,
  shelvesFromToyUuid,
  warehouses,
  warehousesTypes,
  countries,
  staff,
  subscriptions,
  subscriptionsStatuses,
  changesCycles,
  paymentsCycles,
  paymentsTypes,
  toys,
  availableToys,
  toysSuppliers,
  toysMakers,
  toysSuppliersStaff,
  toysStatuses,
  toysCategories,
  toysCleaningDifficultyRates,
  comments,
  toysMaterials,
  toysSuppliersRelations,
  toysPartsDangersRates,
  toysPartsQuantitiesRates,
  toysSoundsVolumes,
  individualToy,
  individualsToysStatuses,
  individualsToysStatusesReasons,
  deposits,
  deliveriesMethods,
  plansTemplates,
  plansTemplatesCategories,
  inventories,
  stocks,
  stocksToys,
  toysStocks,
  familiesCustomers,
  familiesCustomersTypes,
  familiesCustomersStatuses,
  familiesCustomersContractedPlans,
  familiesCustomersDetailsSurveyLabels,
  familiesHouseTypes,
  shipments,
  shipmentsStatuses,
  shipmentToyStatusesReasons,
  shipmentToyCustomerReceiveStatuses,
  shipmentsTypes,
  shipmentToyStatuses,
  details,
  deliveriesMethodsTimes,
  toysProducesStatuses,
  commentsStatuses,
  familiesCustomersBillingsAddresses,
  requiredBatteryTypes,
  requiredBatterySizes,
  plannersMessages,
  plannersMessagesCategories,
  packingMaterials,
  describe,
  expirationsDates,
  coupons,
  couponStatuses,
  familyCustomerCoupons,
  warehousesTransfers,
  planSheets,
  shipmentToyCleanings,
  pickSheets,
  toyInspectionManagementMethods,
};
