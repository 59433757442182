import { type Translate } from "./types";
import { getCode, getCodes } from "./get-codes";
import {
  codeErrorMessage,
  codesErrorMessage,
} from "./_helpers/compose-error-message";
import { isErrorResponseData } from "../error-response";

/**
 * @note apps/apiのErrorスキーマを元に実装しています
 */
export const extractErrorMessage = (
  responseData: unknown | undefined,
  translate?: Translate,
): string | undefined => {
  if (!isErrorResponseData(responseData)) {
    return undefined;
  }

  /**
   * codeとmessageがある場合は、codesとmessagesは確認しなくて良いので早期リターンする
   */
  if (getCode(responseData) !== undefined) {
    return codeErrorMessage(responseData.details, translate);
  }

  if (getCodes(responseData) !== undefined) {
    return codesErrorMessage(responseData.details, translate);
  }

  return undefined;
};
