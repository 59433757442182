import { identity } from "remeda";
import { type Translate } from "../types";
import { camelizeString } from "@/helper/camelize";
import { ErrorMessages } from "@/configs/error-message";
import { type ErrorDetail } from "@/api/generated/openapi-typescript/apiv1/schemas/camel";

/**
 * @note
 * FIXME: `key.split(".")[0]` となっているのは歴史的経緯
 */
export const applyTranslate = (key: string, translate?: Translate) => {
  return translate?.(camelizeString(key.split(".")[0])) ?? key;
};

/**
 * key が code,value が message である ErrorMessages から
 * message を取り出し、取り出した message を編集する。
 */
export const makeErrorMessageWith =
  (codeToMessage: Record<string, string>) =>
  (
    code: string,
    {
      key = "",
      backtrace,
      translate = identity,
      defaultMessage = "エラーコードが未定義です",
    }: {
      key?: string;
      backtrace?: string;
      translate?: Translate;
      defaultMessage?: string;
    },
  ) => {
    return `${
      codeToMessage?.[code] !== undefined ? codeToMessage[code] : defaultMessage
    } (ErrorCode: ${code})`
      .replace(/:attribute/, applyTranslate(key, translate))
      .replace(/:key/, key)
      .replace(/:backtrace/, backtrace ?? "");
  };

export const makeErrorMessage = makeErrorMessageWith(ErrorMessages);

/**
 * ErrorDetail の `code`(array) からメッセージを作成する
 * @note
 * * codesErrorMessage関数との違いに注意
 */
export const codeErrorMessage = (
  errorDetails: Pick<ErrorDetail, "code" | "message" | "backtrace">,
  translate?: Translate,
) => {
  const { code, message, backtrace } = errorDetails;
  /**
   * code: ["E001000", "E001001"]のような形で渡ってくる。
   */
  const msgs = code?.map((c) =>
    makeErrorMessage(c, {
      backtrace,
      translate,
      defaultMessage: message?.join("\n") ?? "",
    }),
  );
  return msgs?.join("\n");
};

/**
 * ErrorDetail の `codes`(object) と `messages` からメッセージを作成する
 * @note
 * * ErrorDetail の messages は フォールバック的に利用されるのみであり、
 *   出力メッセージは原則として ErrorMessages に定義する
 */
export const codesErrorMessage = (
  errorDetails: Pick<ErrorDetail, "codes" | "messages" | "backtrace">,
  translate?: Translate,
) => {
  // codes、messagesの場合はバリデーションエラー発生時に帰ってくる
  // 参考: https://github.com/torana-us/madras/issues/691#issue-1573855585
  const { codes = {}, backtrace, messages = {} } = errorDetails;

  const msgs = Object.entries(codes).map(([key, errorMessageCodes]) => {
    // fieldNameのkeyはmessagesのkeyと1対1で必ず存在する
    const msg = messages[key];
    const mappingErrorMsg =
      errorMessageCodes
        ?.map((code) =>
          makeErrorMessage(code, {
            key,
            backtrace,
            translate,
            defaultMessage: msg?.join("、"),
          }),
        )
        ?.join("\n") ?? "";
    return mappingErrorMsg;
  });

  return msgs.join("\n");
};
