export const inspectionFilterLabels = {
  applyReturnAt: {
    start: "返却申請日 (開始)",
    end: "返却申請日 (終了)",
  },
  createdAt: {
    start: "登録日時 (開始)",
    end: "登録日時 (終了)",
  },
  updatedAt: {
    start: "更新日時 (開始)",
    end: "更新日時 (終了)",
  },

  expiresAt: {
    start: "発送期限日 (開始)",
    end: "発送期限日 (終了)",
  },
  shippedAt: {
    start: "発送日 (開始)",
    end: "発送日 (終了)",
    name: "発送日",
  },
  specifiedDeliveryDate: {
    start: "配達指定日 (開始)",
    end: "配達指定日 (終了)",
  },
  nextAvailableShippingDate: {
    start: "次回発送可能日 (開始)",
    end: "次回発送可能日 (終了)",
  },
  returnedAt: {
    start: "返却日　(開始)",
    end: "返却日　(終了)",
  },

  entry: "記事",
  childInterestingOfReceived: "子供の発育・興味",
  deliveryMethod_Uuid: "配送方法のUUID",
  deliveryMethodTime_Uuid: "配達希望時間のUUID",
  commentOfReceived: "その他・ご意見・ご感想",
  familyCustomerAddress_Address1: "顧客住所1",
  familyCustomerAddress_Address2: "顧客住所2",
  familyCustomerAddress_FirstName: "顧客住所の宛名（名前)",
  familyCustomerAddress_FirstNameOfPronunciation: "顧客住所の宛名（名前・カナ)",
  familyCustomerAddress_LastName: "顧客住所の宛名（苗字)",
  familyCustomerAddress_LastNameOfPronunciation: "顧客住所の宛名（苗字・カナ)",
  familyCustomerAddress_Mail: "顧客住所のメールアドレス",
  familyCustomerAddress_Pref: "顧客住所の都道府県",
  familyCustomerAddress_Tel: "顧客住所の電話番号",
  familyCustomerAddress_Uuid: "顧客住所のUUID",
  familyCustomerAddress_ZipCode: "顧客住所の郵便番号",
  familyCustomerAddress_BuildingName: "顧客住所のアパートマンション名",
  familyCustomerBillingAddress_BuildingName:
    "顧客の請求先住所のアパートマンション名",
  familyCustomerBillingAddress_Address1: "顧客の請求先住所1",
  familyCustomerBillingAddress_Address2: "顧客の請求先住所2",
  familyCustomerBillingAddress_FirstName: "顧客の請求先住所の宛名（名前)",
  familyCustomerBillingAddress_FirstNameOfPronunciation:
    "顧客の請求先住所の宛名（名前・カナ)",
  familyCustomerBillingAddress_LastName: "顧客の請求先住所名（苗字)",
  familyCustomerBillingAddress_LastNameOfPronunciation:
    "顧客の請求先住所の宛名（苗字・カナ)",
  familyCustomerBillingAddress_Pref: "顧客の請求先住所の都道府県",
  familyCustomerBillingAddress_Tel: "顧客の請求先住所の電話番号",
  familyCustomerBillingAddress_Uuid: "顧客の請求先住所のUUID",
  familyCustomerBillingAddress_ZipCode: "顧客の請求先住所の郵便番号",
  familyCustomerContractedPlan_AccountToken:
    "顧客の契約情報のアカウントトークン",
  familyCustomerContractedPlan_Uuid: "顧客の契約情報のUUID",
  planComment: "プランコメント",
  planTemplate_Comment: "プランテンプレートのコメント",
  planTemplate_Title: "プランテンプレート名",
  planTemplate_Uuid: "プランテンプレートのUUID",
  planTemplate_Id: "プランテンプレートのID",

  planTitle: "プランタイトル",
  plannerMessage: "プランナーメッセージ",
  planningTime: "プランニング時間",
  shipmentEvaluation_Uuid: "発送アンケート評価のUUID",
  shipmentStatus_Uuid: "発送ステータスのUUID",
  shipmentToys_Comment: "発送おもちゃのコメント",
  shipmentToys_Survey: "発送おもちゃのアンケート",
  shipmentToys_Uuid: "発送おもちゃのUUID",
  shipmentType_Name: "発送の種類名",
  shipmentType: "発送の種類",
  shipmentType_Uuid: "発送の種類のUUID",
  shippingNumber: "伝票番号",
  staff_Avatar: "スタッフのアバター",
  staff_Name: "スタッフの名前",
  staff_Department: "スタッフの部署",
  staff_Mail: "スタッフのメールアドレス",
  staff_Uuid: "スタッフのUUID",
  uuid: "UUID",
  warehouse_Uuid: "倉庫のUUID",
  deliveryMethod: "配送方法",
  deliveryMethodTime: "配達指定時間",
  shipmentStatus: "発送ステータス",
  shipmentToys_Note: "発送おもちゃのメモ",
  staff: "スタッフ",
  warehouse: "事業所・倉庫",

  isShipped: "発送済み",
  isShippedAtChangeable: "発送日変更",
  reviewerStaff_Avatar: "レビュー実施者のアバター",
  reviewerStaff_Department: "レビュー実施者の部署",
  reviewerStaff_Mail: "レビュー実施者のメールアドレス",
  reviewerStaff_Name: "レビュー実施者の名前",
  reviewerStaff_Uuid: "レビュー実施者のUUID",
  subscription: "契約プラン名",

  familyCustomerContractedPlan_FamilyCustomerId: "顧客ID",

  minFirstChildBirthday: {
    start: "子供の誕生日 (開始) ",
    end: "子供の誕生日 (終了)",
  },
};
