import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type DeliveryMethod } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<DeliveryMethod>;
const initialState = getInitialState<DeliveryMethod>();

const DeliveriesMethodsSlice = createSlice({
  name: "deliveriesMethods",
  initialState,
  reducers: {
    rehydrateDeliveriesMethods: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateDeliveriesMethods } = DeliveriesMethodsSlice.actions;
export default DeliveriesMethodsSlice.reducer;
