import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ToyStock } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<ToyStock>;
const initialState = getInitialState<ToyStock>();

const ToysStocksSlice = createSlice({
  name: "toysStocks",
  initialState,
  reducers: {
    rehydrateToysStocks: (state, { payload }: PayloadAction<PayloadType>) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateToysStocks } = ToysStocksSlice.actions;
export default ToysStocksSlice.reducer;
