export const COLUMNS_LABELS_TOYS = {
  id: "ID",
  uuid: "UUID",
  name: "名前",
  description: "概要",
  accessories: "付属品",
  instruction: "使用方法",
  maker_toy_url: "商品URL",
  note_of_purchase_for_customer: "お客様に対する購入メモ",
};
