export const COLUMNS_LABELS_FAMILY_CUSTOMER_BILLING_ADDRESSES = {
  id: "ID",
  uuid: "UUID",
  first_name: "宛名（名前)",
  last_name: "宛名（苗字)",
  first_name_of_pronunciation: "宛名（名前・カナ)",
  last_name_of_pronunciation: "宛名（苗字・カナ)",
  pref: "都道府県",
  zip_code: "郵便番号",
  tel: "電話番号",
  address1: "住所1",
  address2: "住所2",
  is_primary: "規定",
  building_name: "アパート・マンション名",
  family_customer_id: "顧客ID",
  created_at: "登録日時",
  updated_at: "更新日時",
  deleted_at: "削除日時",
};
