import { createNestedObjectWith } from "./createNestedObjectWith";
import { identity } from "../function";

/**
 * object x の key と value に対してそれぞれ、再帰的に keyFn, valueFn を適用する。
 * ※ 型変換は複雑になるため any を利用。
 */
export const getValues = (nestedObject: object): any[] => {
  const ret = [] as any[];
  const getValue = (value: any) => {
    ret.push(value);
    return value;
  };
  const creator = createNestedObjectWith(identity, getValue);
  creator(nestedObject);
  return ret;
};
