import { extractErrorMessage } from "@/api/errors/helpers/extract-error-code";
import { identity } from "@/helper/function";
import { type ErrorResponseData } from "@/api/errors/error-response";
import { findFilterTagLabel } from "@/helper";

export const useMakeErrorMessage = (pageId?: string) => {
  return (errorData: ErrorResponseData) => {
    const defaultTranslate = (fieldName: string) =>
      findFilterTagLabel(pageId, fieldName).toString();
    return extractErrorMessage(
      errorData,
      pageId === undefined ? identity : defaultTranslate,
    );
  };
};
