import { hasUuid } from "./hasUuid";
import {
  type HasUuid,
  type MaybeUuidObject,
  type UuidToBoolean,
} from "./types";

export const selectUuidObjectsBy =
  (uuidToBoolean: UuidToBoolean) =>
  <T extends MaybeUuidObject>(uuidObjects: T[]): Array<T & HasUuid> =>
    uuidObjects.reduce<Array<T & HasUuid>>((acc, obj) => {
      if (!hasUuid(obj)) {
        return acc;
      }
      const isExist = uuidToBoolean[obj.uuid];
      if (isExist === undefined) {
        return acc;
      }
      return isExist ? [...acc, obj] : acc;
    }, []);

export const selectUuidObjects = <T extends MaybeUuidObject>(
  uuidObjects: T[],
  uuidToBoolean: UuidToBoolean,
): Array<T & HasUuid> => selectUuidObjectsBy(uuidToBoolean)(uuidObjects);
