import {
  Button as MuiButton,
  type ButtonProps as MuiButtonProps,
} from "@mui/material";
import { type ElementType, forwardRef, type ReactNode } from "react";
import { type DatadogAttributes } from "@/types/datadog";
import { addDDAllowPrivacy } from "@/helper/datadog";

export type ButtonProps<RootComponent extends ElementType> = MuiButtonProps<
  RootComponent,
  DatadogAttributes & {
    dataTestId?: string;
  }
>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps<"button">>(
  function Button({ dataTestId, ddAllowPrivacy, ...props }, ref) {
    return (
      <MuiButton
        ref={ref}
        color="inherit"
        data-testid={dataTestId}
        {...addDDAllowPrivacy(ddAllowPrivacy)}
        {...props}
      />
    );
  },
) as <T extends React.ElementType>(props: ButtonProps<T>) => ReactNode;
