export const COLUMNS_LABELS_FAMILY_CUSTOMER_CONTRACTED_PLANS = {
  id: "ID",
  uuid: "UUID",
  family_customer_id: "顧客ID",
  subscription_id: "契約プランのID",
  family_customer_billing_address_id: "顧客の請求先住所のID",
  payment_type_id: "支払い方法のID",
  warehouse_id: "事業所のID",
  account_token: "アカウントトークン",
  is_active: "アクティブ",
  contracted_at: "契約日時",
  min_usage_period_end_date: "最低利用期間終了日",
  cancelled_at: "退会日時",
  special_note: "特記事項",
  issued_at: "顧客の",
};
