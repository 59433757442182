import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ToySupplier } from "@/openapi/api";
import { getInitialState, type RehydratePager } from "@/types/Pager";

type PayloadType = RehydratePager<ToySupplier>;
const initialState = getInitialState<ToySupplier>();

const ToysSuppliersSlice = createSlice({
  name: "toysSuppliers",
  initialState,
  reducers: {
    rehydrateToysSuppliers: (
      state,
      { payload }: PayloadAction<PayloadType>,
    ) => {
      if (payload.parameters !== undefined)
        // @ts-expect-error -- FIXME: ts version 5.1.6 以前はエラーではなかった.
        state[payload.parameters.toString()] = payload;
      return state;
    },
  },
});

export const { rehydrateToysSuppliers } = ToysSuppliersSlice.actions;
export default ToysSuppliersSlice.reducer;
