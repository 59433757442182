import Link, { type LinkProps } from "next/link";
import { type ReactNode } from "react";
import { Button, type ButtonProps } from "./Button";

type DuplicatePropertiesToTValue<T, U> = {
  [K in keyof T & keyof U]: T[K];
};

type AnchorButtonProps = Exclude<ButtonProps<"a">, "component">;
type NarrowedLinkProps = Exclude<LinkProps, "passHref" | "legacyBehavior">;

type DuplicatePropertiesToLinkValue = DuplicatePropertiesToTValue<
  NarrowedLinkProps,
  AnchorButtonProps
>;
type ButtonPropsWithoutLinkProps = Exclude<
  AnchorButtonProps,
  keyof DuplicatePropertiesToLinkValue
>;

type LinkWithButtonProps = {
  children: ReactNode;
  buttonProps: ButtonPropsWithoutLinkProps;
} & NarrowedLinkProps;

/**
 * @note
 * * LinkButton の href は object 形式( `UrlObject` )に対応していませんが、こちらのコンポーネントは対応しています。
 * @deprecated
 * 公式ページ記載の実装ですが（ https://nextjs.org/docs/pages/api-reference/components/link#if-the-child-is-a-functional-component ）、
 * `legacyBehavior` を利用しており、今後削除される可能性があるため、 `UrlObject` を利用する場合にのみ使用してください。
 */
export const LinkWithButton = ({
  children,
  buttonProps,
  ...linkProps
}: LinkWithButtonProps) => {
  // cf. https://nextjs.org/docs/pages/api-reference/components/link#if-the-child-is-a-functional-component
  const propsForWorkaround = {
    passHref: true,
    legacyBehavior: true,
  };

  return (
    <Link {...propsForWorkaround} {...linkProps}>
      <Button component="a" {...buttonProps}>
        {children}
      </Button>
    </Link>
  );
};
